import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import styles from "./sign_in_prompt_button.module.scss";

interface Props {
  buttonText?: string;
  tooltipText?: string;
  extraBtnClasses?: string;
  onClick?: (e) => void;
}

export default function SignInPromptButton({ buttonText, tooltipText = "Sign In", extraBtnClasses = "", onClick }: Props) {
  const linkAttrs = { href: "/signin", rel: "noopener noreferrer", target: "_blank" };

  if (onClick) linkAttrs["onClick"] = onClick;

  return (
    <>
      {!buttonText &&
        <OverlayTrigger overlay={
          <Tooltip id={`sign-in-button-${uuid()}`}>{tooltipText}</Tooltip>
        }>
          <a {...linkAttrs} className={classNames("btn", styles.signInPromptBtn, extraBtnClasses)}>
            <FontAwesomeIcon icon="sign-in-alt" size="sm" />
          </a>
        </OverlayTrigger>
      }

      {buttonText &&
        <a {...linkAttrs} className={classNames("btn", styles.signInPromptBtnWithText, extraBtnClasses)}>
          <FontAwesomeIcon icon="sign-in-alt" size="sm" className="me-1" />
          <span>{buttonText}</span>
        </a>
      }
    </>
  );
}

export function renderSignInPromptButton(elementId, options) {
  const node = ReactDOM.render(
    <SignInPromptButton
      buttonText={options.buttonText}
      tooltipText={options.tooltipText}
      extraBtnClasses={options.extraBtnClasses}
      onClick={options.onClick}
    />,
    document.getElementById(elementId)
  );

  return node;
}