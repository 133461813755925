import React, { useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { postData } from "../global_functions";
import { AssignmentSubmission, Comment, User } from '../../types';
import FormErrorsList from "../shared_components/form_errors_list";

interface DataObj {
  comment: Comment;
  currentUser: User;
  canSubmitAssignment: boolean;
  assignmentSubmission: AssignmentSubmission;
}

interface Props {
  assignmentItemId: number;
  onCreateComment?: (data: DataObj) => void;
}

export function NewAssignmentItemComment({ assignmentItemId, onCreateComment }: Props) {
  const [commentText, setCommentText] = useState('');
  const [errors, setErrors] = useState([]);

  const onCommentCreate = async (e) => {
    e.preventDefault();

    const { data } = await postData({
      url: `/assignment_item_comments`,
      data: {
        assignment_item_id: assignmentItemId,
        comment: {
          comment_text: commentText
        }
      }
    });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setErrors([]);
      onCreateComment?.(data);
    }
  };

  return (
    <div className="d-flex assignment-item-responses w-100">
      <FormErrorsList errors={errors} />

      <Form onSubmit={onCommentCreate} className="w-100">
        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Control as="textarea" rows={3} value={commentText} onChange={e => setCommentText(e.target.value)} />
          </div>
        </div>

        <div className="d-flex flex-wrap">
          <Button type="submit" variant="dark">Submit</Button>
        </div>
      </Form>
    </div>
  );
}

export function renderNewAssignmentItemComment(elementId, options) {
  const node = ReactDOM.render(
    <NewAssignmentItemComment
      assignmentItemId={options.assignmentItemId}
      onCreateComment={options.onCreateComment}
    />,
    document.getElementById(elementId)
  );

  return node;
}