import React from "react";
import { Modal } from "react-bootstrap";

import SetupOrganizationForm from "./setup_organization_form";
import { Organization } from "../../types";

const ORGANIZATION_TYPE_OPTIONS = [
  { label: "School", value: "school" },
  { label: "District", value: "district" },
  { label: "University", value: "college_or_university" },
  { label: "Other", value: "other" }
];

interface Props {
  shown: boolean;
  onSuccess: (organization: Organization) => void;
  onClose: () => void;
}

export function AddOrganizationModal({ shown, onSuccess, onClose }: Props) {
  const handleClose = () => onClose();

  const handleSuccess = (data) => {
    onSuccess(data.organization);
  };

  return (
    <>
      <Modal show={shown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Organization</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <SetupOrganizationForm
            onOrganizationSetupSuccess={handleSuccess}
            postUrl="/organizations"
            displayOrganizationType={true}
            organizationTypeOptions={ORGANIZATION_TYPE_OPTIONS}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
