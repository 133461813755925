import React, { createRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sanitizeHtml from "sanitize-html";
import { v4 as uuid } from "uuid";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ContainerWithHeading from "../shared_components/container_with_heading";
import UpgradeButton from "../shared_components/upgrade_button";
import { timestampFromSeconds } from "../time_functions";

import "./styles.scss";

export enum CLOSE_TYPE {
  MANUAL = "MANUAL",
  TIMER = "TIMER",
}

export enum MODE {
  /**
   * Teacher is presenting prompt to students (with a timer)
   */
  PRESENT = "PRESENT",
  /**
   * Student is submitting a response to the question
   */
  RESPOND = "RESPOND",
  /**
   * Student is submitting a response to live discussion question
   */
  LIVE_RESPOND = "LIVE_RESPOND",
}

function useTimeLeft({ secondsDuration, isEnabled }) {
  const [timeLeft, setTimeLeft] = useState(secondsDuration * 1000);

  useEffect(() => {
    if (!isEnabled) return;

    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft <= 0) clearInterval(intervalId);

        return Math.max(timeLeft - 1000, 0);
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isEnabled]);

  return {
    addMinute: () => setTimeLeft((timeLeft) => timeLeft + 60 * 1000),
    timeLeft,
  };
}

const PausePrompt = React.forwardRef(
  (
    props: {
      id: number;
      backdrop?: boolean;
      hasTimer: boolean;
      secondsDuration: number;
      content: string;
      mode: MODE;
      onClose?: (closeType: CLOSE_TYPE) => void;
      onSubmitResponse?: (responseText: string, callback: (value: any) => void) => void;
      addMinuteButtonEnabled: boolean;
      show: boolean;
      showHeader?: boolean;
      showCloseButton?: boolean;
      container: Element;
    },
    ref
  ) => {
    const [show, setShow] = useState(props.show);
    const [responseText, setResponseText] = useState("");
    const { hasTimer, secondsDuration, addMinuteButtonEnabled } = props;
    const showCloseButton = useMemo(() => {
      return (props.showCloseButton == null) ? true : props.showCloseButton
    }, []);

    const { addMinute, timeLeft } = useTimeLeft({
      secondsDuration,
      isEnabled: show && props.mode === MODE.PRESENT,
    });

    function onClose(closeType: CLOSE_TYPE) {
      setShow(false);
      props.onClose?.(closeType);

      // Remove the node containing the Pause Prompt
      props.container.remove();
    }

    useEffect(() => {
      if (!hasTimer || timeLeft > 0) return;
      onClose(CLOSE_TYPE.TIMER);
    }, [timeLeft]);

    function onManualClose() {
      onClose(CLOSE_TYPE.MANUAL);
    }

    function onChangeResponse(event) {
      setResponseText(event.target.value);
    }

    function onSubmitResponse(event) {
      event.preventDefault();

      if (!props.onSubmitResponse) {
        setShow(false);
        props.onClose?.(CLOSE_TYPE.MANUAL);

        return false;
      }

      props.onSubmitResponse(responseText, (callbackValue) => {
        // Close the pause prompt if the callback returns a truthy value
        if (callbackValue) {
          setShow(false);
          props.onClose?.(CLOSE_TYPE.MANUAL);
        }
      });
    }

    useImperativeHandle(ref, () => ({
      get id() { return props.id },
      get content() { return props.content },
      get container() { return props.container },
      get mode() { return props.mode },
      get hasTimer() { return props.hasTimer },
      close: () => {
        onClose(CLOSE_TYPE.MANUAL);
      }
    }));

    if (!show) return null;

    if (props.mode === MODE.PRESENT || props.mode === MODE.RESPOND) {
      return (
        <>
          {props.backdrop &&
            <div className="pause_prompt_backdrop"></div>
          }

          <div className="pause_prompt_parent_container">
            <Modal.Dialog>
              {props.showHeader &&
                <Modal.Header className="border-bottom" closeButton={showCloseButton} onHide={onManualClose}>
                  <Modal.Title>
                    <FontAwesomeIcon icon="question-circle" size="lg" />
                    &nbsp; Prompt
                  </Modal.Title>
                </Modal.Header>
              }

              {props.mode === MODE.PRESENT ? (
                <>
                  <Modal.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(props.content),
                      }}
                    />

                    {hasTimer &&
                      <div className="mt-3">
                        <FontAwesomeIcon icon="hourglass" size="lg" />
                        &nbsp; {timestampFromSeconds(timeLeft / 1000)}
                      </div>
                    }
                  </Modal.Body>

                  <Modal.Footer className="border-top">
                    <Button variant="outline-dark" onClick={onManualClose} className="me-3">
                      Close
                    </Button>

                    {renderAddMinuteButton(hasTimer, addMinuteButtonEnabled, addMinute)}
                  </Modal.Footer>
                </>
              ) : (
                <>
                  <Modal.Body>
                    <Form onSubmit={onSubmitResponse}>
                      <div id="pause-prompts-comments-errors"></div>

                      <Form.Group>
                        <Form.Label>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHtml(props.content),
                            }}
                          />
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          className="pause-prompt-response"
                          onChange={onChangeResponse}
                          placeholder="Your answer"
                          rows={3}
                          value={responseText}
                        />
                      </Form.Group>

                      <div className="text-end mt-3 mb-2">
                        <Button
                          type="submit"
                          variant="dark"
                          disabled={responseText?.trim()?.length < 1}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </Modal.Body>
                </>
              )}
            </Modal.Dialog>
          </div>
        </>
      );
    } else if (props.mode === MODE.LIVE_RESPOND) {
      return (
        <Form onSubmit={onSubmitResponse}>
          <div id="pause-prompts-comments-errors"></div>

          <Form.Group className="w-50 mx-auto live_respond_form">
            <Form.Label className="w-100">
              <ContainerWithHeading header={"Current question"} iconName="question-circle">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.content),
                  }}
                />
              </ContainerWithHeading>
            </Form.Label>

            <ContainerWithHeading header={"My response"} iconName="comment">
              <Form.Control
                as="textarea"
                onChange={onChangeResponse}
                rows={3}
                value={responseText}
              />

              <div className="text-end mt-3 mb-2">
                <Button
                  type="submit"
                  variant="dark"
                  disabled={responseText?.trim()?.length < 1}
                >
                  Submit
                </Button>
              </div>
            </ContainerWithHeading>
          </Form.Group>
        </Form>
      )
    }
  }
);

function renderAddMinuteButton(hasTimer, addMinuteButtonEnabled, addMinuteFunc) {
  if (!hasTimer) return null;

  if (addMinuteButtonEnabled) {
    return (
      <Button variant="dark" onClick={addMinuteFunc}>
        Add minute
      </Button>
    );
  }

  const toolTipId = `pause-prompt-add-minute-${uuid()}`;

  const toolTip = (
    <Tooltip id={toolTipId}>
      Upgrade to Premium to add more time to Pause Prompts.
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={toolTip}
      >
        <Button variant="dark" disabled={true} className="me-2">
          Add minute
        </Button>
      </OverlayTrigger>

      <UpgradeButton />
    </>
  );
}

export function renderPausePrompt(options) {
  const containerNode = options.container;
  const node = document.createElement("div");

  containerNode.appendChild(node);

  const currentPausePrompt = createRef();

  ReactDOM.render(
    <PausePrompt
      id={options.id}
      backdrop={options.backdrop}
      hasTimer={options.hasTimer}
      secondsDuration={options.secondsDuration}
      content={options.content}
      mode={options.mode}
      onClose={options.onClose}
      onSubmitResponse={options.onSubmitResponse}
      ref={currentPausePrompt}
      addMinuteButtonEnabled={options.addMinuteButtonEnabled}
      show={true}
      showHeader={options.showHeader}
      showCloseButton={options.showCloseButton}
      container={node}
    />,
    node
  );

  return currentPausePrompt;
}
