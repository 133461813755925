export enum FormEvent {
  Default = 'ch_marketing_contact_us_submission',
}

export enum FormTopic {
  ClassHook = 'Contact Us',
}

export enum BrazeSource {
  Contact = 'ch_marketing_form_contact_us',
}