import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from "classnames";

import { Button } from 'react-bootstrap';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

import { retrieveCsrfToken } from '../global_functions';

import googleIcon from "./google_icon.png";
import styles from "./sign_in_button.module.scss";

interface FormProps {
  buttonText?: 'Sign in with Google' | 'Sign up with Google' | 'Continue with Google' | 'Sign in';
  width?: string;
  height?: string;
}

interface ButtonProps extends FormProps {
  clientId: string;
}

function SignInForm({ buttonText, width, height }: FormProps) {
  const formRef = useRef(null);
  const inputIdTokenRef = useRef(null);

  const login = useGoogleLogin({
    ux_mode: 'popup',
    redirect_uri: '/google_oauth',
    flow: 'implicit',
    onSuccess: (tokenResponse) => {
      if (inputIdTokenRef && inputIdTokenRef.current) {
        inputIdTokenRef.current.value = tokenResponse.access_token;
        formRef?.current?.submit();
      }
    }
  });

  return (
    <>
      <form role="form" action="/google_oauth" method="post" ref={formRef}>
        <input type="hidden" name="authenticity_token" value={retrieveCsrfToken()} />
        <input type="hidden" name="google_user_access_token" ref={inputIdTokenRef} />
      </form>

      <Button
        variant="outline-dark"
        className={classNames(styles.btn, "d-inline-block")}
        style={{ width: width, height: height }}
        onClick={() => login()}
      >
        <img src={googleIcon} height={24} />
        {buttonText}
      </Button>
    </>
  );
}

export function GoogleSignInButton({ clientId, buttonText = 'Sign in with Google', width = "100%", height = "48px" }: ButtonProps) {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <SignInForm buttonText={buttonText} width={width} height={height} />
    </GoogleOAuthProvider>
  );
}

export function renderGoogleSignInButton(elementId, options) {
  const node = ReactDOM.render(
    <GoogleSignInButton
      buttonText={options.buttonText}
      clientId={options.clientId}
      width={options.width}
      height={options.height}
    />,
    document.getElementById(elementId)
  );

  return node;
}