export const EDITOR_MODULES = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote', { color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }]
  ]
};

export const EDITOR_FORMATS = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
  'list', 'bullet', 'indent'
];