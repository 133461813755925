import React from "react";

interface Props {
  errors: Array<string>;
}

export default function FormErrorsList({ errors }: Props) {
  if (!errors || errors.length < 1) return null;

  return (
    <div className="mt-2 text-danger">
      This form contains {errors.length} error(s):

      <ul>
        {errors.map(error =>
          <li key={error}>{error}</li>)
        }
      </ul>
    </div>
  );
}