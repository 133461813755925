import React, { useState } from "react";
import ReactDOM from "react-dom";

import { VideoQuestionItem } from "./video_question_item";
import { NewVideoQuestionForm } from "./new_video_question_form";
import { User, VideoQuestion } from "../../types";

interface Props {
  videoQuestions: Array<VideoQuestion>;
  questionableId: number;
  questionableType: string;
  currentUser: User;
}

function VideoQuestionsList({ videoQuestions, questionableId, questionableType, currentUser }: Props) {
  const [theVideoQuestions, setVideoQuestions] = useState(videoQuestions);

  const onCreateSuccess = (videoQuestion: VideoQuestion) => {
    const newVideoQuestions = [...theVideoQuestions, videoQuestion].sort((a, b) => a.time - b.time);

    setVideoQuestions(newVideoQuestions);
  };

  return (
    <>
      {theVideoQuestions.length > 0 &&
        <h5 className="mb-3">My questions</h5>
      }

      <div className="d-flex flex-wrap">
        {theVideoQuestions.map(videoQuestion => {
          return <VideoQuestionItem
            key={videoQuestion.id}
            videoQuestion={videoQuestion}
            currentUser={currentUser}
          />
        })}
      </div>

      <div className="mt-3">
        <NewVideoQuestionForm
          questionableId={questionableId}
          questionableType={questionableType}
          currentUser={currentUser}
          onCreateSuccess={onCreateSuccess}
        />
      </div>
    </>
  );
}

export function renderVideoQuestionsList(elementId, options) {
  const node = ReactDOM.render(
    <VideoQuestionsList
      videoQuestions={options.videoQuestions}
      questionableId={options.questionableId}
      questionableType={options.questionableType}
      currentUser={options.currentUser}
    />,
    document.getElementById(elementId)
  );

  return node;
}