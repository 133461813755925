import React, { useState, useEffect, useContext } from "react";
import {
  Button, Form,
  Modal, OverlayTrigger, Tooltip,
} from "react-bootstrap";
import { postData } from "../global_functions";
import DiscussionContext from "./discussion_context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { chAnalyticsTrackEvent } from "../analytics";


export default function StartDiscussionModal(): React.ReactNode {
  const [generateNicknames, setGenerateNicknames] = useState(false);
  const [hideResponses, setHideResponses] = useState(false);

  const { discussionRoomId, modal: { activeModalName, handleClose } } = useContext(DiscussionContext);
  const startDiscussion = async () => {
    const data = {
      discussion_room_code: {
        discussion_room_id: discussionRoomId,
        generate_nicknames: generateNicknames,
        hide_responses: hideResponses
      }
    };
    const { data: { discussion_room_code } } = await postData({ url: "/discussion_room_codes", data: data });

    // redirects to discussions page
    window.location.href = `/discussions/${discussion_room_code.code}`;
  }

  return (
    <Modal show={activeModalName == "StartDiscussionModal"} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="start-discussion-modal">Start Discussion</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group controlId="generateNicknames">
            <Form.Check type="checkbox">
              <Form.Check.Input type="checkbox"
                value={generateNicknames}
                onChange={(e) => setGenerateNicknames(e.target.checked)}
              />
              <Form.Check.Label>
                <span className="me-2">
                  Generate nicknames for students
                </span>

                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      Allow students to join anonymously with automatically generated nicknames.
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <FontAwesomeIcon icon="info-circle" className="text-primary" forwardedRef={ref} {...triggerHandler} />
                  )}
                </OverlayTrigger>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Form.Group controlId="hideResponses">
            <Form.Check type="checkbox">
              <Form.Check.Input type="checkbox"
                value={`${hideResponses}`}
                onChange={(e) => setHideResponses(e.target.checked)}
              />
              <Form.Check.Label>
                <span className="me-2">
                  Prevent students from seeing other students' responses
                </span>

                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-right`}>
                      Disable students' ability to see each others' responses on their screens.
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <FontAwesomeIcon icon="info-circle" className="text-primary" forwardedRef={ref} {...triggerHandler} />
                  )}
                </OverlayTrigger>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="dark" aria-label="Start Discussion" onClick={startDiscussion}>
          Start Discussion
        </Button>

        <Button variant="outline-dark" data-bs-dismiss="modal" aria-label="Cancel" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
