// A buffer (in seconds) to skip past profanity to ensure that the profanity is fully skipped
const EXTRA_BUFFER_SECONDS = 0.3;

export enum MODE {
  /**
   * Profanity skipping is enabled by a toggle on the webpage
   */
  TOGGLE = "TOGGLE",
  /**
   * Profanity skipping is always enabled
   */
  ENABLED = "ENABLED",
  /**
   * Profanity skipping is always disabled
   */
  DISABLED = "DISABLED",
}

export class VideoProfanitySkippingService {
  mode: MODE;
  toggleElementId: string;
  toggleElement;
  profanityMoments;
  playerRef;

  constructor(options) {
    this.toggleElementId = options.toggleElementId;
    this.profanityMoments = options.profanityMoments;
    this.mode = options.mode || MODE.TOGGLE;

    this.toggleElement = document.getElementById(this.toggleElementId);
  }

  get skippingEnabled() {
    if (this.mode === MODE.ENABLED) {
      return true;
    } else if (this.mode === MODE.DISABLED) {
      return false;
    }

    return this.toggleElement?.checked;
  }

  skipEndTime(startTime, endTime) {
    if (startTime !== endTime) return endTime;

    // If the start and end times are the same, it won't skip the profanity since the current video
    // time would need to be the exact second. To work around this, increase the end time by one second
    return endTime + 1;
  }

  onTimeUpdate(time) {
    if (!this.playerRef || !this.skippingEnabled) return;
    if (!this.profanityMoments || this.profanityMoments.length < 1) return;

    const profanityMoment = this.profanityMoments.find(profanityMoment => {
      const startTime = profanityMoment.start_time_seconds;
      const endTime = this.skipEndTime(startTime, profanityMoment.end_time_seconds);

      return (time >= startTime && time <= endTime);
    });

    if (!profanityMoment) return;

    const seekTime = this.skipEndTime(profanityMoment.start_time_seconds, profanityMoment.end_time_seconds) + EXTRA_BUFFER_SECONDS;
    this.playerRef.current.seekTo(seekTime);
  }

  register(playerRef) {
    this.playerRef = playerRef;
  }
}
