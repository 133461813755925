import * as _ from 'underscore';

export const FunctionHelper = {
  throttle<T extends (...args: any) => any>(fn: T, wait: number, options?: _.ThrottleSettings): T & _.Cancelable {
    return _.throttle<T>(fn, wait, options);
  },

  isFunction(object: any): object is Function {
    return _.isFunction(object);
  },
};