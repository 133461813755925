export function capitalizeString(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
}

// Source: https://stackoverflow.com/a/39787460/2430657
export function insertTextAtCaret(element, textToInsert: string): string {
  const caretPos = element.selectionStart;
  const currentText = element.value;

  return `${currentText.substring(0, caretPos)}${textToInsert}${currentText.substring(caretPos)}`;
};