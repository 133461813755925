import React from "react";
import classNames from "classnames";

import { CleverSignInButton } from "../signin/clever_sign_in_button";
import { GoogleSignInButton } from "../signin/google_sign_in_button";

import styles from "./signin_section.module.scss";

interface Props {
  googleClientId: string;
  cleverUrl: string;
  onLinkClick: (e) => void;
  buttonWidth?: number;
}

export function SigninSection({ onLinkClick, buttonWidth = 200, ...props }: Props) {
  return (
    <>
      <div className="d-flex justify-content-center mb-2">
        <CleverSignInButton url={props.cleverUrl} width={`${buttonWidth}px`} onClick={onLinkClick} />
      </div>

      <div className="d-flex justify-content-center">
        <GoogleSignInButton
          buttonText="Sign up with Google"
          clientId={props.googleClientId}
          width={buttonWidth}
        />
      </div>

      <div className="my-4">
        <div className={styles.otherLoginDividerLine}>
          <span className={styles.otherLoginDividerLineText}>
            or
          </span>
        </div>
      </div>

      <div className="text-center">
        <a href="/signup" className={classNames(styles.emailLoginBtn, "btn btn-outline-dark")} onClick={onLinkClick}>
          Continue with email
        </a>
      </div>

      <div className="my-4 text-center small">
        <span className="text-muted">Already have an account?</span>
        {' '}
        <a href="/signin" onClick={onLinkClick}>Sign in</a>
      </div>
    </>
  );
}