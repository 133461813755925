import countries from "i18n-iso-countries";

// Register English
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export function getCountryNames() {
  return countries.getNames("en", { select: "official" });
}

export function getCountrySelectOptions() {
  const countryNames = getCountryNames();
  let selectOptions = Object.keys(countryNames).sort((keyA, keyB) => countryNames[keyA] > countryNames[keyB] ? 1 : -1).map(key => {
    return {
      label: countryNames[key],
      value: countryNames[key]
    };
  });

  return selectOptions;
}

export default countries;