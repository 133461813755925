export function timestampFromSeconds(seconds, excludeZeroHours = true) {
  const theDate = new Date(1970, 0, 1);

  theDate.setSeconds(seconds);

  const minutes = twoDigitTimeVal(theDate.getMinutes());
  const secondsVal = twoDigitTimeVal(theDate.getSeconds());
  const timeAry = [minutes, secondsVal];

  const hours = theDate.getHours();

  if (hours >= 1 || !excludeZeroHours) {
    timeAry.unshift(twoDigitTimeVal(hours));
  }

  return timeAry.join(":");
}

function twoDigitTimeVal(timeVal) {
  return timeVal.toString().padStart(2, '0');
}

export function normalizeMinuteOrSecondValue(value: string) {
  if (!value) return 0;

  const intValue = parseInt(value, 10);

  // Minutes and seconds can only have a maximum value of 59
  // (Only 59 minutes / hour, only 59 seconds / minute)
  return (intValue > 59 ? 59 : intValue);
}