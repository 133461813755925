import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import "plyr/dist/plyr.css";

import { v4 as uuid } from "uuid";
import Plyr from "plyr";
import ReactDOM from "react-dom";

import { Subtitles } from "../subtitles";
import { VideoSubtitlesService } from "./video_subtitles_service";
import { youtubeEmbedOptions, vimeoEmbedOptions, dailymotionEmbedOptions, processEmbedCode } from "./functions";

import "./styles.scss";

// Sources:
//  https://developers.google.com/youtube/iframe_api_reference#onStateChange
//  https://stackoverflow.com/a/69633836/2430657
const YT_UNAVAILABLE_STATE = -1;

interface MarkerPoint {
  time: number;
  pointTip?: string;
  pointHTML?: string;
  pausePromptId?: number; // Extra metadata stored with the marker
}

interface VideoResource {
  endTime: number;
  entireVideo: boolean;
  id: string;
  startTime: number;
  thumbnailUrl?: string;
  title: string;
  url: string;
  videoFile?: {
    contentType: string;
    thumbnailUrl: string;
    url: string;
  };
  videoHostName: "youtube" | "vimeo" | "dailymotion" | "classhook";
  videoId: string;
}

interface VideoPlayerProps {
  inlinePausePrompt?: boolean;
  onPlyrLoaded?: (player) => void;
  onTimeUpdate?: (time: number) => void;
  onEnded?: (player) => void;
  onDurationChange?: (duration: number) => void;
  onEduPlayerUnavailable?: (playerRef) => void;
  useEduPlayer?: boolean;
  resource: VideoResource;
  subtitlesService?: VideoSubtitlesService;
  markers: Array<MarkerPoint>;
  markerOnClick?: (player, point: MarkerPoint) => void;
}

interface RenderVideoPlayerOptions extends VideoPlayerProps {
  elementId: string;
}

const VideoPlayer = forwardRef((props: VideoPlayerProps, ref) => {
  const [player, setPlayer] = useState<Plyr>();
  const [ignoreEndTime, setIgnoreEndTime] = useState<boolean>();
  const [maxTimeReached, setMaxTimeReached] = useState<number>(0);

  const playerElementId = `player-${uuid()}`;
  const playerContainerId = `${playerElementId}-container`;
  const playerSubtitlesDivId = `${playerElementId}-subtitles`;
  const playerPausePromptsDivId = `${playerElementId}-pause-prompts`;

  useEffect(() => {
    const plyrOptions = {
      controls: ['play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
      invertTime: true,
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: false,
        container: `#${playerContainerId}`
      }
    };

    if (props.resource.videoHostName === 'youtube') {
      plyrOptions["youtube"] = youtubeEmbedOptions(props.resource, props.useEduPlayer);

      if (plyrOptions["youtube"].eduPlayer) {
        // Load from the YouTube for Education domain instead
        plyrOptions['urls'] = {
          youtube: { sdk: "https://www.youtubeeducation.com/iframe_api" }
        };

        if (!window.chLoadedYTEduPlayer) {
          // @ts-ignore Clear the loaded YouTube player SDK (EDU or regular) so Plyr can load the appropriate one
          window.YT = null;

          // @ts-ignore Indicate that the YouTube EDU player was loaded
          window.chLoadedYTEduPlayer = true;
        }
      } else if (window.chLoadedYTEduPlayer) {
        window.YT = null;
        window.chLoadedYTEduPlayer = null;
      }
    } else if (props.resource.videoHostName === 'vimeo') {
      plyrOptions["vimeo"] = vimeoEmbedOptions();
    } else if (props.resource.videoHostName === 'dailymotion') {
      plyrOptions["dailymotion"] = dailymotionEmbedOptions(props.resource);
    }

    if (props.markers) {
      plyrOptions['markers'] = { enabled: true, points: props.markers };

      if (props.markerOnClick) {
        plyrOptions['markers']['onClick'] = props.markerOnClick;
      }
    }

    setPlayer(
      new Plyr(`#${playerElementId}`, plyrOptions)
    );
    return () => {
      player && player.destroy();
    };
  }, []);

  useEffect(() => {
    if (!player) return;

    const onReady = () => {
      if (props.resource.videoHostName === "vimeo") {
        player.embed.setCurrentTime(props.resource.startTime || 0);
      } else if (props.resource.videoHostName === "classhook") {
        player.currentTime = props.resource.startTime || 0;
      } else if (props.resource.videoHostName === "youtube" && props.useEduPlayer) {
        // Make sure we're currently using the EDU player
        // This prevents us from reloading a non-EDU player video
        const isUsingEduPlayer = player.embed.getVideoUrl().includes("youtubeeducation.com");
        const playerState = player.embed.getPlayerState();

        if (isUsingEduPlayer && playerState === YT_UNAVAILABLE_STATE) {
          props.onEduPlayerUnavailable?.(ref);
          return;
        }
      }

      props.onPlyrLoaded?.(ref);
    };

    const onSeeked = () => {
      if (
        ignoreEndTime !== true &&
        player.currentTime > props.resource.endTime
      ) {
        setIgnoreEndTime(true);
      }
    };

    const onEnded = () => {
      props.onEnded?.(player);
    }

    const onTimeUpdate = () => {
      if (player.currentTime > maxTimeReached) {
        setMaxTimeReached(player.currentTime);
      }

      // YouTube has end times built in
      if (
        props.resource.videoHostName !== 'youtube' &&
        ignoreEndTime !== true &&
        !props.resource.entireVideo &&
        player.currentTime > props.resource.endTime
      ) {
        onEnded();
        return player.pause();
      }
      props.onTimeUpdate?.(player.currentTime);
      props.subtitlesService?.onTimeUpdate?.(player.currentTime);
    };

    const onDurationChange = () => {
      props.onDurationChange?.(player.duration);
    };

    player.on("ready", onReady);
    player.on("seeked", onSeeked);
    player.on("ended", onEnded);
    player.on("timeupdate", onTimeUpdate);
    player.on("durationchange", onDurationChange);

    return () => {
      player.off("ready", onReady);
      player.off("seeked", onSeeked);
      player.off("ended", onEnded);
      player.off("timeupdate", onTimeUpdate);
    };
  }, [player, ignoreEndTime, maxTimeReached]);

  useImperativeHandle(ref, () => ({
    get playing() { return player.playing },
    get paused() { return player.paused },
    get currentTime() { return player.currentTime },
    get maxTimeReached() { return maxTimeReached },
    get seeking() { return player.seeking },
    get duration() { return player.duration },
    get language() { return player.language },
    get videoHostName() { return props.resource.videoHostName },
    get pausePromptContainerId() { return playerPausePromptsDivId },
    get embed() { return player.embed },
    get embedCode() {
      let htmlCode;

      if (props.resource.videoHostName === "youtube") {
        // youtube-nocookie.com and youtubeeducation.com don't work when embedding in PowerPoint
        htmlCode = player.embed.getVideoEmbedCode().replace("youtube-nocookie.com", "youtube.com").replace("youtubeeducation.com", "youtube.com");
      } else if (props.resource.videoHostName === "vimeo") {
        htmlCode = player.embed.element.outerHTML;
      } else {
        htmlCode = player?.embed?.outerHTML;
      }

      if (!htmlCode) return null;

      return processEmbedCode(htmlCode, props.resource.videoHostName);
    },
    get subtitlesService() { return props.subtitlesService },
    get startTime() { return props.resource.startTime },
    get fullscreenActive() { return player.fullscreen.active },
    get markers() { return player.markers },
    updateIgnoreEndTime: (shouldIgnoreEndTime) => {
      setIgnoreEndTime(shouldIgnoreEndTime);
    },
    setMarkers: (markerPoints) => {
      const markerObj = {
        enabled: true,
        points: markerPoints
      };

      if (props.markerOnClick) markerObj['onClick'] = props.markerOnClick;

      player.markers = markerObj;
    },
    setOnSeekEvent: (onSeek: (e, seekTime: number, playerRef) => void) => {
      player.config.listeners.seek = (e) => {
        const seekTime = getSeekToTime(e, player.duration);
        return onSeek(e, seekTime, ref);
      };
    },
    setPreventSkip: () => {
      // Source for adding a "seek" event: https://github.com/sampotts/plyr/issues/806#issuecomment-1403172477
      // Prevent default (e.preventDefault()) and return false to prevent seeking
      player.config.listeners.seek = (e) => {
        const seekTime = getSeekToTime(e, player.duration);
        return defaultOnPreventSkipEvent(e, seekTime, ref);
      };
    },
    pause: () => {
      player.pause();
    },
    play: () => {
      player.play();
    },
    destroy: (callback: () => void, soft = false) => {
      player.destroy(callback, soft);
    },
    seekTo: (time) => {
      if (player.currentTime >= time) {
        const timeDiff = (player.currentTime - time);
        player.rewind(timeDiff);
      }
      else {
        const timeDiff = (time - player.currentTime);
        player.forward(timeDiff);
      }
    },
    exitFullscreen: () => {
      player.fullscreen.exit();
    }
  }));

  return (
    <div id={playerContainerId} className="video_player_container">
      {
        props.resource.videoHostName === 'classhook' ?
          renderHtmlPlayer(playerElementId, props.resource) :
          renderThirdPartyPlayer(playerElementId, props.resource)
      }

      {props.subtitlesService &&
        <Subtitles containerId={playerSubtitlesDivId} subtitlesService={props.subtitlesService} />
      }

      {/* Renders pause prompts in the video player */}
      {props.inlinePausePrompt &&
        <div id={playerPausePromptsDivId} className="pause_prompt_container"></div>
      }
    </div>
  );
}
);

function renderHtmlPlayer(playerElementId, resource) {
  return (
    <video
      data-poster={resource.videoFile.thumbnailUrl}
      id={playerElementId}
    >
      <source src={resource.videoFile.url} type={resource.videoFile.contentType} />
    </video>
  );
}

function renderThirdPartyPlayer(playerElementId, resource) {
  return (
    <div
      data-plyr-embed-id={resource.videoId}
      data-plyr-provider={resource.videoHostName}
      id={playerElementId}
    ></div>
  );
}

// Adapted from: https://github.com/sampotts/plyr/issues/806#issuecomment-1099876630
function getSeekToTime(e, playerDuration) {
  if (typeof e === "object" && (e.type === "input" || e.type === "change")) {
    const currentValue = parseFloat(e.target.value);
    const maxValue = parseFloat(e.target.max);

    return (currentValue / maxValue) * playerDuration;
  } else {
    return Number(e);
  }
}

function defaultOnPreventSkipEvent(e, seekTime, thePlayerRef) {
  if (seekTime > thePlayerRef.current.maxTimeReached) {
    // Prevent the default seek handler from running
    // Source: https://github.com/sampotts/plyr/blob/0c9759455cbfcce888c66925c3b457ce06cee31e/src/js/listeners.js#L475
    e.preventDefault();
    return false;
  }

  return true;
}

export function renderVideoPlayer(options: RenderVideoPlayerOptions) {
  const playerRef = createRef();
  ReactDOM.render(
    <VideoPlayer
      inlinePausePrompt={options.inlinePausePrompt}
      onPlyrLoaded={options.onPlyrLoaded}
      onTimeUpdate={options.onTimeUpdate}
      onEnded={options.onEnded}
      onDurationChange={options.onDurationChange}
      onEduPlayerUnavailable={options.onEduPlayerUnavailable}
      ref={playerRef}
      useEduPlayer={options.useEduPlayer}
      resource={options.resource}
      subtitlesService={options.subtitlesService}
      markers={options.markers}
      markerOnClick={options.markerOnClick}
    />,
    document.getElementById(options.elementId)
  );
  return playerRef;
}
