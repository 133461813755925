interface SignInGatePaywallAnalyticsArgs {
  feature: string;
  label: string;
  link?: string;
  clipId?: number;
  clipType?: string;
}

export function paywallAnalyticsArgs({ feature, label, clipId, clipType, link = "" }: SignInGatePaywallAnalyticsArgs) {
  const args = { feature: feature, label: label };

  if (link) args["link"] = link;
  if (clipId) args["model_id"] = clipId;
  if (clipType) args["model_type"] = clipType;

  return args;
}