import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import Nav from "react-bootstrap/Nav";
import TabContainer from "react-bootstrap/TabContainer";
import Tab from "react-bootstrap/Tab";

import { AssignmentsReportResponsesTable } from "./assignments_report_responses_table";
import { AssignmentsReportStudentsTable } from "./assignments_report_students_table";
import { Assignment, AssignmentItem, AssignmentSubmission, Course, User } from "../../types";

import styles from "./assignments_report.module.scss";

interface Props {
  assignment: Assignment;
  assignmentStudents: Array<User>;
  courses: Array<Course>;
  questionsResponsesObj: { [assignment_id: number]: AssignmentItem },
  submissionsObj: { [user_id: number]: AssignmentSubmission };
  submissionStatuses: Array<'Not Started' | 'In Progress' | 'Submitted'>;
}

function AssignmentsReport(props: Props) {
  return (
    <TabContainer id="assignments-report-tabs" defaultActiveKey="responses">
      <Nav variant="pills" fill={true} className="border rounded">
        <Nav.Item>
          <Nav.Link eventKey="responses" className={styles.leftTab}>Questions & Responses</Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link eventKey="students" className={styles.rightTab}>Assigned Students</Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content className="pb-3">
        <Tab.Pane eventKey="responses">
          <AssignmentsReportResponsesTable questionsResponsesObj={props.questionsResponsesObj} />
        </Tab.Pane>

        <Tab.Pane eventKey="students">
          <AssignmentsReportStudentsTable
            assignment={props.assignment}
            assignmentStudents={props.assignmentStudents}
            courses={props.courses}
            submissionsObj={props.submissionsObj}
            submissionStatuses={props.submissionStatuses}
          />
        </Tab.Pane>
      </Tab.Content>
    </TabContainer>
  );
}

export function renderAssignmentsReport(elementId, options) {
  const node = ReactDOM.render(
    <AssignmentsReport
      assignment={options.assignment}
      assignmentStudents={options.assignmentStudents}
      courses={options.courses}
      questionsResponsesObj={options.questionsResponsesObj}
      submissionsObj={options.submissionsObj}
      submissionStatuses={options.submissionStatuses}
    />,
    document.getElementById(elementId)
  );

  return node;
}