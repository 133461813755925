import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { AgeRange } from "../../types";

interface Props {
  ageRanges: Array<AgeRange>;
  size?: "sm" | "lg";
  baseKey?: string;
  showTooltip?: boolean;
}

export default function DisplayAgeRange({ ageRanges, size, baseKey, showTooltip = false }: Props) {
  if (ageRanges?.length < 1) {
    return null;
  }

  const buttonHtml = (ageRange: AgeRange) => {
    const btnClass = `btn-grade-level-${ageRange.abbreviation.toLowerCase()}`;

    return (
      <Button className={`btn ${btnClass}`} size={size} title={ageRange.name}>
        {ageRange.abbreviation}
      </Button>
    );
  };

  return (
    <>
      {ageRanges.map((ageRange, index) => {
        const key = `${baseKey || ''}${index}`;

        if (showTooltip) {
          return (
            <React.Fragment key={key}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`age-range-${ageRange.id}-${index}-view-tooltip`}>
                    {ageRange.name}
                  </Tooltip>
                }
              >
                {buttonHtml(ageRange)}
              </OverlayTrigger>

              {' '}
            </React.Fragment >
          );
        } else {
          return (
            <React.Fragment key={key}>
              {buttonHtml(ageRange)}
              {' '}
            </React.Fragment>
          );
        }
      })}
    </>
  );
}