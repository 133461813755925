import React, { useState } from "react";
import classNames from "classnames";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { patchData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import { DiscussionQuestion } from "../../types";

import styles from "./add_discussion_question_form.module.scss";

interface Props {
  discussionQuestion: DiscussionQuestion;
  onUpdate: (discussionQuestion: DiscussionQuestion) => void;
  onCancel: () => void;
}

export default function EditDiscussionQuestionForm({ discussionQuestion, onUpdate, onCancel }: Props) {
  const [prompt, setPrompt] = useState(discussionQuestion.prompt);
  const [privacy, setPrivacy] = useState(discussionQuestion.privacy);
  const [isDisabled, setIsDisabled] = useState(false);

  const [errors, setErrors] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setIsDisabled(true);

    let urlPath = (discussionQuestion.discussable_type === "Resource") ? "/resource_discussion_questions" : "/user_clip_discussion_questions";

    try {
      const { data } = await patchData({
        url: `${urlPath}/${discussionQuestion.id}`,
        data: {
          discussion_question: {
            prompt: prompt,
            privacy: privacy
          }
        }
      });

      onUpdate(data.discussion_question);
    } catch (e) {
      const data = e.response.data;

      if (data.errors) {
        setErrors(data.errors);
      }

      setIsDisabled(false);
    }
  };

  return (
    <>
      {errors?.length > 0 &&
        <div className="p-3">
          <FormErrorsList errors={errors} />
        </div>
      }

      <Form onSubmit={onSubmit}>
        <div className="p-3">
          <h5 className="mb-0">Edit Clip Question</h5>
        </div>

        <hr className="m-0" />

        <div className="row">
          <div className="col-md-12">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Write a prompt"
              onChange={e => setPrompt(e.target.value)}
              defaultValue={prompt}
            />
          </div>
        </div>

        <hr className="m-0" />

        <div className={classNames("d-flex flex-row flex-wrap p-3", styles.formFooter)}>
          <Button variant="outline-dark" className="px-3" onClick={onCancel}>
            Cancel
          </Button>

          <div className="flex-grow-1"></div>

          <Form.Control as="select" size="sm" className="w-25 me-3" onChange={e => setPrivacy(e.target.value)}>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </Form.Control>

          <Button type="submit" variant="dark" className="px-3" disabled={isDisabled}>
            Save Question
          </Button>
        </div>
      </Form>
    </>
  );
}