import React, { MouseEventHandler } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { Button } from 'react-bootstrap';

import cleverIcon from "./clever_icon.png";
import styles from "./sign_in_button.module.scss";

interface Props {
  url: string;
  buttonText?: 'Sign up with Clever' | 'Sign in with Clever' | string;
  width?: string;
  height?: string;
  onClick?: MouseEventHandler<EventTarget>;
}

export function CleverSignInButton({ buttonText = "Sign up with Clever", width = "100%", height = "48px", url, onClick }: Props) {
  const handleClick = (e) => {
    e.preventDefault();

    if (onClick) {
      onClick(e);
    }

    window.location.href = url;
  };
  
  return (
    <Button 
      variant="outline-dark"
      className={classNames(styles.btn, "d-inline-block")}
      style={{ width: width, height: height }}
      onClick={handleClick}
    >
      <img className={styles.clever} src={cleverIcon} width={22} height={24} />
      {buttonText}
    </Button>
  );
}

export function renderCleverSignInButton(elementId, options) {
  const node = ReactDOM.render(
    <CleverSignInButton
      buttonText={options.buttonText}
      url={options.url}
      width={options.width}
      height={options.height}
      onClick={options.onClick}
    />,
    document.getElementById(elementId)
  );

  return node;
}