import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SigninPaymentGateContainer from "../shared_components/signin_payment_gate_container";
import PaywallUpgradeSection from "./paywall_upgrade_section";
import { chAnalyticsTrackEvent } from "../analytics";

import toolsOverlayStyles from "../signin_gates/discussion_tools_overlay.module.scss";

interface Props {
  clipThumbnailUrl: string;
  containerClass?: string;
  userId?: number;
}

const ANALYTICS_FEATURE_NAME = "browse_by_genre";

function BrowseGenresPaywall(props: Props) {
  useEffect(() => {
    const args = { feature: ANALYTICS_FEATURE_NAME, label: "hit_paywall" };
    if (props.userId) args["user_id"] = props.userId;

    chAnalyticsTrackEvent("paywall", args);
  }, []);

  const onLinkClick = (e) => {
    const args = {
      feature: ANALYTICS_FEATURE_NAME,
      label: "click_paywall",
      link: e.currentTarget.href
    };
    if (props.userId) args["user_id"] = props.userId;

    chAnalyticsTrackEvent("paywall", args);
  };

  return (
    <SigninPaymentGateContainer
      containerClass={props.containerClass}
    >
      <SigninPaymentGateContainer.LeftSide className="p-4">
        <>
          <h4>Browse Genres</h4>

          <p className="mt-4">
            Easily find clips from specific genres in movies and television.
          </p>

          <p>
            Easily integrate humor into the classroom, spark students' sense of wonder,
            and provoke deep reflection and introspection.
          </p>

          <PaywallUpgradeSection onLinkClick={onLinkClick} />
        </>
      </SigninPaymentGateContainer.LeftSide>

      <SigninPaymentGateContainer.RightSide>
        <div className="d-flex justify-content-center h-100 pt-5">
          <div className={classNames(toolsOverlayStyles.backgroundContainer)}>
            <div className="p-4">
              <h5 className="text-center">Browse Clips</h5>

              <div className="input-group w-50 mt-4 mx-auto">
                <input type="text" className="form-control text-center" defaultValue="Comedy" tabIndex={-1} />

                <button type="button" className="btn btn-dark" tabIndex={-1}>
                  <FontAwesomeIcon icon="search" />
                </button>
              </div>

              <div className="mt-4 text-center">
                <img
                  src={props.clipThumbnailUrl}
                  className={classNames("rounded", toolsOverlayStyles.thumbnail)}
                  width="100%"
                  alt="Video thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </SigninPaymentGateContainer.RightSide>
    </SigninPaymentGateContainer>
  );
}

export function renderBrowseGenresPaywall(elementId, options) {
  const node = ReactDOM.render(
    <BrowseGenresPaywall
      clipThumbnailUrl={options.clipThumbnailUrl}
      containerClass={options.containerClass}
      userId={options.userId}
    />,
    document.getElementById(elementId)
  );

  return node;
}