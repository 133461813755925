import React from 'react';
import classNames from 'classnames';

import { UrlHelpers } from '../../lib/utils/url_helpers';

import styles from './slider_clickview_item.module.scss'

export interface ClickViewVideo {
  id: string,
  series: string;
  rating: string;
  duration: string;
  year_group: string;
  thumbnail_url: string;
  url: string;
  title: string;
  is_clickview: true;
}

interface SliderClickviewItemProps {
  resource: ClickViewVideo;
}

function RatingBadge({ rating }: { rating: string }) {
  switch (rating) {
    case 'G':
      return <span className={classNames('bg-green rounded-1 d-flex justify-content-center align-items-center fw-500', styles.ratingBadge)}>G</span>

    default:
      return null
  }
}

export function SliderClickviewItem(props: SliderClickviewItemProps) {
  const clickviewUrlWithReferrer = UrlHelpers.addReferrer(props.resource.url, window.location.href);

  return (
    <div className={classNames(styles.resourceContainer, 'd-flex flex-column')}>
      <div className={styles.resourceThumbnailContainer}>
        <a
          href={clickviewUrlWithReferrer}
          className="position-absolute w-100 h-100 top-0 start-0"
          target='_blank'
        >
          <img
            src={props.resource.thumbnail_url}
            alt={`${props.resource.title} thumbnail`}
            className={classNames(styles.resourceThumbnail, "rounded-3")}
          />
          <span className={classNames("resource-thumbnail-length px-2")}>
            {props.resource.duration}
          </span>
        </a>
      </div>

      <div className="d-flex flex-column mb-1">
        {props.resource.series && (
          <div className="resource-series-title">{props.resource.series}</div>
        )}
        <div className="resource-title">
          <a
            href={clickviewUrlWithReferrer}
            title={props.resource.title}
            className="resource-title-link"
            target='_blank'
          >
            {props.resource.title}
          </a>
        </div>
      </div>

      <div className="d-flex mt-1">
        {props.resource.year_group && (
          <span className='border border-dark rounded-pill px-2'>{props.resource.year_group}</span>
        )}
        {/* when there is no year group show rating */}
        {!props.resource.year_group && props.resource.rating && (
          <RatingBadge rating={props.resource.rating} />
        )}
      </div>
    </div>
  );
};