import React from "react";

import DisplayTag from "./display_tag";
import { ResourceObj } from "./slider_resource_item";

interface ResourceTagsProps {
  resource: ResourceObj;
  showOnlyFirstTag: boolean;
}

export function ResourceTags(props: ResourceTagsProps) {
  const [showMoreTags, setShowMoreTags] = React.useState(false);

  const { resource, showOnlyFirstTag } = props;
  const theTags = resource.subject_tags;

  const numTags = showOnlyFirstTag ? 1 : -1;
  const tagsToShow = theTags.slice(0, numTags);
  const remainingTags = theTags.length - 2;

  return (
    <>
      {resource.profanity_moments.some(moment => moment.approved) && (
        <div className="clip-profanity-badge display-tag">
          has profanity
        </div>
      )}
      {tagsToShow.slice(0, 2).map(tag => (
        <div key={tag.id} className="me-2 mb-2">
          <DisplayTag tag={tag} />
        </div>
      ))}
      {!showOnlyFirstTag && remainingTags > 0 && (
        <>
          <button
            id="resource-show-tags-btn"
            className="btn display-tag rounded-pill align-self-center mb-2"
            data-id={resource.id}
            onClick={() => setShowMoreTags(!showMoreTags)}
          >
            +{remainingTags} more
          </button>
          {showMoreTags && theTags.slice(2).map(tag => (
            <div key={tag.id} className="me-2 mb-2">
              <DisplayTag tag={tag} />
            </div>
          ))}
        </>
      )}
    </>
  );
}