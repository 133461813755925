import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactQuill from 'react-quill';
import { v4 as uuid } from "uuid";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { postData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import { EDITOR_FORMATS, EDITOR_MODULES } from "../../lib/quill_helper";
import { normalizeMinuteOrSecondValue, timestampFromSeconds } from "../time_functions";
import { getCurrentVideoPlayer } from "../video_player/functions";
import { PausePrompt } from "../../types";

import 'react-quill/dist/quill.snow.css';
import styles from "./new_pause_prompt_form.module.scss";
import "./new_pause_prompt_form_quill.scss"

interface Props {
  promptableType: string;
  promptableId: number;
  canAddPausePrompt: boolean;
  onCreate: (pausePrompt: PausePrompt, canAddPausePrompt: boolean) => void;
  onCancel: () => void;
  prompt?: string;
}

const baseElementId = `new-pause-prompt-form-${uuid()}`;
const autoResumeElementId = `${baseElementId}-auto-resume`;
const pauseMinutesElementId = `${baseElementId}-pause-minutes`;
const pauseSecondsElementId = `${baseElementId}-pause-seconds`;
const privacyElementId = `${baseElementId}-privacy`;

export default function NewPausePromptForm(props: Props) {
  const [prompt, setPrompt] = useState(props.prompt || '');
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [autoResume, setAutoResume] = useState(true);
  const [isPublic, setIsPublic] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);

  useEffect(() => {
    const theIntervalId = setInterval(() => {
      const thePlayer = getCurrentVideoPlayer();

      if (thePlayer) {
        setCurrentPlayerTime(thePlayer.currentTime || 0);
      }
    }, 500);

    return () => clearInterval(theIntervalId);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setIsDisabled(true);

    try {
      const { data } = await postData({
        url: "/pause_prompts",
        data: {
          pause_prompt: {
            prompt: prompt,
            start_time: currentPlayerTime,
            pause_duration_minutes: minutes,
            pause_duration_seconds: seconds,
            auto_resume: autoResume,
            public: isPublic,
            promptable_id: props.promptableId,
            promptable_type: props.promptableType
          }
        }
      });

      props.onCreate(data.pause_prompt, data.can_add_pause_prompt);
    } catch (e) {
      if (e.response.data.errors) {
        setErrors(e.response.data.errors);
      }

      setIsDisabled(false);
    }
  };

  return (
    <>
      {errors.length > 0 &&
        <div className="p-3">
          <FormErrorsList errors={errors} />
        </div>
      }

      <Form onSubmit={onSubmit}>
        <div className="d-flex flex-wrap align-items-center p-3">
          <h5 className="mb-0 me-3">Add Prompt</h5>
          <span className={classNames("rounded-pill", "px-2", styles.timeDisplay)}>
            {timestampFromSeconds(currentPlayerTime)}
          </span>
        </div>

        <hr className="m-0" />

        <div className="pt-3 px-3">
          <Row className="gb-2 mb-2">
            <Form.Group className="col-md-12">
              <ReactQuill
                className={styles.editor}
                theme="snow"
                value={prompt}
                modules={EDITOR_MODULES}
                formats={EDITOR_FORMATS}
                onChange={setPrompt}
              />
            </Form.Group>
          </Row>

          <Row className="gb-2 mb-2">
            <Form.Group className="col-md-6">
              <Form.Label htmlFor={autoResumeElementId}>Add timer</Form.Label>
              <Form.Control as="select" id={autoResumeElementId} defaultValue={autoResume.toString()} onChange={e => setAutoResume(e.target.value === "true")}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label htmlFor={privacyElementId}>Visibility</Form.Label>
              <Form.Control as="select" id={privacyElementId} defaultValue={isPublic.toString()} onChange={e => setIsPublic(e.target.value === "true")}>
                <option value="true">Public</option>
                <option value="false">Private</option>
              </Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group className="col-md-6">
              <Form.Label htmlFor={pauseMinutesElementId}>Pause duration</Form.Label>
              <div className="input-group">
                <Form.Control
                  id={pauseMinutesElementId}
                  type="number"
                  min={0}
                  max={59}
                  disabled={!autoResume}
                  value={minutes}
                  className="border-end-0"
                  onChange={e => setMinutes(normalizeMinuteOrSecondValue(e.target.value))}
                />

                <span className="input-group-text bg-transparent">min</span>
              </div>
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label className="invisible">Pause duration</Form.Label>

              <div className="input-group">
                <Form.Control
                  id={pauseSecondsElementId}
                  type="number"
                  min={0}
                  max={59}
                  disabled={!autoResume}
                  value={seconds}
                  className="border-end-0"
                  onChange={e => setSeconds(normalizeMinuteOrSecondValue(e.target.value))}
                />

                <span className="input-group-text bg-transparent">sec</span>
              </div>
            </Form.Group>
          </Row>
        </div>

        <hr className="mt-4 mb-1" />

        <div className="d-flex flex-row flex-wrap justify-content-end p-3">
          <Button variant="link" className="text-reset text-decoration-none" onClick={props.onCancel}>
            Cancel
          </Button>

          <Button type="submit" variant="dark" className="px-3" disabled={isDisabled}>
            Add prompt
          </Button>
        </div>
      </Form>
    </>
  );
}
