import React, { useState, useEffect } from "react";
import { VideoSubtitlesService } from "../video_player/video_subtitles_service";

import "./styles.scss";

export function Subtitles(props: { containerId: string, subtitlesService?: VideoSubtitlesService; }) {
  const [subtitleStr, setSubtitleStr] = useState(null);
  const { containerId, subtitlesService } = props;

  const subtitlesUpdated = (subtitlesStr) => {
    setSubtitleStr(subtitlesStr);
  };

  useEffect(() => {
    // TODO: I don't like that we're overriding the event rather than listening to it, but this
    // works for our current needs. Would this prevent multiple events from listening to this event?
    subtitlesService.onSubtitlesUpdate = subtitlesUpdated.bind(this);
  }, []);

  if (!subtitleStr) return null;

  return (
    <div id={containerId} className="onscreen_subtitles">
      {subtitleStr}
    </div>
  );
}
