import React from "react";

export interface Tag {
  id: number;
  name: string;
}

interface Props {
  tag: Tag;
  enableClicks?: boolean;
}

export default function DisplayTag({ tag, enableClicks = true }: Props) {
  const queryParams = {
    q: tag.name,
    "filters[tags][]": tag.id.toString()
  };

  const queryString = new URLSearchParams(queryParams).toString();
  const tagUrl = `/search?${queryString}`

  return (
    <div className="display-tag rounded-pill">
      {enableClicks &&
        <a href={tagUrl} className="text-reset text-decoration-none">
          {tag.name}
        </a>
      }

      {!enableClicks && <span className="d-inline-block text-truncate w-max-100" title={tag.name}>{tag.name}</span>}
    </div>
  );
}
