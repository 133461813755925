import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import SigninPaymentGateContainer from "../shared_components/signin_payment_gate_container";
import DiscussionToolsOverlay from "./discussion_tools_overlay";
import { SigninSection } from "./signin_section";
import { chAnalyticsTrackEvent } from "../analytics";
import { paywallAnalyticsArgs } from "../analytics/analytics_helper";

interface Props {
  googleClientId: string;
  cleverUrl: string;
  clipTitle: string;
  clipThumbnailUrl: string;
  clipId?: number;
  clipType?: string;
  showCloseButton?: boolean;
  containerClass?: string;
  onClose?: () => void;
}

const ANALYTICS_FEATURE_NAME = "view_clip";

export function ClipSigninGate({ showCloseButton = false, ...props }: Props) {
  const handleOnClose = useCallback(() => {
    props.onClose?.();
  }, []);

  const onLinkClick = (e) => {
    const args = paywallAnalyticsArgs({
      feature: ANALYTICS_FEATURE_NAME,
      label: "click_signin_gate",
      clipId: props.clipId,
      clipType: props.clipType,
      link: e.currentTarget.href
    });

    chAnalyticsTrackEvent("signin_gate", args);
  };

  useEffect(() => {
    const args = paywallAnalyticsArgs({
      feature: ANALYTICS_FEATURE_NAME,
      label: "hit_signin_gate",
      clipId: props.clipId,
      clipType: props.clipType
    });

    chAnalyticsTrackEvent("signin_gate", args);
  }, []);

  return (
    <SigninPaymentGateContainer
      showCloseButton={showCloseButton}
      onClose={handleOnClose}
      containerClass={props.containerClass}
    >
      <SigninPaymentGateContainer.LeftSide>
        <>
          <h5>To view this clip, please create an account.</h5>

          <div className="mt-5 text-center">
            <SigninSection
              googleClientId={props.googleClientId}
              cleverUrl={props.cleverUrl}
              onLinkClick={onLinkClick}
              buttonWidth={230}
            />
          </div>
        </>
      </SigninPaymentGateContainer.LeftSide>

      <SigninPaymentGateContainer.RightSide>
        <div className="d-flex justify-content-center h-100">
          <DiscussionToolsOverlay
            clipTitle={props.clipTitle}
            clipThumbnailUrl={props.clipThumbnailUrl}
          />
        </div>
      </SigninPaymentGateContainer.RightSide>
    </SigninPaymentGateContainer>
  );
}

export function renderClipSigninGate(elementId, options) {
  const node = ReactDOM.render(
    <ClipSigninGate
      googleClientId={options.googleClientId}
      cleverUrl={options.cleverUrl}
      clipTitle={options.clipTitle}
      clipThumbnailUrl={options.clipThumbnailUrl}
      clipId={options.clipId}
      clipType={options.clipType}
      showCloseButton={options.showCloseButton}
      containerClass={options.containerClass}
      onClose={options.onClose}
    />,
    document.getElementById(elementId)
  );

  return node;
}