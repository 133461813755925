export function hideBackButton(
  isHoveringOnSlider: boolean,
  sliderOffset: number,
  maxViewableItems: number,
  sliderCollectionSize: number,
  isTabbing: boolean
): boolean {
  // if the collection size is smaller than the max viewable items, hide the back button
  if (sliderCollectionSize <= maxViewableItems)
    return true;

  // When hovering on the entire slider area, hide the back button if the slider is in the far left position
  if (isHoveringOnSlider)
    return (sliderOffset === maxViewableItems);

  if (isTabbing)
    return sliderOffset === maxViewableItems;

  return true;
}

export function hideNextButton(
  isHoveringOnSlider: boolean,
  sliderOffset: number,
  maxViewableItems: number,
  sliderCollectionSize: number,
  isTabbing: boolean
): boolean {
  // if the collection size is smaller than the max viewable items, hide the next button
  if (sliderCollectionSize <= maxViewableItems)
    return true;

  // Always show the next button if the slider is in the far left position
  if (sliderOffset === maxViewableItems)
    return false;

  // When the user is hovering over the entire slider area...
  if (isHoveringOnSlider) {
    // Only show the next button if there are still more items to show
    return sliderCollectionSize <= sliderOffset;
  }

  if (isTabbing)
    return sliderOffset === sliderCollectionSize;

  return true;
}