import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

import { Option } from "../../types";

interface Props {
  defaultSelectedOptions: Array<Option>;
  options: Array<Option>;
  prompt: string;
  htmlName: string;
  closeMenuOnSelect?: boolean;
}

function MultiSelectFormGroup({ closeMenuOnSelect = false, ...props }: Props) {
  const preselectedOptions = props.defaultSelectedOptions.filter(option1 => props.options.some(option2 => option1.value === option2.value));

  return (
    <div>
      <label htmlFor={props.htmlName}>{props.prompt}</label>
      <Select
        options={props.options}
        isMulti
        defaultValue={preselectedOptions}
        name={props.htmlName}
        closeMenuOnSelect={closeMenuOnSelect}
      />
    </div>
  );
}

export function renderMultiSelectFormGroup(elementId, options) {
  const node = ReactDOM.render(
    <MultiSelectFormGroup
      defaultSelectedOptions={options.defaultSelectedOptions}
      options={options.options}
      prompt={options.prompt}
      htmlName={options.htmlName}
      closeMenuOnSelect={options.closeMenuOnSelect}
    />,
    document.getElementById(elementId)
  );

  return node;
}