import React from "react";
import {
  Table,
  Button
} from "react-bootstrap";
import DisplayAgeRange from "../../resources/display_age_range";
import DisplayTag from "../../resources/display_tag";
import AddClipButton from "./add_clip_button";

interface PlaylistObject {
  id: number;
  title: string;
  items: Array<object>;
}

interface Props {
  playlists: Array<PlaylistObject>;
  queueableType: string;
}

export default function Playlists({ playlists, queueableType}: Props): React.ReactNode {
  return (
    <div className="tab-clip-list">
      <div id="playlists-tab-accordion" className="accordion">
        { playlists.map((playlist,index) => (
          <div className="card" key={index}>
            <div className="card-header" id={`playlist-${playlist.id}-title`}>
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#playlist-${playlist.id}-content`} aria-expanded="false" aria-controls={`playlist-${playlist.id}-content`}>
                    {playlist.title}
                </button>
              </h2>
            </div>

            <div id={`playlist-${playlist.id}-content`} className="collapse" aria-labelledby={`playlist-${playlist.id}-title`} data-parent="#playlists-tab-accordion">
              <div className="card-body m-0 p-0">
                <Table striped className="tab-clip-list mb-0" responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Clip</th>
                        <th>Subjects</th>
                        <th>Grades</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        playlist.items.map((item,index) =>(
                          <tr key={index}>
                            <td>
                              <AddClipButton
                                queueableType={queueableType}
                                video={item.resource}
                              />
                            </td>

                            <td>
                              <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                            </td>

                            <td>
                              <div className="d-flex flex-wrap small">
                                { item.subject_tags.map((tag, index) => (
                                  <div className="mb-2 me-2" key={index}>
                                    <DisplayTag tag={tag}/>
                                  </div>
                                ))}
                              </div>
                            </td>

                            <td>
                              <DisplayAgeRange
                                ageRanges={item.resource.age_ranges}
                                
                              />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                </Table>
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  )
}
