import { BrazeClient, ClassHookMarketingFormEvent } from '@clickview/braze-client';

interface BrazeSdkConfig {
  url: string;
  key: string;
}

/**
 * This is temporary so that we can use en-gb locale braze config on en-au for welcome entrust LP
 */
type BrazeConfig = {
  common: {
    version: string;
    name: string;
  },
  enableLogging: boolean;
  sdk: BrazeSdkConfig;
}

export const braze: BrazeConfig = {
  common: {
    name: 'ClassHook',
    version: '0.0.0',
  },
  enableLogging: (process.env.NODE_ENV === "production" && process.env.SERVER_ENV === "production") || false,
  sdk: {
    url: process.env.EN_US_BRAZE_SDK_URL || '',
    key: process.env.EN_US_BRAZE_SDK_KEY || ''
  }
};

export const BrazeHelper = {
  async getClient() {
    return new BrazeClient({
      apiOptions: {
        productName: braze.common.name,
        productVersion: braze.common.version,
        baseUrl: braze.sdk.url,
        apiKey: braze.sdk.key,
        enableLogging: braze.enableLogging
      },
      allowAnonymous: true,
      shouldInitialize: true
    });
  },

  async getEnums() {
    return {
      ClassHookMarketingFormEvent,
    };
  }
};