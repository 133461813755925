import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Comment, PausePromptComment } from "../pause_prompts/pause_prompt_comment";
import { fetchData, patchData } from "../global_functions";

import "./discussion_helper.scss";
import { timestampFromSeconds } from "../time_functions";

export interface DiscussionProps {
  elementId?: string;
  title: string;
  roomCode: string;
  objective: string;
  name: string;
  id: string;
  discussionRoomId?: number;
  isPaidOrTrialUser?: boolean;
  roomCodeId?: number;
  userPreferences?: {
    subtitlesOn: boolean;
    skipProfanity: boolean;
    censorProfanity: boolean;
  },
  hideResponses?: boolean;
}

interface DiscussionSectionProps {
  children: React.ReactNode;
  title: string;
  objective: string;
  roomCode?: string;
  isStudent: boolean;
}

export function DiscussionSection({ children, title, objective, roomCode, isStudent }: DiscussionSectionProps) {
  return (
    <div>
      {roomCode &&
        <div className="me-3 mb-4">
          Join at: www.classhook.com

          <div>Join Code: {roomCode}</div>
        </div>
      }

      <h3>{title}</h3>

      {objective?.length > 0 &&
        <p className="mb-4">
          {isStudent ? "Today's" : "Lesson"} Objective: {objective}
        </p>
      }

      {children}
    </div>
  );
}
interface ParticipantsProps {
  containerClass?: string;
  userList: Array<object>;
  isStudent: boolean;
  responseIndicators: Set<string>;
  id: string;
}

export function Participants({ containerClass, userList, isStudent, responseIndicators, id }: ParticipantsProps) {
  const removeUser = (userId) => () => {
    App.cable.subscriptions.subscriptions[0].removeUser({ student_uuid: userId });
  };

  return (
    <div className={containerClass}>
      <div className="text-center px-3 py-2 rounded-top">
        <FontAwesomeIcon icon="users" size="lg" className="me-2" />
        Participants
      </div>

      <hr className="m-0" />

      <div className="px-3 pt-3 rounded-bottom participants-container">
        {userList.map(user =>
          <div key={user.id} className="d-flex">
            <div className="position-relative avatar-wrapper">
              <FontAwesomeIcon icon="user-circle" size="2x" className="me-2" />
              {responseIndicators.has(user.id) &&
                <FontAwesomeIcon icon="check-circle" className="response-indicator" color="green" />
              }
            </div>

            <p>{user.name} {id === user.id && " (You) "}</p>

            {!isStudent && id !== user.id &&
              <>
                <div className="flex-grow-1"></div>
                <FontAwesomeIcon
                  icon="window-close"
                  size="lg"
                  color="red"
                  onClick={removeUser(user.id)}
                  className="icon-hover"
                />
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
}

interface UserJson {
  isStudent: boolean;
  name: string;
}

interface SubscribeProps {
  roomCode: string;
  userJson: UserJson;
  receivedCallback: Function;
}

interface ClassResponsesProps {
  classResponses: Array<Comment>;
  isTeacher: boolean;
  hideResponses: boolean;
}

export function subscribeToChannel({ roomCode, userJson, receivedCallback }: SubscribeProps) {
  return (
    App.cable.subscriptions.create(
      {
        channel: "LiveDiscussionChannel",
        code: roomCode,
        ...userJson,
      },
      {
        received: receivedCallback,
        removeUser: function (data) {
          this.perform("remove_user", data);
        },
        pausePromptShown: function (data) {
          this.perform("pause_prompt_shown", data);
        },
      }
    )
  );
}

export function ClassResponses({ classResponses, isTeacher }: ClassResponsesProps): React.ReactNode {
  const toggleResponseVisibility = (comment) => () => {
    const formData = {
      comment: {
        is_hidden: !comment.is_hidden,
      }
    };

    return patchData({ url: `/pause_prompt_comments/${comment.id}`, data: formData });
  }

  return (
    <div className="class-responses">
      {
        classResponses.map((comment, index) =>
          <div className="mb-2" key={index}>
            {index > 0 &&
              <hr className="my-2" />
            }

            <div className="d-flex">
              <PausePromptComment comment={comment} key={`${comment.id}`} />

              {isTeacher &&
                <>
                  <div className="flex-grow-1"></div>

                  <Button variant="link" className="text-reset" onClick={toggleResponseVisibility(comment)}>
                    {comment.is_hidden ?
                      <FontAwesomeIcon icon="eye-slash" size="lg" /> :
                      <FontAwesomeIcon icon="eye" size="lg" />
                    }
                    <span className="sr-only">{comment.is_hidden ? "Show comment" : "Hide comment"}</span>
                  </Button>
                </>
              }
            </div>
          </div>
        )
      }
    </div>
  );
}

export interface Video {
  yt_end_time?: string;
  end_time_seconds?: number;
  yt_start_time?: string;
  start_time_seconds?: number;
  video_host_name: string;
  video_id: string;
  is_entire_video: boolean;
  resource_type?: string;
  id?: number;
  video_file?: {
    content_type: string;
    thumbnail_url?: string;
    url: string;
  }
}

export interface DiscussionQueueVideo {
  id: number;
  discussion_room_id: number;
  position: number;
  queueable: Video;
}

export interface VideoPlayerResource {
  endTime: number | string;
  entireVideo: boolean;
  startTime: number | string;
  videoHostName: string;
  videoId: string;
}

// use to comply with video player resource interface
export function createVideoPlayerResource(currentVideo: Video): VideoPlayerResource {
  if (!currentVideo) return null;

  const videoPlayerObj = {
    endTime: currentVideo.yt_end_time || currentVideo.end_time_seconds,
    startTime: currentVideo.yt_start_time || currentVideo.start_time_seconds,
    videoHostName: currentVideo.video_host_name,
    videoId: currentVideo.video_id,
    entireVideo: currentVideo.is_entire_video
  };

  if (currentVideo.video_host_name === "classhook") {
    videoPlayerObj["videoFile"] = {
      contentType: currentVideo.video_file.content_type,
      thumbnailUrl: currentVideo.video_file.thumbnail_url,
      url: currentVideo.video_file.url
    };
  }

  return videoPlayerObj;
}

export interface VideoIdParams {
  promptable_type?: string;
  promptable_id?: number;
}

export function buildVideoIdParams(currentVideo: DiscussionQueueVideo): VideoIdParams {
  if (!currentVideo) return {};

  const videoIdParams = { promptable_type: "DiscussionQueueVideo", promptable_id: currentVideo.id };

  return videoIdParams;
}

export function fetchPausePrompts(currentVideo: DiscussionQueueVideo, userId: string): Promise<any> {
  return fetchData(
    {
      url: `/users/${userId}/pause_prompts`,
      params: buildVideoIdParams(currentVideo)
    }
  );
}

export function fetchExistingVideoPausePrompts(currentVideo: DiscussionQueueVideo): Promise<any> {
  const promptableType = currentVideo.queueable.hasOwnProperty("resource_type") ? "Resource" : "UserClip";

  return fetchData({
    url: "/pause_prompts/prompts_for_clip",
    params: {
      promptable_type: promptableType,
      promptable_id: currentVideo.queueable.id
    }
  });
}

export function PresentationPausePrompt({ pausePrompt: { start_time }, playerRef, isCurrentPrompt }): React.ReactNode {
  const handleSeek = (e) => {
    e.preventDefault();

    // Increase the reliability of showing the pause prompt by seeking to the beginning of the second
    // The timer may otherwise run after the pause prompt time window has passed
    const theStartTime = Math.trunc(start_time);
    playerRef?.current?.seekTo(theStartTime);
  };

  return (
    <div className="d-flex">
      {isCurrentPrompt &&
        <div className="align-self-center">
          <FontAwesomeIcon icon="arrow-right" />
        </div>
      }

      <Button onClick={handleSeek} variant="link">
        <FontAwesomeIcon icon="clock" size="lg" className="me-2" />
        <span>{timestampFromSeconds(start_time)}</span>
      </Button>
    </div>
  );
}