import React, { createRef, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { ReactSVG } from "react-svg";

import NewPausePromptForm from "./new_pause_prompt_form";
import EditPausePromptForm from "./edit_pause_prompt_form";
import AddPromptButton from "./add_prompt_button";
import UpgradeButton from "../shared_components/upgrade_button";
import { focusVideoPlayerAddEditMode } from "../resources/discussion_tools_section";

import { deleteData, postData } from "../global_functions";
import { chAnalyticsTrackEvent } from "../analytics";
import { getCurrentPausePromptService, getCurrentVideoPlayer, markersFromPausePrompts } from "../video_player/functions";
import { ClipType, PausePrompt, User } from "../../types";

import { MODES, UPDATE_ROW_HIGHLIGHT_DURATION, CUSTOM_TABLE_STYLES } from "../discussion_questions/constants_and_types";

import plusCircleIcon from "../shared_components/images/plus-circle_icon.svg";
import moreIcon from "../shared_components/images/ellipsis-v_icon.svg";

import styles from "../shared_components/discussion_tools.module.scss";

function upgradeTooltip(elementId: string) {
  return (
    <Tooltip id={elementId}>
      {UPGRADE_BUTTON_TOOLTIP}
    </Tooltip>
  );
}

interface Props {
  canAddPausePrompt: boolean;
  promptableType: ClipType;
  promptableId: number;
  pausePrompts: Array<PausePrompt>;
  user: User;
  mode?: MODES;
}

const UPGRADE_BUTTON_TOOLTIP = "Upgrade to Premium to add more prompts.";

export const PausePromptsTable = forwardRef((props: Props, ref) => {
  const [pausePrompts, setPausePrompts] = useState<Array<PausePrompt>>([...props.pausePrompts]);
  const [updatedPausePromptId, setUpdatedPausePromptId] = useState<number>();
  const [selectedPausePrompt, setSelectedPausePrompt] = useState<PausePrompt>();
  const [canAddPausePrompt, setCanAddPausePrompts] = useState(props.canAddPausePrompt);
  const [mode, setMode] = useState<MODES>(props.mode);
  const [newPromptText, setNewPromptText] = useState('');

  useImperativeHandle(ref, () => ({
    setActiveMode: (mode: MODES) => {
      setMode(mode);
    },
    updateNewPromptText: (promptText: string) => {
      setNewPromptText(promptText);
    },
    toggleAddClicked: (buttonClicked: string = "add_button") => {
      onAddPromptClicked(buttonClicked);
    }
  }));

  const onTimeSeeked = (e, pausePrompt: PausePrompt) => {
    e.preventDefault();

    const player = getCurrentVideoPlayer();

    if (!player) return;

    player.seekTo(pausePrompt.start_time);

    chAnalyticsTrackEvent("click", {
      clip_id: props.promptableId,
      clip_type: props.promptableType,
      model_id: pausePrompt.id,
      model_type: "PausePrompt",
      link: "seek_pause_prompt",
      label: "pause_prompts_table"
    });
  };

  const tableColumns = useMemo(() => [
    {
      name: "Time",
      sortable: true,
      wrap: true,
      grow: 0,
      cell: (pausePrompt: PausePrompt) => {
        return (
          <a href="#" className={classNames("rounded-pill", "px-2", styles.timestamp)} onClick={e => onTimeSeeked(e, pausePrompt)}>
            {pausePrompt.display_start_time}
          </a>
        );
      }
    },
    {
      name: "Prompt",
      selector: (pausePrompt: PausePrompt) => pausePrompt.plaintext_prompt,
      sortable: true,
      wrap: true
    },
    {
      name: "Owner",
      sortable: true,
      wrap: true,
      grow: 0,
      minWidth: "120px",
      selector: (pausePrompt: PausePrompt) => (props.user.id === pausePrompt.user_id) ? "You" : "Community"
    },
    {
      name: "Duration",
      selector: (pausePrompt: PausePrompt) => pausePrompt.display_pause_duration,
      sortable: true,
      wrap: true,
      grow: 0,
      minWidth: "130px"
    },
    {
      name: "Visibility",
      selector: (pausePrompt: PausePrompt) => pausePrompt.display_publicity_status,
      sortable: true,
      grow: 0,
      minWidth: "125px"
    },
    {
      grow: 0,
      center: true,
      width: "70px",
      cell: (pausePrompt: PausePrompt) => {
        const actionMenuId = `pause_prompt_${pausePrompt.id}_actions`;
        const isCreator = (props.user.id === pausePrompt.user_id);

        if (isCreator || props.user.is_admin) {
          return (
            <Dropdown>
              <Dropdown.Toggle id={actionMenuId} variant="link" size="sm" className={classNames(styles.dropdownToggle, "text-reset")}>
                <ReactSVG src={moreIcon} wrapper="span" className={styles.svgTopAlign} />
                <span className="sr-only">Actions</span>
              </Dropdown.Toggle>

              <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                <DropdownItem onClick={() => onEditPrompt(pausePrompt)}>Edit</DropdownItem>
                <DropdownItem onClick={() => onDeletePrompt(pausePrompt)}>Delete</DropdownItem>
              </Dropdown.Menu>
            </Dropdown>
          );
        } else {
          const toolTipElementId = `pause-prompts-table-duplicate-prompt-tooltip-${pausePrompt.id}`;

          if (canAddPausePrompt) {
            return (
              <OverlayTrigger placement="top" overlay={<Tooltip id={toolTipElementId}>Add to my prompts</Tooltip>}>
                <div className="d-inline-block">
                  {renderDuplicatePausePromptButton(canAddPausePrompt, () => onDuplicatePrompt(pausePrompt))}
                </div>
              </OverlayTrigger>
            );
          } else {
            return (
              <OverlayTrigger placement="top" overlay={upgradeTooltip(toolTipElementId)}>
                <div className="d-inline-block">
                  {renderDuplicatePausePromptButton(canAddPausePrompt, () => null)}
                </div>
              </OverlayTrigger>
            );
          }
        }
      }
    }
  ], [pausePrompts, canAddPausePrompt]);

  useEffect(() => {
    if (!updatedPausePromptId) return;

    setTimeout(() => {
      setUpdatedPausePromptId(null);
    }, UPDATE_ROW_HIGHLIGHT_DURATION);
  }, [updatedPausePromptId]);

  useEffect(() => {
    focusVideoPlayerAddEditMode(mode, styles.addOrEditModeHighlight);

    document.body.removeEventListener("keyup", onKeyUp);

    if (mode != null) {
      document.body.addEventListener("keyup", onKeyUp);
    }
  }, [mode]);

  // Update the current video player
  useEffect(() => {
    const player = getCurrentVideoPlayer();

    if (!player) return;

    const userPausePrompts = pausePrompts.filter(prompt => prompt.user_id === props.user.id);

    const markers = markersFromPausePrompts(userPausePrompts);
    player.setMarkers(markers);

    const pausePromptService = getCurrentPausePromptService();

    if (!pausePromptService) return;

    const newPausePrompts = [...userPausePrompts];

    pausePromptService.pausePrompts = newPausePrompts;
  }, [pausePrompts]);

  const onKeyUp = useMemo(() => {
    return (e) => {
      if (e.key === "Escape") {
        onCancelEdit();
      }
    };
  }, []);

  const onCancelCreate = () => {
    setMode(null);
    setNewPromptText('');
  };

  const onCancelEdit = () => {
    setMode(null);
    setSelectedPausePrompt(null);
    setNewPromptText('');
  };

  const onCreatePrompt = (newPausePrompt: PausePrompt, userCanAddPausePrompt: boolean) => {
    const newPausePrompts = [...pausePrompts, newPausePrompt].sort((pausePrompt1, pausePrompt2) => pausePrompt1.start_time - pausePrompt2.start_time);

    setPausePrompts(newPausePrompts);
    setCanAddPausePrompts(userCanAddPausePrompt);
    setMode(null);
    setUpdatedPausePromptId(newPausePrompt.id);
    setNewPromptText('');
  };

  const onEditPrompt = (pausePrompt: PausePrompt) => {
    chAnalyticsTrackEvent("click", {
      clip_id: props.promptableId,
      clip_type: props.promptableType,
      model_id: pausePrompt.id,
      model_type: "PausePrompt",
      link: "edit_pause_prompt",
      label: "pause_prompts_table"
    });

    setSelectedPausePrompt(pausePrompt);
    setMode("edit");
  };

  const onUpdatePrompt = (updatedPausePrompt: PausePrompt) => {
    const existingPromptIndex = pausePrompts.findIndex(prompt => prompt.id === updatedPausePrompt.id);

    let updatedPausePrompts = [...pausePrompts];
    updatedPausePrompts.splice(existingPromptIndex, 1, updatedPausePrompt);

    updatedPausePrompts = updatedPausePrompts.sort((pausePrompt1, pausePrompt2) => pausePrompt1.start_time - pausePrompt2.start_time);

    setPausePrompts(updatedPausePrompts);
    setMode(null);
    setUpdatedPausePromptId(updatedPausePrompt.id);
  };

  const onDuplicatePrompt = async (pausePromptToDuplicate: PausePrompt) => {
    const { data } = await postData({ url: `/pause_prompts/${pausePromptToDuplicate.id}/duplicate` });
    const newPausePrompt = data.pause_prompt;

    const newPausePrompts = [...pausePrompts, newPausePrompt].sort((pausePrompt1, pausePrompt2) => pausePrompt1.start_time - pausePrompt2.start_time);

    setPausePrompts(newPausePrompts);
    setCanAddPausePrompts(data.can_add_pause_prompt);
    setUpdatedPausePromptId(newPausePrompt.id);
  };

  const onDeletePrompt = async (pausePromptToDelete: PausePrompt) => {
    const { data } = await deleteData({ url: `/pause_prompts/${pausePromptToDelete.id}` });
    const updatedPrompts = [...pausePrompts].filter(pausePrompt => pausePrompt.id !== data.pause_prompt.id);

    setPausePrompts(updatedPrompts);
    setCanAddPausePrompts(data.can_add_pause_prompt);
  };

  const onAddPromptClicked = (buttonClicked: string = "pause_prompts_table_add_button") => {
    chAnalyticsTrackEvent("click", {
      model_id: props.promptableId,
      model_type: props.promptableType,
      link: 'add_pause_prompt',
      label: 'pause_prompts_table',
      button_clicked: buttonClicked
    });

    setMode("add");
  };

  const onUpgradeButtonClicked = () => {
    chAnalyticsTrackEvent("paywall", {
      user_id: props.user.id,
      model_id: props.promptableId,
      model_type: props.promptableType,
      feature: "pause_prompts",
      label: "click_paywall",
      link: "upgrade_button"
    });
  };

  return (
    <>
      <div
        className={classNames("content-container", mode != null ? styles.addOrEditModeHighlight : null)}
        {...(mode != null && { 'data-discussion-mode-active': "true" } )}
      >
        {mode === "add" &&
          <NewPausePromptForm
            promptableId={props.promptableId}
            promptableType={props.promptableType}
            canAddPausePrompt={canAddPausePrompt}
            onCreate={onCreatePrompt}
            onCancel={onCancelCreate}
            prompt={newPromptText}
          />
        }

        {mode === "edit" &&
          <EditPausePromptForm
            pausePrompt={selectedPausePrompt}
            onUpdate={onUpdatePrompt}
            onCancel={onCancelEdit}
          />
        }

        {mode == null &&
          <>
            <div className="px-3 py-2 mb-3">
              <div className="d-flex flex-wrap align-items-center">
                <h5 className="my-1 me-3 fw-500">Pause prompts</h5>
                <div className="flex-grow-1"></div>

                {canAddPausePrompt &&
                  <AddPromptButton
                    canUserAddPrompt={canAddPausePrompt}
                    btnText="Add prompt"
                    extraClasses="my-1"
                    showIcon={true}
                    onClick={() => onAddPromptClicked()}
                  />
                }

                {!canAddPausePrompt &&
                  <>
                    <div className="d-inline-block my-1 me-2">
                      <AddPromptButton
                        canUserAddPrompt={canAddPausePrompt}
                        btnText="Add prompt"
                        showIcon={true}
                      />
                    </div>

                    <div className="d-inline-block my-1">
                      <UpgradeButton
                        tooltipText={UPGRADE_BUTTON_TOOLTIP}
                        onClick={onUpgradeButtonClicked}
                      />
                    </div>
                  </>
                }
              </div>
            </div>

            <DataTable
              columns={tableColumns}
              customStyles={CUSTOM_TABLE_STYLES}
              conditionalRowStyles={[
                {
                  when: (pausePrompt: PausePrompt) => updatedPausePromptId && (pausePrompt.id === updatedPausePromptId),
                  classNames: [styles.highlightedRow]
                }]
              }
              data={pausePrompts}
              noDataComponent={
                <div className="p-3 text-center">
                  <div className="mb-2">
                    <FontAwesomeIcon icon="inbox" size="2x" />
                  </div>

                  <div>
                    There's nothing here! Add a pause prompt above.
                  </div>
                </div>
              }
              responsive
              fixedHeader
            />
          </>
        }
      </div>
    </>
  );
});

function renderDuplicatePausePromptButton(canAddPausePrompt: boolean, onClick: () => void) {
  return (
    <Button type="button" variant="link" size="sm" disabled={!canAddPausePrompt} onClick={onClick}>
      <ReactSVG src={plusCircleIcon} wrapper="span" />
      <span className="sr-only">Add as My Prompt</span>
    </Button>
  );
}

export function renderPausePromptsTable(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <PausePromptsTable
      canAddPausePrompt={options.canAddPausePrompt}
      promptableId={options.promptableId}
      promptableType={options.promptableType}
      pausePrompts={options.pausePrompts}
      user={options.user}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}