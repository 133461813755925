import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import classNames from 'classnames';

import { isStudentMatch } from "./assignments_helper";
import { Assignment, AssignmentSubmission, Course, User } from "../../types";

import styles from './assignments.module.scss';

interface Props {
  assignment: Assignment;
  assignmentStudents: Array<User>;
  courses: Array<Course>;
  submissionsObj: {
    [user_id: number]: AssignmentSubmission;
  };
  submissionStatuses: Array<'Not Started' | 'In Progress' | 'Submitted'>;
}

export function AssignmentsReportStudentsTable({ assignment, assignmentStudents, courses, submissionsObj, submissionStatuses }: Props) {
  const [filterText, setFilterText] = useState("");
  const [filteredCourseIds, setFilteredCourseIds] = useState([]);
  const [filteredSubmissionStatuses, setFilteredSubmissionStatuses] = useState([]);

  const filteredStudents = useMemo(() => {
    let theStudents = assignmentStudents;

    if (filterText.length > 0) {
      theStudents = theStudents.filter(student => isStudentMatch(student, filterText));
    }

    if (filteredCourseIds.length > 0) {
      theStudents = theStudents.filter(student => {
        return student.courses.find(course => filteredCourseIds.includes(course.id));
      });
    }

    if (filteredSubmissionStatuses.length > 0) {
      theStudents = theStudents.filter(student => {
        const valueToSearch = submissionsObj[student.id]?.display_status || 'Not Started';

        return filteredSubmissionStatuses.includes(valueToSearch);
      });
    }

    return theStudents;
  }, [filterText, filteredCourseIds, filteredSubmissionStatuses]);

  const handleFilterChange = e => setFilterText(e.target.value);
  const handleFilterCourses = (ary) => {
    const theAry = ary || [];
    const courseIds = theAry.map(courseObj => courseObj.value);

    setFilteredCourseIds(courseIds);
  };

  const handleFilterSubmissionStatus = (ary) => {
    const theAry = ary || [];
    const submissionStatuses = theAry.map(submissionObj => submissionObj.value);

    setFilteredSubmissionStatuses(submissionStatuses);
  };

  const tableColumns = [
    {
      name: "Student Name",
      selector: student => student.name,
      sortable: true,
      wrap: true
    },
    {
      name: "Email",
      selector: student => student.email,
      sortable: true,
      wrap: true,
      hide: "md"
    },
    {
      name: "Courses",
      selector: student => student.courses.map(course => course.name).join(', '),
      sortable: true,
      wrap: true,
      cell: student => {
        return student.courses.map(course => {
          return (<a href={`/courses/${course.id}`} target="_blank" rel="noopener noreferrer">{course.name}</a>);
        });
      }
    },
    {
      name: "Submission Status",
      selector: student => submissionsObj[student.id]?.display_status || 'Not Started',
      sortable: true,
      wrap: true,
      cell: student => submissionsObj[student.id]?.display_status || 'Not Started'
    },
    {
      name: "Submitted on",
      selector: student => {
        const submissionTime = submissionsObj[student.id]?.submitted_on;
        return (submissionTime) ? DateTime.fromISO(submissionTime).toLocaleString(DateTime.DATETIME_FULL) : "";
      },
      sortable: true,
      wrap: true,
      cell: student => {
        const submissionTime = submissionsObj[student.id]?.submitted_on;
        return (submissionTime) ? DateTime.fromISO(submissionTime).toLocaleString(DateTime.DATETIME_MED) : "";
      },
      hide: "md"
    }
  ];

  const courseOptions = useMemo(() => courses.map(course => ({ label: course.name, value: course.id })), []);
  const submissionStatusOptions = useMemo(() => submissionStatuses.map(status => ({ label: status, value: status })), []);

  return (
    <div className="pt-4">
      <div className="row">
        <div className="col-md-4 mb-3">
          <Select
            id="assignments_filter_course"
            onChange={handleFilterCourses}
            options={courseOptions}
            isMulti
            closeMenuOnSelect={false}
            isDisabled={false}
            placeholder="Filter by course"
          />
        </div>

        <div className="col-md-4 mb-3">
          <Select
            id="assignments_filter_submission_status"
            onChange={handleFilterSubmissionStatus}
            options={submissionStatusOptions}
            isMulti
            closeMenuOnSelect={false}
            isDisabled={false}
            placeholder="Filter by submission status"
          />
        </div>

        <div className="col-md-4 mb-3">
          <input type="input" className="form-control" placeholder="Search..." value={filterText} onChange={handleFilterChange} />
        </div>
      </div>

      <div className={classNames(styles['assignments-table'], 'table-responsive')}>
        <DataTable
          columns={tableColumns}
          data={filteredStudents}
        />
      </div>
    </div>
  );
}
