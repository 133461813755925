export interface ReducerActions<TActionType, TPayloadType = any> {
  type: TActionType;
  payload?: TPayloadType;
}

export enum SliderInteraction {
  Init = 'init',
  Next = 'next',
  Previous = 'previous',
  Resize = 'resize',
  Drag = 'drag',
  Update = 'update',
  JumpToIndex = 'jump-to-index'
}

export interface Breakpoints {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
}

function computeViewableResources(breakpoints: Breakpoints, itemCount: number): number {
  let maxItems = 4;

  if (breakpoints.xs)
    maxItems = 1;

  if (breakpoints.sm)
    maxItems = 2;

  if (breakpoints.md || breakpoints.lg)
    maxItems = 3;

  if (itemCount < maxItems)
    maxItems = itemCount;

  return maxItems;
}

export interface ResourcesSliderPayload {
  breakpoints?: Breakpoints;
  offset?: number;
  totalItems?: number;
}

export interface ResourcesSliderState {
  // Current position within the slider
  offset: number;
  // Number of items viewable in slider for the current breakpoint/viewport size
  viewableItems: number;
  // Relative position of slider in the window. Used for an inline translate3d().
  position: number;
  // Total number of items in the slider
  totalItems: number;
}

export const initialSliderState: ResourcesSliderState = {
  offset: 0,
  viewableItems: 4,
  position: 0,
  totalItems: 0,
};

const computeNextPosition = (prevState: ResourcesSliderState) => {
  const { viewableItems, offset, position, totalItems } = prevState;

  const remainingCount = totalItems - offset;

  /**
   * If the remaining items are evening divisible by the items in view, or,
   * we have more items that can be displayed in the view, move the slider a full 100%.
   */
  if (remainingCount % viewableItems === 0 || remainingCount > viewableItems)
    return {
      position: position - 100,
      offset: offset + viewableItems
    };

  /**
   * Where there are less items than we can show in a full slide, 
   * calculate the % movement required to only show those items.
   */
  if (remainingCount < viewableItems)
    return {
      position: position - (remainingCount / viewableItems * 100),
      offset: offset + remainingCount
    };

  /**
   * Default back to the current state as a fallback
   */
  return {
    position: position,
    offset: offset
  };
};

const computePreviousPosition = (prevState: ResourcesSliderState) => {
  const { viewableItems, offset, position } = prevState;

  const remainingCount = offset - viewableItems;

  if (remainingCount % viewableItems === 0 || remainingCount > viewableItems)
    return {
      position: position + 100,
      offset: offset - viewableItems
    };

  if (remainingCount < viewableItems)
    return {
      position: position + (remainingCount / viewableItems * 100),
      offset: offset - remainingCount
    };

  return {
    position: position,
    offset: offset
  };
};

export function ResourcesSliderReducer(
  prevState: ResourcesSliderState,
  action: ReducerActions<SliderInteraction, ResourcesSliderPayload>
): ResourcesSliderState {
  const { type, payload = {} } = action;

  switch (type) {
    case SliderInteraction.Next: {
      return {
        ...prevState,
        ...computeNextPosition(prevState)
      };
    }

    case SliderInteraction.Previous: {
      return {
        ...prevState,
        ...computePreviousPosition(prevState)
      };
    }

    case SliderInteraction.JumpToIndex: {
      return {
        ...prevState,
        offset: payload.offset,
        position: (payload.offset - 1) * -100
      };
    }

    case SliderInteraction.Resize: {
      const initialSeen = computeViewableResources(payload.breakpoints, prevState.totalItems);

      return {
        ...prevState,
        position: 0,
        offset: initialSeen,
        viewableItems: initialSeen
      };
    }

    case SliderInteraction.Init: {
      const initialSeen = computeViewableResources(payload.breakpoints, payload.totalItems);

      return lazySliderInit({
        ...prevState,
        offset: initialSeen,
        viewableItems: initialSeen,
        totalItems: payload.totalItems,
        position: 0
      });
    }

    case SliderInteraction.Update: {
      return {
        ...prevState,
        totalItems: payload.totalItems
      };
    }

    default:
      return prevState;
  }
}

export function lazySliderInit(newState: ResourcesSliderState): ResourcesSliderState {
  return { ...initialSliderState, ...newState };
}