import React from "react";
import ReactDOM from "react-dom";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

import { useHover } from "../../../lib/hooks/use_hover";
import { useBreakPoints } from "../../../lib/hooks/use_break_points";
import { useSliderKeyboard } from "../../../lib/hooks/use_slider_keyboard";
import { ResourceObj, SliderResourceItem } from "../../resources/slider_resource_item";
import { useTabbingListener } from "../../../lib/hooks/use_tabbing_listener";

import chervonLeftIcon from "../images/chevron_left.svg"
import chervonRightIcon from "../images/chevron_right.svg"

import { ResourcesSliderReducer, initialSliderState, SliderInteraction } from "./resources_slider_reducer";
import { hideBackButton, hideNextButton } from "./resources_slider_utils";

import styles from './resources_slider.module.scss'
import { ClickViewVideo, SliderClickviewItem } from "../../resources/slider_clickview_item";

type ResourceItem = ResourceObj | ClickViewVideo

interface ResourcesSliderProps {
  resources: ResourceItem[];
  show_clip_length: boolean,
  show_only_first_tag: boolean,
  show_age_range_tooltip: boolean,
  ch_tracking_attributes?: Record<string, string>,
}

const getSliderPositionStyling = (xPosition: number) => {
  return {
    transform: `translate3d(${xPosition}%, 0px, 0px)`,
    // Sync with transition time in use_slider_keyboard.ts
    transition: 'transform .4s ease-in-out'
  };
};

function getButtonPositionStyle(viewableItems: number) {
  return { paddingBottom: (56.25 / viewableItems) + '%' }
}

export function ResourcesSlider({
  resources,
  ch_tracking_attributes,
  ...restProps
}: ResourcesSliderProps) {
  useTabbingListener()
  const breakpoints = useBreakPoints()

  const containerRef = React.useRef(null);

  const [state, dispatch] = React.useReducer(ResourcesSliderReducer, initialSliderState);
  const { onFocusSlider, onBlurSlider, isTabbing, getSliderAttributes, focusNext, focusPrev } = useSliderKeyboard({ state, containerRef });
  const [buttonsRef, isHovering] = useHover<HTMLDivElement>();

  const shouldHideBack = hideBackButton(isHovering, state.offset, state.viewableItems, resources.length, isTabbing)
  const shouldHideNext = hideNextButton(isHovering, state.offset, state.viewableItems, resources.length, isTabbing)

  const hideBackGradient = state.offset === state.viewableItems;
  const hideNextGradient = resources.length <= state.offset;

  React.useEffect(() => {
    if (!resources.length)
      return;
    dispatch({
      type: SliderInteraction.Init,
      payload: {
        breakpoints,
        totalItems: resources.length
      }
    });
  }, [resources.length]);

  React.useEffect(() => {
    dispatch({
      type: SliderInteraction.Resize,
      payload: { breakpoints }
    });
  }, [breakpoints]);

  function onClickButton(direction: 'left' | 'right') {
    if (direction === 'right') {
      focusNext();
    } else {
      focusPrev();
    }
    dispatch({ type: direction === 'right' ? SliderInteraction.Next : SliderInteraction.Previous });
  }

  return (
    <div className="d-flex position-relative w-100" ref={buttonsRef}>
      {!shouldHideBack && (
        <div
          className={classNames(styles.buttonContainer, "position-absolute")}
          style={getButtonPositionStyle(state.viewableItems)}
        >
          <button
            type="button"
            className={classNames(styles.left, styles.button)}
            onClick={() => onClickButton('left')}
          >
            <ReactSVG src={chervonLeftIcon} wrapper="span" />
          </button>
        </div>
      )}
      {!hideBackGradient && <div className={styles.leftOverflowGradient}></div>}

      <ul
        className='list-unstyled d-flex flex-nowrap position-relative w-100 mb-0'
        style={getSliderPositionStyling(state.position)}
        ref={containerRef}
      >
        {resources?.map((resource, index) => {
          const isClickViewVideo = !!resource.is_clickview

          const resourceObj = { ...resource, ...restProps }
          const trackingAttributes = {
            ...ch_tracking_attributes,
            "data-ch-event-model-id": resource.id,
            "data-ch-event-model-type": 'Resource'
          }
          return (
            <li
              key={index}
              className={classNames(styles.sliderItemWrapper, 'me-3 w-100')}
              onFocus={() => onFocusSlider(index)}
              onBlur={onBlurSlider}
              {...getSliderAttributes(index)}
            >
              <div className={classNames(styles.sliderItem, "flex-shrink-0 px-1")}>
                {!isClickViewVideo ? (
                  <SliderResourceItem
                    resource={resourceObj as ResourceObj}
                    smallAgeRange
                    showClipLength
                    showAgeRangeTooltip
                    chTrackingAttributes={trackingAttributes}
                  />
                ) : (
                  <SliderClickviewItem resource={resourceObj as ClickViewVideo} />
                )}
              </div>
            </li>
          )
        })}
      </ul>

      {!shouldHideNext && (
        <div
          className={classNames(styles.buttonContainer, "position-absolute")}
          style={getButtonPositionStyle(state.viewableItems)}
        >
          <button
            type="button"
            className={classNames(styles.right, styles.button)}
            onClick={() => onClickButton('right')}
          >
            <ReactSVG src={chervonRightIcon} wrapper="span" />
          </button>
        </div>
      )}
      {!hideNextGradient && <div className={styles.rightOverflowGradient}></div>}
    </div >
  );
}

export function renderResourcesSlider(elementId, options) {
  const node = ReactDOM.render(
    <ResourcesSlider
      {...options}
    />,
    document.getElementById(elementId)
  );

  return node;
}