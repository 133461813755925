import React, { createContext, PropsWithChildren, useContext } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./signin_payment_gate_container.module.scss";

const SigninPaymentGateContainerContext = createContext({
  bgColorClass: '',
  showCloseButton: false,
  onClose() { }
});

interface ContainerProps {
  showCloseButton?: boolean;
  containerClass?: string;
  onClose?: () => void;
  className?: string;
}

interface SideProps {
  className?: string;
}

const SigninPaymentGateContainer = ({ children, showCloseButton = false, ...props }: PropsWithChildren<ContainerProps>) => {
  const theContext = {
    bgColorClass: 'chBlue',
    showCloseButton: showCloseButton,
    onClose() { props.onClose() }
  };

  return (
    <SigninPaymentGateContainerContext.Provider value={theContext}>
      <div className={classNames(props.containerClass, props.className)}>
        <div className="row">
          {children}
        </div>
      </div>
    </SigninPaymentGateContainerContext.Provider>
  );
};

SigninPaymentGateContainer.LeftSide = ({ children, className }: PropsWithChildren<SideProps>) => {
  return (
    <div className={classNames(className, "col-md-5")}>
      {children}
    </div>
  );
};

SigninPaymentGateContainer.RightSide = ({ children, className }: PropsWithChildren<SideProps>) => {
  const { bgColorClass, showCloseButton, onClose } = useContext(SigninPaymentGateContainerContext);

  return (
    <div className="col-md-7">
      <div className={classNames(styles[bgColorClass], className, "d-flex flex-column h-100")}>
        {showCloseButton &&
          <div className="d-flex justify-content-end p-2">
            <button type="button" className={classNames(styles.closeBtn, "btn btn-link")} onClick={onClose}>
              <FontAwesomeIcon icon="times" size="lg" />
              <span className="sr-only">Close</span>
            </button>
          </div>
        }

        <div className="w-100 h-100 mt-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SigninPaymentGateContainer;