import { CLOSE_TYPE, MODE, renderPausePrompt } from "../pause_prompts";

const TRIGGER_TIME_SECONDS = 0.5;
const RECENTLY_FINISHED_RESET_SECONDS = 1.5;

interface PausePrompt {
  id: string;
  auto_resume: boolean;
  pause_duration: number;
  prompt: string;
  recently_finished: boolean;
  start_time: number;
}

export class VideoPausePromptService {
  courseId?: string;
  enabled: boolean;
  isHomeworkMode?: boolean;
  isPaidOrTrialUser: boolean;
  mode: MODE;
  currentPausePrompt: object;
  onPausePromptShow?: (pausePrompt) => void;
  onPausePromptFinish?: () => void;
  onSubmitResponse?: (pausePrompt: PausePrompt, responseText: string, callback: (value: any) => void) => void;
  pausePromptContainerId?: string;
  pausePromptContainer?: Element;
  pausePromptBackdrop?: boolean;
  pausePrompts: Array<PausePrompt>;
  showPausePromptHeader?: boolean;
  preventSkipAhead?: boolean;
  playerAvailable: boolean;
  playerRef;

  constructor(options) {
    this.courseId = options.courseId;
    this.enabled = options.enabled || true;
    this.isHomeworkMode = options.isHomeworkMode;
    this.isPaidOrTrialUser = options.isPaidOrTrialUser;
    this.onPausePromptShow = options.onPausePromptShow;
    this.onPausePromptFinish = options.onPausePromptFinish;
    this.onSubmitResponse = options.onSubmitResponse;
    this.pausePromptContainerId = options.pausePromptContainerId;
    this.pausePrompts = options.pausePrompts || [];
    this.pausePromptBackdrop = options.pausePromptBackdrop;
    this.mode = options.mode || MODE.PRESENT;
    this.showPausePromptHeader = options.hasOwnProperty("showPausePromptHeader") ? options.showPausePromptHeader : true;
    this.preventSkipAhead = options.preventSkipAhead;

    this.playerAvailable = false;
  }

  onPlayerAvailable() {
    this.playerAvailable = true;

    if (this.preventSkipAhead) {
      this.playerRef.current.setPreventSkip();
    }
  }

  onTimeUpdate(time) {
    if (!this.playerRef || !this.enabled) return;
    if (this.currentPausePrompt) return;

    if (!this.playerAvailable) {
      this.onPlayerAvailable();
    }

    let prompt = null;

    for (const pausePrompt of this.pausePrompts) {
      const endTriggerTime = (pausePrompt.start_time + TRIGGER_TIME_SECONDS);

      if (time >= pausePrompt.start_time && time < endTriggerTime) {
        if (pausePrompt.recently_finished != true) {
          prompt = pausePrompt;
          break;
        }
      }
      else if (pausePrompt.recently_finished === true) {
        // After enough delay, set the pause prompt to not be recently finished
        // This prevents them from appearing multiple times
        const timeSinceStart = Math.abs(time - pausePrompt.start_time);
        const timeSinceEnd = Math.abs(time - endTriggerTime);

        if (timeSinceStart >= RECENTLY_FINISHED_RESET_SECONDS || timeSinceEnd >= RECENTLY_FINISHED_RESET_SECONDS) {
          pausePrompt['recently_finished'] = false;
        }
      }
    }

    if (!prompt) return;

    this.playerRef.current.pause();
    this.currentPausePrompt = renderPausePrompt({
      id: prompt.id,
      backdrop: this.pausePromptBackdrop,
      container: this.pausePromptContainer,
      hasTimer: prompt.auto_resume,
      content: prompt.prompt,
      addMinuteButtonEnabled: this.isPaidOrTrialUser,
      mode: this.mode,
      showHeader: this.showPausePromptHeader,
      showCloseButton: !!!this.preventSkipAhead,
      secondsDuration: prompt.pause_duration,
      onClose: (closeType: CLOSE_TYPE) => {
        // Allow the PausePrompt to be shown again but not immediately after
        prompt['recently_finished'] = true;
        this.currentPausePrompt = null;

        this.onPausePromptFinish?.();

        if (closeType === CLOSE_TYPE.TIMER) {
          this.playerRef.current.play();
        }
      },
      onSubmitResponse: (responseText, callback) => {
        if (this.onSubmitResponse) {
          return this.onSubmitResponse(prompt, responseText, callback);
        }

        callback(true);
      }
    });

    this.onPausePromptShow?.(this.currentPausePrompt.current);
  }

  register(playerRef) {
    this.playerRef = playerRef;

    // Use the specified container or the video player's inline container to render pause prompts
    this.pausePromptContainerId = this.pausePromptContainerId || this.playerRef.current.pausePromptContainerId;
    this.pausePromptContainer = document.getElementById(this.pausePromptContainerId);
  }

  toggleEnabled(enabled) {
    this.enabled = enabled;
  }
}
