import React, { useState, useContext } from "react";
import Select from "react-select";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { postData } from "../../global_functions";
import ClipSearchResults from "./clip_search_results";
import UpgradeButton from "../../shared_components/upgrade_button";
import DiscussionContext from "../discussion_context";

interface SelectParams {
  data: Array<[string, number]>;
  value: Array<T>;
  onChange: () => void;
  isDisabled?: boolean;
}

function MultiSelect({ data, value, onChange, isDisabled = false }: SelectParams) {
  const options = data.map(each => ({ value: each[1], label: each[0] }));

  return (
    <Select
      defaultValue={value}
      onChange={onChange}
      options={options}
      isMulti
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
    />
  )
}

interface SearchObject {
  is_paid_or_trial_user: boolean;
  all_clip_lengths: Array<Array<string, number>>;
  age_ranges: Array<Array<string, number>>;
  decade_options: Array<Array<string, number>>;
}

interface Props {
  search: SearchObject;
}

export default function Search({ search }: Props) {
  const [gradeLevels, setGradeLevels] = useState([]);
  const [clipLengths, setClipLengths] = useState([]);
  const [decades, setDecades] = useState([]);
  const [noProfanity, setProfanity] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [searchResults, setSearchResults] = useState();

  const { discussionRoomId } = useContext(DiscussionContext);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // If the user sets filters then removes them, these values can be null
    const theGradeLevels = gradeLevels || [];
    const theClipLengths = clipLengths || [];
    const theDecades = decades || [];

    let data = {
      q: searchInput,
      filters: {
        age_range: theGradeLevels.map(gradeLevel => gradeLevel.value),
        clip_length: theClipLengths.map(clipLength => clipLength.value),
        decade: theDecades.map(decade => decade.value),
      },
      discussion_room_id: discussionRoomId
    };

    if (noProfanity) {
      data['filters']['no_profanity'] = Number(noProfanity);
    }

    const json = await postData({ url: "/search/add_discussion_queue_video", data });
    setSearchResults(json.data);
  };

  return (
    <div className="p-3">
      <Form onSubmit={handleSubmit}>
        <div id="search-filters" className="search-filters">
          <div className="row g-2">
            <Form.Group className="col-md-3" >
              <Form.Label>Grade Level</Form.Label>
              <MultiSelect
                data={search.age_ranges}
                value={gradeLevels}
                onChange={setGradeLevels}
              />
            </Form.Group>

            <Form.Group className="col-md-3">
              <Form.Label>Clip Length</Form.Label>
              <MultiSelect
                data={search.all_clip_lengths}
                value={clipLengths}
                onChange={setClipLengths}
              />
            </Form.Group>

            {/*-- Decade Filter -- */
              <Form.Group className="col-md-3">
                <Form.Label>
                  Decade
                  {!search.is_paid_or_trial_user &&
                    <>
                      {' '}
                      <UpgradeButton buttonText="UPGRADE" />
                    </>
                  }
                </Form.Label>

                <MultiSelect
                  data={search.decade_options}
                  value={decades}
                  onChange={setDecades}
                  isDisabled={!search.is_paid_or_trial_user}
                />
              </Form.Group>
            }
            {
              <Form.Group className="col-md-3">
                <Form.Label>No Profanity</Form.Label>
                <br />
                <div className="d-flex">
                  <Form.Check onChange={() => setProfanity(!noProfanity)} />
                </div>
              </Form.Group>
            }
          </div>

          <div className="row mt-2">
            <Form.Group className="col-md-10">
              <Form.Control placeholder="Search" onChange={(e) => setSearchInput(e.target.value)} />
            </Form.Group>

            <Form.Group className="col-md-2">
              <Button type="submit" variant="dark">Search</Button>
            </Form.Group>
          </div>
        </div>
      </Form>

      <hr />

      <ClipSearchResults
        queueableType={search.queueable_type}
        searchResults={searchResults}
      />
    </div>
  )
}

