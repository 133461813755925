import React from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import { AgeRange, Resource } from '../../types';

import AddToPlayListIcon from '../shared_components/images/add_to_playlist.svg'

import { Tag } from './display_tag';
import DisplayAgeRange from './display_age_range';
import { ResourceThumbnail } from './resource_thumbnail';
import { ResourceTags } from './resource_tags';

import styles from './slider_resource_item.module.scss'

export interface ResourceObj extends Resource {
  series?: { name: string };
  age_ranges?: AgeRange[];
  subjects?: { name: string }[];
  subject_tags?: Tag[];
  profanity_moments?: { approved: boolean }[];
  slug: string;
  is_clickview?: boolean;
}

interface SliderResourceItemProps {
  resource: ResourceObj;

  smallAgeRange?: boolean;
  showClipLength?: boolean;
  showOnlyFirstTag?: boolean;
  showQuickActions?: boolean;
  chTrackingAttributes?: Record<string, any>;
  otherLinkAttrs?: Record<string, any>;
  showAgeRangeTooltip?: boolean;
  queryParams?: Record<string, any>;
}

export function SliderResourceItem(props: SliderResourceItemProps) {
  const {
    smallAgeRange,
    showClipLength,
    showOnlyFirstTag,
    showQuickActions,
    chTrackingAttributes = {},
    otherLinkAttrs = {},
    showAgeRangeTooltip,
    resource
  } = props
  
  const theResourceUrl = `/resources/${resource.id}-${resource.slug}`;

  return (
    <div className={classNames(styles.resourceContainer, 'd-flex flex-column')}>
      <div className={styles.resourceThumbnailContainer}>
        <a
          href={theResourceUrl}
          {...otherLinkAttrs}
          {...chTrackingAttributes}
          className="position-absolute w-100 h-100 top-0 start-0"
        >
          <ResourceThumbnail
            resource={resource}
            showClipLength={showClipLength}
          />
        </a>
      </div>

      <div className="d-flex flex-column mb-1">
        {resource.series?.name && (
          <div className="resource-series-title">{resource.series.name}</div>
        )}
        <div className="resource-title">
          <a
            href={theResourceUrl}
            {...otherLinkAttrs}
            {...chTrackingAttributes}
            title={resource.title}
            aria-label={resource.slug}
            className="resource-title-link"
          >
            {resource.title}
          </a>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-between">
        <div>
          <DisplayAgeRange
            ageRanges={resource['age_ranges']}
            size={smallAgeRange ? 'sm' : 'lg'}
            showTooltip={showAgeRangeTooltip}
          />
        </div>

        {showQuickActions && (
          <div>
            <button
              type="button"
              className="btn btn-link text-muted p-0 cursor-pointer"
              resource-id={resource.id}
              resource-title={resource.title}
              data-bs-toggle="modal"
              data-bs-target="#add-resource-to-playlist-modal"
              ch-event-name="click"
              ch-event-model-id={resource.id}
              ch-event-model-type="Resource"
              ch-event-title={resource.title}
              ch-event-link="add_playlist_button"
            >
              <ReactSVG src={AddToPlayListIcon} wrapper="span" />
              <span className="sr-only">Add to Playlist</span>
            </button>
          </div>
        )}
      </div>

      <div className="resource-tags mt-2">
        <ResourceTags
          resource={resource}
          showOnlyFirstTag={showOnlyFirstTag}
        />
      </div>
    </div>
  );
};