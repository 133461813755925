import { unmountComponentAtNode } from "react-dom";
import { renderVideoPlayer } from "./video_player";

export function youtubeEmbedOptions(resource, useEduPlayer) {
  let options = {};

  // The EDU player is restricted to certain domains, so only run it on production environments
  const isProduction = (process.env.NODE_ENV === "production");

  if (useEduPlayer && isProduction) {
    options['eduPlayer'] = true;

    // An API key is required
    options['embedConfig'] = { 'apiKey': process.env.YOUTUBE_DATA_API_KEY };
  } else {
    options['noCookie'] = true;
  }

  if (resource.startTime > 0) {
    options['start'] = resource.startTime;
  }

  if (!resource.entireVideo) {
    options['end'] = resource.endTime;
  }

  return options;
}

export function vimeoEmbedOptions() {
  const options = {
    dnt: true
  };

  return options;
}

export function dailymotionEmbedOptions(resource) {
  const options = {
    'endscreen-enable': false,
    'queue-autoplay-next': false,
    'queue-enable': false
  };

  if (resource.startTime > 0) {
    options['start'] = resource.startTime;
  }

  return options;
}

// Removes unnecessary attributes, and updates embed options
export function processEmbedCode(embedCode, videoHostName) {
  // Create a copy of the iframe and remove certain attributes before generating the embed code
  // Source for help: https://www.w3docs.com/snippets/javascript/how-to-create-a-new-dom-element-from-html-string.html
  // Unfortunately, template elements are not supported in IE 11, so use a div instead
  const cloneDiv = document.createElement('div');
  cloneDiv.innerHTML = embedCode;

  const iframe = cloneDiv.children[0];
  iframe.removeAttribute("id");

  if (videoHostName === "dailymotion") {
    const srcUrl = new URL(iframe.src);

    // Enable the UI controls, and show an info screen before the video is played
    srcUrl.searchParams.set('controls', 'true');
    srcUrl.searchParams.set('ui-start-screen-info', 'true');

    iframe.src = srcUrl.toString();
  }

  return iframe.outerHTML;
}

export function markersFromPausePrompts(pausePrompts) {
  return pausePrompts.map(pausePrompt => {
    return { time: pausePrompt.start_time, pausePromptId: pausePrompt.id };
  });
}

export function getCurrentVideoPlayer(index: number = 0) {
  // @ts-ignore
  return window.ytPlayers && window.ytPlayers[index];
}

export function getCurrentPausePromptService(index: number = 0) {
  // @ts-ignore
  return window.pausePromptServices && window.pausePromptServices[index];
}

// Replaces the YouTube EDU player with the regular YouTube player
export function replaceYoutubeEduPlayer(videoPlayerElementId, videoPlayerOptions) {
  const videoPlayerContainer = document.getElementById(videoPlayerElementId);

	unmountComponentAtNode(videoPlayerContainer);

	// Clear references to the YouTube SDK so Plyr can reload it for regular YouTube (not the EDU version)
	// This allows the onReady event to fire and load the Plyr UI
	// The onReady event isn't compatible between the regular and EDU YouTube Players since it's not fired
	// when videos are loaded from a different host
	// Source: https://github.com/ClassHook/plyr/blob/master/src/js/plugins/youtube.js#L60
	window.YT = null;

	const newVideoPlayerOptions = { ...videoPlayerOptions, elementId: videoPlayerElementId, useEduPlayer: false };

  // Remove the onEduPlayerUnavailable if it's specified since it already calls this method
  delete newVideoPlayerOptions['onEduPlayerUnavailable'];

	const thePlayerRef = renderVideoPlayer(newVideoPlayerOptions);

	return thePlayerRef;
}