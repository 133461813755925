import React from 'react';

export const useIsComponentMounted = () => {
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => { isMounted.current = false; };
  }, []);

  return isMounted;
};