import React, { useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";
import { Quote } from "../../types";

const DEFAULT_BODY = `
Hi,

I've been researching ClassHook and have customized a quote. Here is a quote for ClassHook, based on the ClassHook Premium plan:

https://www.classhook.com/quotes/{quoteId}

We can start our 12-month subscription whenever we want, and we can pay with a check, credit card, or purchase order if we decide to purchase ClassHook.

Here are some resources and information on ClassHook Premium: https://www.classhook.com/pricing

Any questions can be answered at hello@classhook.com.

Thanks for joining me in exploring a ClassHook license for {organization}.
`;

interface Props {
  quote: Quote;
  onCancel: () => void;
}

function defaultBodyStr(quote: Quote) {
  const theBody = DEFAULT_BODY.replace("{quoteId}", `${quote.id}`).replace("{organization}", quote.organization).trim();
  return theBody;
}

function ShareQuoteForm({ quote, onCancel }: Props) {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [recipientEmail, setRecipientEmail] = useState('');
  const [senderName, setSenderName] = useState(`${quote.first_name} ${quote.last_name}`);
  const [subject, setSubject] = useState(`ClassHook Quote for ${quote.organization}`);
  const [body, setBody] = useState(defaultBodyStr(quote));

  const onShare = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data;

    try {
      const response = await postData({
        url: `/quotes/${quote.id}/share`,
        data: {
          recipient_email: recipientEmail,
          sender_name: senderName,
          subject: subject,
          body: body
        }
      });

      data = response.data;
    } catch (e) {
      data = e.response.data;
    }

    if (data.errors) {
      setErrors(data.errors);
      setSuccessMessage('');
    } else {
      setErrors([]);
      setSuccessMessage(data.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <FormErrorsList errors={errors} />

      {successMessage &&
        <div className="alert alert-success mb-3">{successMessage}</div>
      }

      <Form onSubmit={onShare}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="quote_share_recipient_email">Recipient Email</Form.Label>
            <Form.Control id="quote_share_recipient_email" type="text" onChange={e => setRecipientEmail(e.target.value)} />
          </div>

          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="quote_share_sender_name">Sender Name</Form.Label>
            <Form.Control id="quote_share_sender_name" type="text" defaultValue={senderName} onChange={e => setSenderName(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="quote_share_subject">Subject</Form.Label>
            <Form.Control id="quote_share_subject" type="text" defaultValue={subject} onChange={e => setSubject(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="quote_share_body">Body</Form.Label>
            <Form.Control id="quote_share_body" as="textarea" rows={6} defaultValue={body} onChange={e => setBody(e.target.value)} />
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <Button type="button" variant="outline-dark" onClick={() => onCancel()}>
              Cancel
            </Button>
          </div>

          <div>
            <Button type="submit" variant="dark" disabled={isLoading}>
              <FontAwesomeIcon icon="paper-plane" />
              &nbsp;
              <span className="me-2">Send</span>
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export function renderShareQuoteForm(elementId, options) {
  const node = ReactDOM.render(
    <ShareQuoteForm
      quote={options.quote}
      onCancel={options.onCancel}
    />,
    document.getElementById(elementId)
  );

  return node;
}