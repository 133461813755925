import React from "react";
import {
  Table,
  Button
} from "react-bootstrap";
import AddClipButton from "./add_clip_button";

interface PersonalClipObject {
  user_clip: object;
  url: string;
}

interface Props {
  personalClips: Array<PersonalClipObject>;
  queueableType: string;
}

function PersonalClips({ personalClips, queueableType }: Props): React.ReactNode {
  return (
    <Table striped size="sm" className="tab-clip-list mb-0" responsive>
      <thead>
        <tr>
          <th></th>
          <th>Clip</th>
        </tr>
      </thead>

      <tbody>
        { personalClips.map((personalClip,index) =>(
          <tr key={index}>
            <td>
              <AddClipButton
                queueableType={queueableType}
                video={personalClip.user_clip}
              />
            </td>

            <td>
              <a href={personalClip.user_clip.url} target="_blank" rel="noopener noreferrer">{personalClip.user_clip.title}</a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default PersonalClips;
