import React from "react";
import Button from "react-bootstrap/Button";
import { ReactSVG } from "react-svg";

import plusIcon from "../shared_components/images/plus_icon.svg";

interface Props {
  canUserAddPrompt: boolean;
  btnText?: string;
  extraClasses?: string;
  showIcon?: boolean;
  onClick?: () => void;
}

export default function AddPromptButton({ canUserAddPrompt, btnText, extraClasses, showIcon = false, onClick }: Props) {
  const theBtnText = btnText || "Add a Prompt";

  const onClickHandler = () => {
    if (!canUserAddPrompt) return null;
    onClick?.();
  };

  return (
    <Button variant="outline-dark" className={extraClasses} onClick={onClickHandler} disabled={!canUserAddPrompt}>
      {showIcon &&
        <>
          <ReactSVG src={plusIcon} wrapper="span" />
          {' '}
        </>
      }

      {theBtnText}
    </Button>
  );
}