import React from 'react'
import { Dropdown } from 'react-bootstrap';
import classnames from 'classnames';

import { MAX_DISPLAY_OPTIONS, SearchFilterOption } from '../search_filters';
import styles from '../search_filters.module.scss';

interface DropdownFilterItemProps {
  label: string;
  multiSelectOptions: Array<SearchFilterOption>;
  filterName: string;
  onFilterSelected: (filterName: string, value: string | number | Array<SearchFilterOption>) => void;
  onFilterModalShown?: (filterName: string, title: string, filterOptions: Array<SearchFilterOption>) => void;
  disabled?: boolean;
}

export function DropdownMultiSelectFilterItem(props: DropdownFilterItemProps) {
  const showInModal = props.multiSelectOptions.length > MAX_DISPLAY_OPTIONS;
  const [showDropdown, setShowDropdown] = React.useState(false);
  const isSelected = props.multiSelectOptions.find(option => option.checked)
  const selectedCount = props.multiSelectOptions.filter(option => option.checked).length
  function onToggleDropdown(isShowing: boolean): void {
    setShowDropdown(isShowing);

    if (isShowing)
      return;
  }

  function onFilterSelected(value) {
    const newSelectOptions = props.multiSelectOptions.map(option =>
      option.value === value ? { ...option, checked: !option.checked } : option
    );

    props.onFilterSelected(props.filterName, newSelectOptions)
  }
  function onFilterModalShown() {
    props.onFilterModalShown(props.filterName, props.label, props.multiSelectOptions)
  }

  return (
    <Dropdown onToggle={showInModal ? onFilterModalShown : onToggleDropdown} show={showDropdown}>
      <Dropdown.Toggle variant={isSelected ? 'dark' : 'outline-dark'} disabled={props.disabled}>
        {props.label} {selectedCount ? `- ${selectedCount}` : ''}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.multiSelectOptions.map((option: SearchFilterOption) => (
          <Dropdown.Item
            key={option.value}
            onClick={() => {
              onToggleDropdown(false)
              onFilterSelected(option.value)
            }}
            className={styles.dropdownItem}
          >
            <div className={classnames('form-check', styles.customCheckbox)}>
              <input type="checkbox" className="form-check-input cursor-pointer" checked={option.checked} />
              {option.label}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}