export enum MODE {
  /**
   * Subtitles are enabled by a toggle on the webpage
   */
  TOGGLE = "TOGGLE",
  /**
   * Subtitles are always enabled
   */
  ENABLED = "ENABLED",
  /**
   * Subtitles are always disabled
   */
  DISABLED = "DISABLED",
}

export class VideoSubtitlesService {
  mode: MODE;
  censoredToggleElementId: string;
  censoredToggleElement;
  toggleElementId: string;
  toggleElement;
  subtitlesDiv: Element;
  subtitles;
  onSubtitlesUpdate?: (subtitlesStr: string) => void;

  constructor(options) {
    this.censoredToggleElementId = options.censoredToggleElementId;
    this.toggleElementId = options.toggleElementId;
    this.subtitles = options.subtitles;
    this.mode = options.mode || MODE.TOGGLE;

    this.censoredToggleElement = document.getElementById(this.censoredToggleElementId);
    this.toggleElement = document.getElementById(this.toggleElementId);
    this.onSubtitlesUpdate = options.onSubtitlesUpdate;
  }

  get subtitlesEnabled() {
    if (this.mode === MODE.ENABLED) {
      return true;
    } else if (this.mode === MODE.DISABLED) {
      return false;
    }

    return this.toggleElement?.checked;
  }

  get censoringEnabled() {
    return this.censoredToggleElement?.checked;
  }

  onTimeUpdate(time) {
    if (!this.subtitlesEnabled) {
      this.onSubtitlesUpdate?.(null);
      return null;
    }

    if (!this.subtitles || this.subtitles.length < 1) {
      this.onSubtitlesUpdate?.(null);
      return null;
    }

    const subtitlesToUse = this.subtitles.filter(subtitle => {
      return (time >= subtitle.start_time && time <= subtitle.end_time);
    });

    const subtitlePhrases = subtitlesToUse.map(subtitle => {
      return (this.censoringEnabled && subtitle.censored_phrase) || subtitle.phrase;
    });

    const subtitlesStr = (subtitlePhrases.length < 1) ? null : subtitlePhrases.join(" ");
    this.onSubtitlesUpdate?.(subtitlesStr);
  }
}
