import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ChDirectUpload } from "../active_storage_overrides/ch_direct_upload";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./styles.scss";

const SHOW_FILE_STATUSES = ['pending', 'error'];
const ONE_MB_IN_BYTES = 1048576;

interface UploadProps {
  accept?: string;
  blob?: { signed_id: string };
  elementClass: string;
  elementId: string;
  elementName: string;
  fileName?: string;
  label: string;
  serviceName?: string;
  sizeLimitInMB?: number;
  uploadUrl: string;
}

function matchesType(fileType, acceptVal) {
  if (!acceptVal) return true;

  const acceptAry = acceptVal.split(",");
  const fileTypeMatch = acceptAry.find(accept => fileType.match(accept));

  return !!fileTypeMatch;
}

class DirectFileUpload extends Component {
  constructor(props: UploadProps) {
    super(props);

    this.state = {
      blob: props.blob,
      displayText: props.label,
      error: null,
      fileName: props.fileName,
      progress: 0,
      status: 'pending'
    };

    this.fileInput = null;

    this.directUploadWillStoreFileWithXHR = this.directUploadWillStoreFileWithXHR.bind(this);
    this.directUploadDidProgress = this.directUploadDidProgress.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  matchesSizeLimit(fileSize: number) {
    const { sizeLimitInMB } = this.props;

    if (!sizeLimitInMB) return true;

    const fileSizeInMB = (fileSize / ONE_MB_IN_BYTES);
    return (fileSizeInMB <= sizeLimitInMB);
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e) {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    this.tryUploadFile(file);
  }

  onChange(e) {
    const file = e.target.files[0];
    this.tryUploadFile(file);
  }

  tryUploadFile(file) {
    if (!file) return false;
    if (this.state.status === 'uploading') return false;

    if (!matchesType(file.type, this.props.accept)) {
      this.setState({ ...this.state, status: "error", error: "Invalid file type." });
      return;
    }

    if (!this.matchesSizeLimit(file.size)) {
      this.setState({ ...this.state, status: "error", error: `File must be less than ${this.props.sizeLimitInMB} MB.` });

      return;
    }

    this.uploadFile(file);
  }

  onButtonClick(e) {
    e.preventDefault();

    if (this.fileInput) {
      this.fileInput.click();
    }
  }

  onRemoveFile(e) {
    e.preventDefault();

    this.setState({
      ...this.state,
      blob: {
        ...this.state.blob,
        signed_id: ''
      },
      fileName: null
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", event => this.directUploadDidProgress(event));
  }

  directUploadDidProgress(e) {
    const progress = Math.floor(parseFloat(e.loaded) / parseFloat(e.total) * 100);

    this.setState({ ...this.state, progress: progress });
  }

  uploadFile(file) {
    const { label, uploadUrl, serviceName } = this.props;
    const upload = new ChDirectUpload(file, uploadUrl, serviceName, this);

    this.setState({
      ...this.state,
      fileName: file.name,
      displayText: "Uploading...",
      progress: 0,
      status: "uploading"
    });

    upload.create((error, blob) => {
      if (error) {
        this.setState({ ...this.state, status: "error", error: error });
      } else {
        this.setState({ ...this.state, blob: blob, displayText: label, progress: 0, status: "pending" });
      }
    });
  }

  renderHiddenInput() {
    const { blob } = this.state;
    const { elementName } = this.props;

    return (
      <input type="hidden" name={elementName} value={blob.signed_id}></input>
    );
  }

  renderFile() {
    const { fileName } = this.state;
    const fileParts = fileName.split(".");

    let fileNameNoExt = fileName;
    let fileExt = "";

    if (fileParts.length > 0) {
      fileNameNoExt = fileParts.splice(0, fileParts.length - 1).join('');
      fileExt = `.${fileParts[fileParts.length - 1]}`;
    }

    // Source for help: https://stackoverflow.com/questions/64569723/smart-truncation-of-filename-in-css
    return (
      <div className="d-flex align-items-center overflow-hidden mt-2">
        <FontAwesomeIcon icon="file" size="lg" className="ch-blue me-2" />
        <span className="direct-upload__filename_name">{fileNameNoExt}</span>
        <span className="direct-upload__filename_extension me-2">{fileExt}</span>

        <button className="btn btn-link text-decoration-none" type="button" onClick={this.onRemoveFile}>
          <FontAwesomeIcon icon="times" size="lg" className="text-red" />
          <span className="sr-only">Remove</span>
        </button>
      </div>
    );
  }

  render() {
    const { blob, displayText, error, fileName, progress, status } = this.state;
    const { accept, elementId, elementName } = this.props;

    return (
      <>
        <div
          className={`position-relative d-flex flex-column border rounded direct-upload--${status}`}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          <label htmlFor={elementId} className="direct-upload__label">
            {displayText}

            {status === 'uploading' &&
              <div className="mt-2">{progress}%</div>
            }

            <input
              accept={accept}
              className="direct-upload__input"
              id={elementId}
              name={elementName}
              onChange={this.onChange}
              ref={input => this.fileInput = input}
              type="file"
            />

            {status !== 'uploading' &&
              <div className="mt-2">
                <button type="button" className="btn btn-dark direct-upload__button" onClick={this.onButtonClick}>
                  Drag/Drop or Select a file
                </button>
              </div>
            }

            {status === 'error' &&
              <div className="mt-2 fw-bold">
                {error}
              </div>
            }
          </label>

          <div className="direct-upload__progress" style={{ width: `${progress}%` }}></div>
        </div>

        { blob && this.renderHiddenInput()}

        { fileName && SHOW_FILE_STATUSES.includes(status) &&
          this.renderFile()
        }
      </>
    );
  }
}

export function renderDirectFileUpload(elementId, options) {
  const node = ReactDOM.render(
    <DirectFileUpload
      accept={options.accept}
      blob={options.blob}
      elementClass={options.elementClass}
      elementId={options.elementId}
      elementName={options.elementName}
      fileName={options.fileName}
      label={options.label}
      serviceName={options.serviceName}
      sizeLimitInMB={options.sizeLimitInMB}
      uploadUrl={options.uploadUrl}
    />,
    document.getElementById(elementId)
  );

  return node;
}
