import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import qs from "qs";
import classNames from "classnames";

import ChModal from "../shared_components/ch_modal";
import UpgradeButton from "../shared_components/upgrade_button";
import { SearchFilterOptions, PREMIUM_FILTER_NAMES } from "./search_filters";
import { AgeRange, Genre, Series, Standard, Subject, Tag } from "../../types";
import { chAnalyticsTrackEvent } from "../analytics";

import styles from "./search_filters_mobile.module.scss";

const MAX_DISPLAY_OPTIONS = 8;

interface SearchFilterOption {
  label: string;
  value: string;
  checked?: boolean;
}

interface SearchFiltersProps {
  includedFilters: Array<SearchFilterOptions>;
  selectedSearchFilterValues: { [key: string]: any };
  isPaidUser: boolean;
  searchRequestPath: string;
  userId?: number;
  ageRanges?: Array<AgeRange>;
  subjects?: Array<Subject>;
  tags?: Array<Tag>;
  clipLengths?: Array<string>;
  allSeries?: Array<Series>;
  mediaTypes?: Array<Array<string>>;
  standards?: Array<Standard>;
  genres?: Array<Genre>;
  decades?: Array<Array<string>>;
  onSubmit?: (searchFilterValues: { [key: string]: any }) => void;
}

interface SearchMultiFilterSectionProps {
  title: string;
  multiSelectOptions: Array<SearchFilterOption>;
  filterName: string;
  onFilterSelected: (filterName: string, value: string | number | Array<SearchFilterOption>) => void;
  disabled?: boolean;
}

export function SearchFilters({ includedFilters, isPaidUser, searchRequestPath, ...props }: SearchFiltersProps) {
  const [modalShown, setModalShown] = useState(false);
  const [selectedSearchFilterValues, setSelectedSearchFilterValues] = useState({ ...props.selectedSearchFilterValues });

  const showClearFiltersBtn = useMemo(() => {
    return JSON.stringify(selectedSearchFilterValues) != JSON.stringify(props.selectedSearchFilterValues);
  }, [selectedSearchFilterValues]);

  let ageRangeOptions = [];
  let subjectsOptions = [];
  let tagsOptions = [];
  let clipLengthOptions = [];
  let seriesOptions = [];
  let mediaTypeOptions = [];
  let standardsOptions = [];
  let genreOptions = [];
  let decadeOptions = [];

  const premiumFiltersIncluded = useMemo(() => {
    return PREMIUM_FILTER_NAMES.some(filterName => includedFilters.includes(filterName));
  }, []);

  if (includedFilters.includes("age_range")) {
    ageRangeOptions = props.ageRanges.map(ageRange => ({ label: ageRange.name, value: ageRange.value.toString(), checked: selectedSearchFilterValues["age_range"] && selectedSearchFilterValues["age_range"].includes(ageRange.value.toString()) }));
  }

  if (includedFilters.includes("subjects")) {
    subjectsOptions = props.subjects.map(subject => ({ label: subject.name, value: subject.id.toString(), checked: selectedSearchFilterValues["subjects"] && selectedSearchFilterValues["subjects"].includes(subject.id.toString()) }));
  }

  if (includedFilters.includes("tags")) {
    tagsOptions = props.tags.map(tag => ({ label: tag.name, value: tag.id.toString(), checked: selectedSearchFilterValues["tags"] && selectedSearchFilterValues["tags"].includes(tag.id.toString()) }));
  }

  if (includedFilters.includes("clip_length")) {
    clipLengthOptions = props.clipLengths.map((clipLength, index) => ({ label: clipLength, value: index.toString(), checked: selectedSearchFilterValues["clip_length"] && selectedSearchFilterValues["clip_length"].includes(index.toString()) }));
  }

  if (includedFilters.includes("series")) {
    seriesOptions = props.allSeries.map(series => ({ label: series.name, value: series.id.toString(), checked: selectedSearchFilterValues["series"] && selectedSearchFilterValues["series"].includes(series.id.toString()) }));
  }

  if (includedFilters.includes("media_type")) {
    mediaTypeOptions = props.mediaTypes.map(mediaTypeAry => ({ label: mediaTypeAry[0], value: mediaTypeAry[1].toString(), checked: selectedSearchFilterValues["media_type"] && selectedSearchFilterValues["media_type"].includes(mediaTypeAry[1].toString()) }));
  }

  if (includedFilters.includes("standards")) {
    standardsOptions = props.standards.map(standard => ({ label: standard.code, value: standard.id.toString(), checked: selectedSearchFilterValues["standards"] && selectedSearchFilterValues["standards"].includes(standard.id.toString()) }));
  }

  if (includedFilters.includes("genre")) {
    genreOptions = props.genres.map(genre => ({ label: genre.name, value: genre.id.toString(), checked: selectedSearchFilterValues["genre"] && selectedSearchFilterValues["genre"].includes(genre.id.toString()) }));
  }

  if (includedFilters.includes("decade")) {
    decadeOptions = props.decades.map(decadeAry => ({ label: decadeAry[0], value: decadeAry[1].toString(), checked: selectedSearchFilterValues["decade"] && selectedSearchFilterValues["decade"].includes(decadeAry[1].toString()) }));
  }

  const reloadPageWithFilters = (updatedSelectedSearchFilterValues) => {
    const url = new URL(window.location.href);
    const params = {
      q: url.searchParams.get("q"),
      filters: updatedSelectedSearchFilterValues
    };

    url.search = qs.stringify(params, { arrayFormat: "brackets" });

    window.location.href = url.toString();
  };

  const onFilterSelected = (filterName: string, value: string | number | Array<SearchFilterOption>) => {
    const newSelectedSearchFilterValues = { ...selectedSearchFilterValues };

    if (value === null) {
      delete newSelectedSearchFilterValues[filterName];
    } else if (typeof (value) === "string" || typeof (value) === "number") {
      newSelectedSearchFilterValues[filterName] = value;
    } else {
      newSelectedSearchFilterValues[filterName] = value.filter(option => option.checked).map(option => option.value);

      if (!newSelectedSearchFilterValues[filterName] || newSelectedSearchFilterValues[filterName].length < 1) {
        delete newSelectedSearchFilterValues[filterName];
      }
    }

    setSelectedSearchFilterValues(newSelectedSearchFilterValues);
  };

  const onClearFilters = () => {
    setSelectedSearchFilterValues(props.selectedSearchFilterValues);
  };

  const onModalClose = () => {
    onClearFilters();
    setModalShown(false);
  };

  const onApplyFilters = () => {
    if (props.onSubmit) {
      setModalShown(false);
      props.onSubmit?.(selectedSearchFilterValues);
      return;
    }

    reloadPageWithFilters(selectedSearchFilterValues);
  };

  const onUpgradeClicked = (link: string) => {
    chAnalyticsTrackEvent("click", {
      user_id: props.userId,
      label: "search_filters_mobile",
      link: link
    });
  };

  const multiFilterProps = { onFilterSelected };

  return (
    <>
      <Button variant="outline-dark" onClick={() => setModalShown(true)}>
        Filters
      </Button>

      <ChModal shown={modalShown} onClose={onModalClose}>
        <ChModal.Header closeButton></ChModal.Header>

        <ChModal.Body>
          {/* Single item filters */}
          <div className="mb-3">
            {includedFilters.includes("no_profanity") && renderSearchToggleFilterSection("No Profanity", "1", "no_profanity", onFilterSelected, (selectedSearchFilterValues["no_profanity"] === "1"))}
          </div>

          {/* Multi item filters */}
          {includedFilters.includes("age_range") && <SearchMultiFilterSection {...multiFilterProps} title="Grade Level" multiSelectOptions={ageRangeOptions} filterName={"age_range"} />}
          {includedFilters.includes("subjects") && <SearchMultiFilterSection {...multiFilterProps} title="Subject" multiSelectOptions={subjectsOptions} filterName="subjects" />}
          {includedFilters.includes("tags") && <SearchMultiFilterSection {...multiFilterProps} title="Topic" multiSelectOptions={tagsOptions} filterName="tags" />}
          {includedFilters.includes("clip_length") && <SearchMultiFilterSection {...multiFilterProps} title="Clip Length" multiSelectOptions={clipLengthOptions} filterName="clip_length" />}
          {includedFilters.includes("series") && <SearchMultiFilterSection {...multiFilterProps} title="Series" multiSelectOptions={seriesOptions} filterName="series" />}
          {includedFilters.includes("media_type") && <SearchMultiFilterSection {...multiFilterProps} title="Media Type" multiSelectOptions={mediaTypeOptions} filterName="media_type" />}

          {/* Premium */}
          {premiumFiltersIncluded &&
            <div className={!isPaidUser ? "border rounded" : ""}>
              {!isPaidUser &&
                <div className={classNames(styles.premiumHeading, "rounded", "p-2")}>
                  <UpgradeButton
                    extraBtnClasses={classNames(styles.premiumUpgradeButton, "me-1")}
                    onClick={() => onUpgradeClicked("upgrade_button")}
                  />
                  {' '}
                  <span className="small me-2">Premium Filters</span>
                  {' '}
                  <a
                    href="/pricing"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={classNames(styles.premiumLearnMore, "small")}
                    onClick={() => onUpgradeClicked("learn_more_link")}
                  >
                    Learn More
                  </a>
                </div>
              }

              <div className="my-3">
                {includedFilters.includes("most_popular") && renderSearchToggleFilterSection("Most Popular", "1", "most_popular", onFilterSelected, (selectedSearchFilterValues["most_popular"] === "1"), !isPaidUser)}
              </div>

              {includedFilters.includes("standards") && <SearchMultiFilterSection {...multiFilterProps} title="Standards" multiSelectOptions={standardsOptions} filterName="standards" disabled={!isPaidUser} />}
              {includedFilters.includes("genre") && <SearchMultiFilterSection {...multiFilterProps} title="Genre" multiSelectOptions={genreOptions} filterName="genre" disabled={!isPaidUser} />}
              {includedFilters.includes("decade") && <SearchMultiFilterSection {...multiFilterProps} title="Decade" multiSelectOptions={decadeOptions} filterName="decade" disabled={!isPaidUser} />}
            </div>
          }
        </ChModal.Body>

        <ChModal.Footer>
          {showClearFiltersBtn &&
            <Button type="button" variant="outline-dark" className="px-3" aria-label="Clear" onClick={onClearFilters}>
              Clear
            </Button>
          }
          <div className="flex-grow-1"></div>

          <Button type="button" variant="dark" className="px-3" aria-label="Apply" onClick={onApplyFilters}>
            Apply
          </Button>
        </ChModal.Footer>
      </ChModal>
    </>
  );
}

function renderSearchToggleFilterSection(label: string, value: string, filterName: string, onFilterSelected, selected: boolean = false, disabled: boolean = false) {
  const elementId = `search_filter_${htmlAttrSafeName(label)}`;

  const onChange = e => {
    onFilterSelected(filterName, e.target.checked ? value : null);
  };

  return (
    <div className="px-3">
      <Form.Switch
        id={elementId}
        type="switch"
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={selected}
      />
    </div>
  );
}

function SearchMultiFilterSection({ title, multiSelectOptions, filterName, onFilterSelected, disabled = false }: SearchMultiFilterSectionProps) {
  const [shouldShowMore, setShouldShowMore] = useState((multiSelectOptions.length > MAX_DISPLAY_OPTIONS));

  const baseElementId = `search_filter_${htmlAttrSafeName(title)}`;
  const numOptionsToRender = shouldShowMore ? MAX_DISPLAY_OPTIONS : multiSelectOptions.length;

  const onChange = (e, value) => {
    const newSelectOptions = [...multiSelectOptions];

    const changedOptionIndex = newSelectOptions.findIndex(option => option.value === value);
    const changedOption = newSelectOptions[changedOptionIndex];

    changedOption.checked = !changedOption.checked;

    newSelectOptions.splice(changedOptionIndex, 1, changedOption);

    onFilterSelected(filterName, newSelectOptions);
  };

  return (
    <div className="mb-3 px-3">
      <h6 className="fw-bold mb-3">{title}</h6>

      <div>
        {multiSelectOptions.length < 1 && <div>None available</div>}

        {multiSelectOptions.slice(0, numOptionsToRender).map(option =>
          <button
            key={`${baseElementId}_${htmlAttrSafeName(option.value)}`}
            id={`${baseElementId}_${htmlAttrSafeName(option.value)}`}
            type="button"
            value={option.value}
            onClick={e => onChange(e, option.value)}
            disabled={disabled}
            className={classNames("me-2 mb-2", styles.multiSelectButton, option.checked ? styles.selected : styles.notSelected)}
          >
            {option.label}
          </button>
        )}

        {shouldShowMore &&
          <Button variant="link" disabled={disabled} onClick={() => setShouldShowMore(false)}>
            show more
          </Button>
        }
      </div>
    </div>
  );
}

function htmlAttrSafeName(str: string) {
  return str.toString().toLowerCase().replaceAll(" ", "_");
}

export function renderSearchFiltersMobile(elementId, options) {
  const node = ReactDOM.render(
    <SearchFilters
      ageRanges={options.ageRanges}
      subjects={options.subjects}
      tags={options.tags}
      clipLengths={options.clipLengths}
      allSeries={options.allSeries}
      mediaTypes={options.mediaTypes}
      standards={options.standards}
      genres={options.genres}
      decades={options.decades}
      includedFilters={options.includedFilters}
      selectedSearchFilterValues={options.selectedSearchFilterValues || {}}
      isPaidUser={options.isPaidUser}
      searchRequestPath={options.searchRequestPath}
      userId={options.userId}
      onSubmit={options.onSubmit}
    />,
    document.getElementById(elementId)
  );

  return node;
}