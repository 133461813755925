import type { BrazeUser, FormEventType } from '@clickview/braze-client';

import type { BrazeSource } from './enums/form_enum';
import { BrazeHelper } from './braze_helper';
import { ContactFormTopic } from './enums/contact_form';
import { NewQuoteFormData } from '../src/quotes/new_quote_form';
import { postData } from '../src/global_functions';

interface FormBrazeHelperSendOptions {
  source: BrazeSource;
  eventType: FormEventType,
  topic: ContactFormTopic,
  values: NewQuoteFormData,
  successCallback?: (success: boolean) => void
}

export const POSITION_DEPARTMENT_MAPPING = [
  { department: 'Management', value: 'Assistant Principal' },
  { department: 'Management', value: 'Assistant Superintendent' },
  { department: 'Management', value: 'Digital Learning Director' },
  { department: 'Management', value: 'Elementary Curriculum Director' },
  { department: 'Management', value: 'K-12 Curriculum Director' },
  { department: '', value: 'Parent' },
  { department: 'Management', value: 'Principal' },
  { department: 'Management', value: 'Superintendent' },
  { department: 'Teaching Staff', value: 'Teacher' },
  { department: '', value: 'Other' },
  { department: '', value: 'IT' },
  { department: '', value: 'Librarian' }
]

export const BrazeFormHelper = {
  async send({
    eventType,
    successCallback,
    topic,
    values,
    source
  }: FormBrazeHelperSendOptions) {
    try {
      const braze = await BrazeHelper.getClient();

      if (!braze.isInitialized()) {
        return;
      }
      const body = { email: values.email };

      const emailCheck = await postData({ url: '/braze_api/check_email', data: body });

      const emailCheckData = emailCheck.data;
      if (emailCheckData.error) {
        console.error(`Failed to check email: ${emailCheckData.error}`);
      }

      let accountName = values?.organization;
      if (!accountName && !emailCheckData?.message?.exists) {
        accountName = 'Unknown';
      }

      const selectedPosition = POSITION_DEPARTMENT_MAPPING.find(option => values?.role === option.value);
      const user: BrazeUser = {
        email: values.email,
        firstName: values['first_name'],
        lastName: values['last_name'],
        phone: values.phone,
        custom: {
          jobTitle: values.role || selectedPosition?.value,
          accountName: accountName,
          department: selectedPosition?.department,
          topic: topic,
          source: source,
        },
        event: {
          contactUsComment: values?.message,
          eventTopic: topic,
          howCanWeHelp: `request pricing / # of students: ${values.number_of_staff}`,
          reasonForInterest: values?.message
        }
      };

      // Set sync_to_crm to true if the user does not exist in braze
      if (!emailCheckData.error && !emailCheckData?.exists && user.custom) {
        user.custom = {
          ...user.custom,
          syncToCrm: true
        };
      }

      braze.setUserInit(user);
      braze.event?.logForm({
        eventType: eventType,
        callback: successCallback
      });
    } catch (error) {
      console.error(error);

      if (typeof successCallback !== 'function')
        return;

      successCallback(false);
    }
  }
};
