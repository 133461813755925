import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Form from 'react-bootstrap/Form';

import { postData } from "../global_functions";
import FormErrorsList from '../shared_components/form_errors_list';

interface PredictionData {
  video_url: string;
  model_isEdu: number;
  model_confidence: number;
}

function VideoPredictions() {
  const [errors, setErrors] = useState([]);
  const [predictions, setPredictions] = useState<Array<PredictionData>>([]);
  const [csvFile, setCsvFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeFile = (e) => setCsvFile(e.target.files[0]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setErrors([]);

    const formData = new FormData();
    formData.append("csv_file", csvFile);

    let response;

    try {
      response = await postData({
        url: "/video_predictions",
        data: formData,
        headers: {
          'Content-Type': "multipart/form-data"
        }
      });
    } catch (e) {
      response = e.response;
    }

    setIsLoading(false);

    if (response.status !== 200) {
      setErrors(response.data.errors);
      return;
    }

    setPredictions(response.data.data);
  };

  const isDisabled = isLoading || !csvFile;

  return (
    <>
      <FormErrorsList errors={errors} />

      <Form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-3 col-md-3">
            <input accept="text/csv" name="csv_file" className="form-control-file" type="file" onChange={onChangeFile} />
          </div>
        </div>

        <div className="row mt-4">
          <div className="mb-3 col-md-3">
            <button type="submit" className="btn btn-dark" disabled={isDisabled}>
              Submit
            </button>
          </div>
        </div>
      </Form>

      {predictions.length > 0 && renderPredictionsTable(predictions)}
    </>
  );
}

function renderPredictionsTable(predictions: Array<PredictionData>) {
  const predictionsHtml = predictions.map((prediction: PredictionData) => {
    const isEducational = (prediction.model_isEdu > 0) ? "Yes" : "No";
    const confidence = (prediction.model_confidence && Math.round(prediction.model_confidence * 100)) || 0;

    return (
      <tr>
        <td>
          <a href={prediction.video_url} target="_blank" rel="noopener noreferrer">{prediction.video_url}</a>
        </td>
        <td>{isEducational}</td>
        <td>{confidence}</td>
      </tr>
    );
  });

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Video URL</th>
          <th>Is Educational?</th>
          <th>Confidence %</th>
        </tr>
      </thead>

      <tbody>
        {predictionsHtml}
      </tbody>
    </table>
  );
}

export function renderVideoPredictions(elementId) {
  const node = ReactDOM.render(
    <VideoPredictions />,
    document.getElementById(elementId)
  );

  return node;
}