import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import ReactDOM from "react-dom";
import { useDebouncedCallback } from "use-debounce";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import { fetchData } from "../global_functions";
import DisplayTag from "../resources/display_tag";
import DisplayAgeRange from "../resources/display_age_range";

import styles from "./homepage_search.module.scss";
import { ResourceThumbnail } from "../resources/resource_thumbnail";

function EmptyResultItem({ isLoading }) {
  return (
    <div className={classNames('col-4', styles.videoItem)}>
      <div className={styles.videoThumbnail}>
        <div className={styles.thumbnailOverflow}>
          {isLoading ? <Spinner animation="border" className="ch-blue" /> : <div className={styles.playButton}></div>}
        </div>
      </div>
      <div className="mt-2">
        <div className={classNames('mb-1 rounded-pill w-80', styles.videoSubTitle)}></div>
        <div className={classNames('mb-1 rounded-pill w-60', styles.videoTitle)}></div>
        <div className="d-flex">
          <div className={styles.tag}></div>
          <div className={styles.tag}></div>
          <div className={styles.tag}></div>
          <div className={styles.tag}></div>
        </div>
        <div className="d-flex">
          <div className={classNames('rounded-pill me-2', styles.moreInfo)}></div>
          <div className={classNames('rounded-pill', styles.moreInfo)}></div>
        </div>
      </div>
    </div>
  )
}

function HomepageSearch() {
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (searchQuery.length < 1) {
      setResults([]);
      return;
    }

    performSearch(searchQuery);
  }, [searchQuery]);

  const performSearch = useDebouncedCallback(
    async (query: string) => {
      setLoading(true);
      setFirstLoad(false);

      const response = await fetchData({
        url: "/search/homepage_search",
        params: { q: query }
      });

      setResults(response.data.results);
      setLoading(false);
    },
    400
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-3 w-100">
        <Form.Control
          type="input"
          placeholder="Enter a topic (ex: photosynthesis, american history)"
          aria-label="Search"
          size={isSmallScreen ? "sm" : undefined}
          onChange={e => setSearchQuery(e.target.value)}
          className="form-control"
        />
      </div>

      {/* empty status */}
      {!results.length &&
        <div className="row justify-content-center w-100 gx-3">
          {Array.from({ length: 3 }).map((_, index) =>
            <EmptyResultItem key={index} isLoading={loading} />
          )}
        </div>
      }

      {!loading && !firstLoad &&
        <>
          <div className={classNames("row justify-content-center", styles.cardRow)}>
            {results.map((result, index) =>
              <DisplayResource resource={result} key={index} />
            )}
          </div>

          {searchQuery.length > 0 && results.length < 1 &&
            <p>No results found. Try changing your search query.</p>
          }
        </>
      }
    </div>
  );
}

function DisplayResource({ resource }) {
  // Lowercase subject names
  const subjects = resource.subjects.map(subject => ({ ...subject, name: subject.name.toLowerCase() }));

  let [seriesName, title] = resource.title.split(":")
  if (!title) {
    title = seriesName
  }

  return (
    <div className={classNames("col col-12 col-md-4", styles.cardItem)}>
      <div className={styles.cardItem}>
        <a href="/signup?homepage_search=true" className={styles.resourceWrapper}>
          <ResourceThumbnail resource={resource.resource} extraClasses="card-img-top" />

          <div className="my-2">
            <div className='fw-normal'>{seriesName}</div>
            <div className="fw-500">{title}</div>

            <div className="card-text">
              <div>
                <DisplayAgeRange ageRanges={resource.age_ranges} size="sm" showTooltip={true} />
              </div>

              <div className="resource-tag">
                <div className="d-flex flex-wrap mt-2">
                  {
                    subjects.concat(resource.subject_tags).map((tag, index) => (
                      <div className="mb-2 me-2 w-max-100" key={index}>
                        <DisplayTag tag={tag} enableClicks={false} />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export function renderHomepageSearch(elementId) {
  const node = ReactDOM.render(
    <HomepageSearch />,
    document.getElementById(elementId)
  );

  return node;
}