import React, { useState } from "react";
import ReactDOM from "react-dom";
import pluralize from "pluralize";

import { postData } from "../../src/global_functions";

interface OnSuccessFuncArgs {
  newClips: Array<object>;
  updatedClips: Array<object>;
  errors: Array<string>;
}

interface Props {
  userId: number;
  onSuccess: (data: OnSuccessFuncArgs) => void;
}

interface ResponseData {
  new_user_clips: Array<object>;
  updated_user_clips: Array<object>;
  errors: Array<string>;
}

function UserClipsImportCsvForm({ userId, onSuccess }: Props) {
  const [csvFile, setCsvFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [newClipsAdded, setNewClipsAdded] = useState([]);
  const [updatedClips, setUpdatedClips] = useState([]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrors([]);
    setNewClipsAdded([]);
    setUpdatedClips([]);
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("csv_file", csvFile);

      const response = await postData({
        url: `/users/${userId}/user_clips/import_csv`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const data: ResponseData = response.data;

      setNewClipsAdded(data.new_user_clips);
      setUpdatedClips(data.updated_user_clips);
      setErrors(data.errors);

      onSuccess({
        newClips: data.new_user_clips,
        updatedClips: data.updated_user_clips,
        errors: data.errors
      });
    } catch (e) {
      setErrors(e.response.data.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-3 col-md-12">
            <input type="file" name="csv_file" accept="text/csv" className="form-control-file" onChange={e => setCsvFile(e.target.files[0])} />
          </div>
        </div>

        <button type="submit" className="btn btn-dark px-3 mt-3" disabled={isLoading}>
          {isLoading ? "Importing..." : "Start Import"}
        </button>
      </form>

      <hr />

      {newClipsAdded.length > 0 &&
        <p className="fw-bold">
          {newClipsAdded.length} {pluralize('clip', newClipsAdded.length)} added
        </p>
      }

      {updatedClips.length > 0 &&
        <p className="fw-bold">
          {updatedClips.length} {pluralize('clip', updatedClips.length)} updated
        </p>
      }

      {errors.length > 0 &&
        <div>
          <p className="fw-bold">
            {errors.length} {pluralize('row', errors.length)} with errors
          </p>

          <ul>
            {errors.map(error => <li key={error}>{error}</li>)}
          </ul>
        </div>
      }
    </>
  );
}

export function renderUserClipsImportCsvForm(elementId, options) {
  const node = ReactDOM.render(
    <UserClipsImportCsvForm
      userId={options.userId}
      onSuccess={options.onSuccess}
    />,
    document.getElementById(elementId)
  );

  return node;
}