import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";
import { insertTextAtCaret } from "../string_functions";
import { DiscussionQuestionTemplate } from "../../types";

interface Props {
  onCreate: (questionTemplate: DiscussionQuestionTemplate, numTemplatesRemaining: number) => void;
  onCancel: () => void;
}

const DEFAULT_PRIVACY = 'public';

// Copied from the backend
const BLANK_VALUE = '____';

export function AddQuestionTemplateForm(props: Props) {
  const [prompt, setPrompt] = useState('');
  const [privacy, setPrivacy] = useState(DEFAULT_PRIVACY);

  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const promptElementRef = useRef();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    }
  }, []);

  const onAddBlank = () => {
    const updatedPrompt = insertTextAtCaret(promptElementRef.current, BLANK_VALUE);
    setPrompt(updatedPrompt);

    // @ts-ignore
    promptElementRef.current.focus();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsDisabled(true);

      const { data } = await postData({
        url: "/discussion_question_templates",
        data: {
          discussion_question_template: {
            prompt: prompt,
            privacy: privacy
          }
        }
      });

      props.onCreate(data.discussion_question_template, data.num_templates_remaining);
    } catch (e) {
      setErrors(e.response.data.errors);
    } finally {
      if (mountedRef.current) {
        setIsDisabled(false);
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {errors?.length > 0 &&
        <div className="mb-3">
          <FormErrorsList errors={errors} />
        </div>
      }

      <h5>Add a question template</h5>

      <div className="mb-3">
        <Form.Control
          as="textarea"
          ref={promptElementRef}
          rows={3}
          placeholder="Write a question"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
        />
      </div>

      <div className="d-flex flex-wrap mb-3">
        <div className="me-2">
          <Button type="button" variant="outline-dark" onClick={onAddBlank}>
            <FontAwesomeIcon icon="plus" />
            {' '}
            Add Blank
          </Button>
        </div>

        <div className="flex-grow-1"></div>

        <div>
          <Form.Control as="select" onChange={e => setPrivacy(e.target.value)}>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </Form.Control>
        </div>
      </div>

      <div className="d-flex flex-wrap">
        <Button type="button" variant="outline-dark" className="px-4" onClick={props.onCancel}>
          Cancel
        </Button>

        <div className="flex-grow-1"></div>

        <Button type="submit" variant="dark" className="px-4" disabled={isDisabled}>
          Save
        </Button>
      </div>
    </Form>
  );
}