import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { validate } from "email-validator";

import FormErrorsList from "../shared_components/form_errors_list";
import { postData } from "../global_functions";
import { BrazeFormHelper } from "../../lib/braze_form_helper";
import { BrazeHelper } from "../../lib/braze_helper";
import { ContactFormTopic } from "../../lib/enums/contact_form";
import { BrazeSource } from "../../lib/enums/form_enum";

// if you going to change values here, make sure to update POSITION_DEPARTMENT_MAPPING in braze_form_helper.ts as well
const POSITION_OPTIONS_MAPPING_V2 = {
  assistant_principal: 'Assistant Principal',
  assistant_superintendent: 'Assistant Superintendent',
  digital_learning_director: 'Digital Learning Director',
  elementary_curriculum_director: 'Elementary Curriculum Director',
  K12_curriculum_director: 'K-12 Curriculum Director',
  librarian: 'Librarian',
  it: 'IT',
  parent: 'Parent',
  principal: 'Principal',
  superintendent: 'Superintendent',
  teacher: 'Teacher',
  other: 'Other',
}

export interface NewQuoteFormData {
  organization: string;
  first_name: string;
  last_name: string;
  role: string;
  email: string;
  phone: string;
  number_of_staff?: number;
  message?: string;
}

function NewQuoteForm() {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [organization, setOrganization] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [numberOfStudents, setNumberOfStudents] = useState(0);
  const [extraInfo, setExtraInfo] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSuccessfulSend, setIsSuccessfulSend] = useState(false);

  useEffect(() => {
    setIsValidEmail(email.length > 0 && validate(email));
  }, [email])

  const onCreate = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let data;
    const payload: {['lead_contact_message']:NewQuoteFormData} = {
        lead_contact_message: {
          organization: organization,
          first_name: firstName,
          last_name: lastName,
          role: title,
          email: email,
          number_of_staff: numberOfStudents,
          phone: phone,
          message: extraInfo
        }
      }

    try {
      const response = await postData({
        url: "/lead_contact_messages",
        data: payload,
      });

      data = response.data;
    } catch (e) {
      data = e.response.data;
    }

    if (data.errors) {
      setErrors(data.errors);
    } else if (data.lead_contact_message) {
      setIsSuccessfulSend(true);

      // send to Braze
      const { ClassHookMarketingFormEvent } = await BrazeHelper.getEnums();

      const brazeValues ={
        ... payload.lead_contact_message,
        role: POSITION_OPTIONS_MAPPING_V2[payload.lead_contact_message.role]
      }

      try {
        await BrazeFormHelper.send({
          eventType: ClassHookMarketingFormEvent.ContactUsFormSubmit,
          topic: ContactFormTopic.Sales,
          values: brazeValues,
          source: BrazeSource.Contact,
        });
      } catch (e) {
        console.error(e)
      }
    }

    setIsLoading(false);
  };

  if (isSuccessfulSend) {
    return (
      <h5 className="text-center">
        Thanks for your interest!
        We'll be in touch soon with more information.
      </h5>
    );
  }

  const saveButtonEnabled = !isLoading &&
    organization.length > 0 &&
    firstName.length > 0 &&
    lastName.length > 0 &&
    title.length > 0 &&
    isValidEmail &&
    phone.length > 0;

  return (
    <>
      <FormErrorsList errors={errors} />

      <Form onSubmit={onCreate}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="quote_first_name">First Name*</Form.Label>
            <Form.Control id="quote_first_name" type="text" onChange={e => setFirstName(e.target.value)} required />
          </div>

          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="quote_last_name">Last Name*</Form.Label>
            <Form.Control id="quote_last_name" type="text" onChange={e => setLastName(e.target.value)} required />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="quote_email">Email*</Form.Label>
            <Form.Control id="quote_email" type="email" onChange={e => setEmail(e.target.value)} required />

            {email.length > 0 && !isValidEmail &&
              <div className="text-red small">Please enter a valid email</div>
            }
          </div>

          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="quote_phone">Phone number*</Form.Label>
            <Form.Control id="quote_phone" type="tel" onChange={e => setPhone(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="quote_organization">School or District Name*</Form.Label>
            <Form.Control id="quote_organization" type="text" onChange={e => setOrganization(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="quote_title">Position*</Form.Label>
            <Form.Control
              as="select"
              id="quote_title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            >
              <option value="" disabled>Select a position</option>
              {Object.keys(POSITION_OPTIONS_MAPPING_V2).map(key => <option key={key} value={key}>{POSITION_OPTIONS_MAPPING_V2[key]}</option>)}
            </Form.Control>
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="quote_num_students">Number of students</Form.Label>
            <Form.Control id="quote_num_students" type="number" min={0} onChange={e => setNumberOfStudents(+e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="quote_extra_info">Is there any other information you would like to share with us?</Form.Label>
            <Form.Control id="quote_extra_info" type="text" onChange={e => setExtraInfo(e.target.value)} />
          </div>
        </div>

        <div className="row w-80 m-auto">
          <Button type="submit" variant="dark" disabled={!saveButtonEnabled}>
            <span className="me-2">Send request</span>
          </Button>
        </div>
      </Form>
    </>
  );
}

export function renderNewQuoteForm(elementId, options) {
  const node = ReactDOM.render(
    <NewQuoteForm />,
    document.getElementById(elementId)
  );

  return node;
}