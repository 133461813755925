import React, { useState } from "react";
import ReactDOM from "react-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ReactSVG } from "react-svg";

import FormErrorsList from "../shared_components/form_errors_list";
import { patchData } from "../global_functions";
import { Assignment, Course, User } from "../../types";

import infoCircleIcon from "../shared_components/images/info_circle_icon.svg";

const DATETIME_FORMAT = "YYYY-MM-DD";

interface Props {
  assignment: Assignment;
  assignmentUsers: Array<User>;
  selectedCourses: Array<Course>;
  courses: Array<Course>;
}

function EditAssignmentForm({ assignment, assignmentUsers, selectedCourses, courses }: Props) {
  const [name, setName] = useState(assignment.name);
  const [instructions, setInstructions] = useState(assignment.instructions);
  const [playAfterPrompt, setPlayAfterPrompt] = useState(assignment.play_after_prompt);
  const [dueDate, setDueDate] = useState(assignment.due_date);
  const [preventSkipAhead, setPreventSkipAhead] = useState(assignment.prevent_skip_ahead);
  const [courseIds, setCourseIds] = useState(selectedCourses.map(course => course.id));
  const [studentIds, setStudentIds] = useState(assignmentUsers.map(user => user.id));
  const [saveSuccessful, setSaveSuccessful] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState([]);

  const allCourseOptions = courses.map(course => ({ label: course.name, value: course.id }));
  const allStudentOptions = assignmentUsers.map(user => ({ label: user.name, value: user.id }));
  const defaultSelectedCourses = selectedCourses.map(course => ({ label: course.name, value: course.id }));

  const onChangeCourseIds = (courseOptions) => {
    let newCourseIds = courseOptions || [];
    newCourseIds = newCourseIds.map(course => course.value);
    setCourseIds(newCourseIds);
  };

  const onChangeStudentIds = (studentOptions) => {
    let newStudentIds = studentOptions || [];
    newStudentIds = newStudentIds.map(student => student.value);
    setStudentIds(newStudentIds);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setSaveSuccessful(false);
    setIsDisabled(true);

    try {
      const { data } = await patchData({
        url: `/assignments/${assignment.id}`,
        data: {
          assignment: {
            name: name,
            instructions: instructions,
            due_date: dueDate,
            play_after_prompt: playAfterPrompt,
            prevent_skip_ahead: preventSkipAhead,
            course_ids: courseIds,
            student_ids: studentIds
          }
        }
      });

      if (data.success) {
        window.scrollTo({ top: 0 });

        setSaveSuccessful(true);
        setErrors([]);
      }
    } catch (e) {
      const errors = e.response.data.errors;
      setErrors(errors);
    }

    setIsDisabled(false);
  };

  const buttonDisabled = isDisabled || name.length < 2 || instructions.length < 2;

  const handleDateApply = (ev, picker) => setDueDate(picker.startDate.format(DATETIME_FORMAT));
  const handleDateCancel = (ev, picker) => setDueDate('');

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      {saveSuccessful &&
        <div className="alert alert-success">Your changes have been saved</div>
      }

      <FormErrorsList errors={errors} />

      <Form onSubmit={onSubmit}>
        <Row>
          <div className="mb-3 col-md-12">
            <label htmlFor="new_assignment_name">Assignment name</label>
            <input
              id="new_assignment_name"
              className="form-control"
              type="text"
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </Row>

        <Row>
          <div className="mb-3 col-md-12">
            <label htmlFor="new_assignment_instructions">Instructions</label>
            <textarea
              id="new_assignment_instructions"
              className="form-control"
              rows={3}
              defaultValue={instructions}
              onChange={e => setInstructions(e.target.value)}
            />
          </div>
        </Row>

        <Row>
          <div className="mb-3 col-md-12">
            <div className="d-flex flex-wrap align-items-center">
              <Form.Check
                id="new_assignment_play_after_prompt"
                className="me-2"
                label="Continue video after students submit an answer"
                defaultChecked={playAfterPrompt}
                onChange={e => setPlayAfterPrompt(e.target.checked)}
              />

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="assignment-form-play-after-prompt-tooltip">
                    If unchecked, students will need to press the play button
                    to continue the video after submitting a response to a question.
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <ReactSVG src={infoCircleIcon} wrapper="span" ref={ref} {...triggerHandler} />
                )}
              </OverlayTrigger>
            </div>
          </div>
        </Row>

        <Row>
          <div className="mb-3 col-md-12">
            <div className="d-flex flex-wrap align-items-center">
              <Form.Check
                id="new_assignment_prevent_skip_ahead"
                className="me-2"
                label="Prevent students from skipping past questions"
                defaultChecked={preventSkipAhead}
                onChange={e => setPreventSkipAhead(e.target.checked)}
              />

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="assignment-form-prevent-skip-ahead-tooltip">
                    If checked, students will not be able to go past a question until they answer it
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <ReactSVG src={infoCircleIcon} wrapper="span" ref={ref} {...triggerHandler} />
                )}
              </OverlayTrigger>
            </div>
          </div>
        </Row>

        <Row>
          <div className="mb-3 col-md-12">
            <div>
              <label htmlFor="new_assignment_course_ids" className="mb-0">
                Assign to class
              </label>
              {' '}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="assignment-form-course-ids-tooltip">
                    If you don't specify a class, any student can join.
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <ReactSVG src={infoCircleIcon} wrapper="span" ref={ref} {...triggerHandler} />
                )}
              </OverlayTrigger>
            </div>

            <div className="mb-2">
              <a href="/courses" target="_blank" className="small" rel="noopener noreferrer">
                Need to add a class?
              </a>
            </div>

            <Select
              id="new_assignment_course_ids"
              onChange={onChangeCourseIds}
              options={allCourseOptions}
              defaultValue={defaultSelectedCourses}
              isMulti
              closeMenuOnSelect={false}
              isDisabled={false}
            />
          </div>
        </Row>

        {assignmentUsers && assignmentUsers.length > 0 &&
          <Row>
            <div className="mb-3 col-md-12">
              <div className="mb-2">
                <label htmlFor="new_assignment_course_ids" className="mb-0">
                  Individually assigned students
                </label>
                {' '}
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="assignment-form-course-ids-tooltip">
                      Students who have joined your assignment via a code.
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <ReactSVG src={infoCircleIcon} wrapper="span" ref={ref} {...triggerHandler} />
                  )}
                </OverlayTrigger>
              </div>

              <Select
                id="new_assignment_student_ids"
                onChange={onChangeStudentIds}
                options={allStudentOptions}
                defaultValue={allStudentOptions}
                isMulti
                closeMenuOnSelect={false}
                isDisabled={false}
              />
            </div>
          </Row>
        }

        <Row>
          <div className="mb-3 col-md-12">
            <label htmlFor="new_assignment_due_date">Due date</label>
            <DateRangePicker
              initialSettings={{
                minDate: tomorrow,
                autoUpdateInput: false,
                singleDatePicker: true,
                drops: 'up',
                locale: { cancelLabel: "Clear", format: DATETIME_FORMAT }
              }}
              onHide={handleDateApply}
              onApply={handleDateApply}
              onCancel={handleDateCancel}
            >
              <input type="text" className="form-control" value={dueDate} onChange={e => setDueDate(e.target.value)} />
            </DateRangePicker>
          </div>
        </Row>

        <div className="mt-3">
          <Button type="submit" variant="dark" className="px-5 me-3" disabled={buttonDisabled}>
            Save changes
          </Button>

          <a href="/assignments" className="btn btn-link text-reset text-decoration-none px-5">
            Cancel
          </a>
        </div>
      </Form>
    </>
  );
}

export function renderEditAssignmentForm(elementId, options) {
  const node = ReactDOM.render(
    <EditAssignmentForm
      assignment={options.assignment}
      assignmentUsers={options.assignmentUsers}
      selectedCourses={options.selectedCourses}
      courses={options.courses}
    />,
    document.getElementById(elementId)
  );

  return node;
}