interface ClickviewReferrerQueryParams {
  r: string
}

export const UrlHelpers = {
  addReferrer(linkToUrl: string, currentUrl: string): string {
    const queryParams = clickviewReferrerQueryParams(currentUrl);

    const uri = new URL(linkToUrl);
    Object.entries(queryParams).forEach(([key, value]) => uri.searchParams.set(key, value));

    return uri.toString();
  }
}

function clickviewReferrerQueryParams(currentUrl: string): ClickviewReferrerQueryParams {
  // internalReferrer refers to the URL that brought the user to ClickView
  // originalReferrer refers to how the user first came to ClassHook. We don't store this information yet, so leave it blank for now
  const hash = {
    internalReferrer: currentUrl,
    originalReferrer: ""
  };

  const base64EncodedStr = window.btoa(JSON.stringify(hash));

  return { r: base64EncodedStr };
}