// Override of the corresponding ActiveStorage class to support passing in a service name
// NOTE: This is temporary until the following PR gets merged: https://github.com/rails/rails/pull/38957
import { DirectUpload } from "@rails/activestorage";
import { BlobRecord } from "./blob_record";
import { BlobUpload } from "./blob_upload";
import { FileChecksum } from "./file_checksum";

export class ChDirectUpload extends DirectUpload {
  constructor(file, url, serviceName, delegate) {
    super(file, url, delegate);

    this.serviceName = serviceName;
  }

  create(callback) {
    FileChecksum.create(this.file, (error, checksum) => {
      if (error) {
        callback(error)
        return
      }

      const blob = new BlobRecord(this.file, checksum, this.url, this.serviceName);
      notify(this.delegate, "directUploadWillCreateBlobWithXHR", blob.xhr)

      blob.create(error => {
        if (error) {
          callback(error)
        } else {
          const upload = new BlobUpload(blob)
          notify(this.delegate, "directUploadWillStoreFileWithXHR", upload.xhr)
          upload.create(error => {
            if (error) {
              callback(error)
            } else {
              callback(null, blob.toJSON())
            }
          });
        }
      });
    });
  }
};

function notify(object, methodName, ...messages) {
  if (object && typeof object[methodName] == "function") {
    return object[methodName](...messages)
  }
}