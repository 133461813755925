import mixpanel from 'mixpanel-browser';

const initOptions = { ignore_dnt: true };
const isProduction = (process.env.NODE_ENV === "production" && process.env.SERVER_ENV === "production");

if (!isProduction) {
  initOptions['debug'] = true;
}

mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN, initOptions);

export default mixpanel;