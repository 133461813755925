import React from "react";
import DisplayTag from "./display_tag";
import DisplaySubject, { SubjectObj } from "./display_subject";
import DisplayAgeRange from "./display_age_range";

import { AgeRange, Resource, Tag } from "../../types";
import { ResourceThumbnail } from "./resource_thumbnail";

interface ResourceObj {
  resource: Resource;
  resource_subjects: Array<SubjectObj>;
  subject_tags: Array<Tag>;
  age_ranges: Array<AgeRange>;
  title: string;
  url: string;
}

interface Props {
  resourceObj: ResourceObj;
  thumbnailClasses?: string;
}

export default function DisplayResourceFlex({ resourceObj, thumbnailClasses }: Props) {
  return (
    <div className="resource-container">
      <div className="resource-thumbnail-container">
        <ResourceThumbnail resource={resourceObj.resource} extraClasses={thumbnailClasses} />
      </div>

      <div className="resource-title">
        <a href={resourceObj.url} className="text-reset text-decoration-none">{resourceObj.title}</a>
      </div>

      <div>
        <DisplayAgeRange ageRanges={resourceObj.age_ranges} size="sm" />
      </div>

      <div className="resource-tag">
        <div className="d-flex flex-wrap mt-2">
          {resourceObj.resource_subjects.map((subjectObj, index) => (
            <div className="mb-2 me-2" key={index}>
              <DisplaySubject subjectObj={subjectObj} />
            </div>
          ))}

          {resourceObj.subject_tags.map((tag, index) => (
            <div className="mb-2 me-2" key={index}>
              <DisplayTag tag={tag} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
