import React, { useState } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import DataTable from 'react-data-table-component';
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Pluralize from "react-pluralize";

import UpgradeButton from "../shared_components/upgrade_button";
import { chAnalyticsTrackEvent } from "../analytics";
import { fetchData } from "../global_functions";
import { Standard } from "../../types";

import "./browse_clips_by_standards.scss";

interface FormProps {
  standardsSets: Array<Array<string>>;
  allGradeLevels: Array<Array<number>>;
  onSubmitSearchForm: (selectedGradeLevel: string, selectedStandard: string, searchQuery: string) => void;
}

interface Props {
  standardsSets: Array<Array<string>>;
  allGradeLevels: Array<Array<number>>;
}

const onClipClicked = (standard, resource) => {
  chAnalyticsTrackEvent("standards", {
    standard_id: standard.id,
    standard_type: standard.standard_type,
    standard_code: standard.code,
    clip_id: resource.id,
    clip_title: resource.title,
    clip_type: "Resource",
    action: "browse_by_standards_clip_click"
  });
};

const onUpgradeButtonClicked = (standard) => {
  const args = {
    feature: "clips_by_standards",
    label: "click_paywall",
    standard_id: standard.id,
    standard_type: standard.standard_type,
    standard_code: standard.code
  };

  const userId = document.getElementById("browse-clips-by-standards-user-id")?.getAttribute("data-user-id");

  if (userId) args['user_id'] = userId;

  chAnalyticsTrackEvent("paywall", args);
};

const TABLE_COLUMNS = [
  {
    name: "Subject",
    selector: standard => standard.subject,
    sortable: true,
    wrap: true
  },
  {
    name: "Standard",
    selector: standard => standard.code,
    sortable: true,
    wrap: true
  },
  {
    name: "Description",
    selector: standard => standard.statement,
    sortable: true,
    wrap: true,
    width: "50%"
  },
  {
    name: "Clips",
    minWidth: "280px",
    cell: (standard: Standard) => {
      const numResources = standard.resources?.length || standard.resource_count;
      let clipsHtml;

      if (standard.resources) {
        clipsHtml = standard.resources.map((resource, index) => {
          return (
            <div key={index}>
              <a href={resource.url} target="_blank" rel="noopener noreferrer" onClick={() => onClipClicked(standard, resource)}>
                {resource.title}
              </a>

              {(index < (numResources - 1)) &&
                <hr className="my-2"></hr>
              }
            </div>
          );
        })
      } else if (standard.resource_count) {
        clipsHtml = (
          <div className="d-flex align-items-start justify-content-between w-100">
            <div className="me-2">
              <Pluralize singular={'clip'} plural={'clips'} count={standard.resource_count} className="fw-bold" />
              {' '} for this standard
            </div>

            <UpgradeButton
              darken={true}
              tooltipText="Upgrade to Premium to view"
              extraBtnClasses="mt-1"
              size="sm"
              onClick={() => onUpgradeButtonClicked(standard)}
            />
          </div>
        );
      }

      return (clipsHtml);
    }
  }
];

function BrowseStandardsForm({ standardsSets, allGradeLevels, onSubmitSearchForm }: FormProps) {
  const [selectedStandard, setSelectedStandard] = useState('');
  const [selectedGradeLevel, setSelectedGradeLevel] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSearchInput = (e) => setSearchQuery(e.target.value);
  const onStandardSetChange = (e) => setSelectedStandard(e.value);
  const onGradeLevelsChange = (e) => setSelectedGradeLevel(e.value);

  const handleSubmitSearchForm = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    await onSubmitSearchForm(selectedGradeLevel, selectedStandard, searchQuery);

    setIsLoading(false);
  };

  const standardsSetOptions = standardsSets.map(standardSetAry => {
    return { label: standardSetAry[0], value: standardSetAry[1] };
  });

  const gradeLevelsOptions = allGradeLevels.map(gradeLevelAry => {
    return { label: gradeLevelAry[0].toString(), value: gradeLevelAry[1] };
  });

  return (
    <div>
      <form onSubmit={handleSubmitSearchForm}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="browse_standards_set_select">Standard</Form.Label>
            <Select
              id="browse_standards_set_select"
              onChange={onStandardSetChange}
              options={standardsSetOptions}
              isDisabled={false}
            />
          </div>

          <div className="mb-3 col-md-6">
            <Form.Label htmlFor="browse_grade_levels_select">Grade Level</Form.Label>
            <Select
              id="browse_grade_levels_select"
              onChange={onGradeLevelsChange}
              options={gradeLevelsOptions}
              isDisabled={false}
            />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <label htmlFor="browse_query_search">Enter standard code or search term</label>
            <input
              id="browse_query_search"
              type="text"
              className="form-control"
              aria-label="Enter standard code or search term"
              onChange={onSearchInput}
            />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-2">
            <button type="submit" className="btn btn-dark px-4" disabled={isLoading}>Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}

function BrowseClipsByStandards({ standardsSets, allGradeLevels }: Props) {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [standardsResults, setStandardsResults] = useState([]);

  const onSubmitSearchForm = async (selectedGradeLevel, selectedStandard, searchQuery) => {
    setIsFirstLoad(false);
    setIsLoading(true);

    const params = { browse_mode: true };

    if (selectedGradeLevel) params['grade_level_ids'] = [selectedGradeLevel];
    if (selectedStandard) params['standard_types'] = [selectedStandard];
    if (searchQuery) params['query'] = searchQuery;

    const { data } = await fetchData({
      url: "/standards",
      params: params
    });

    setStandardsResults(data.standards);
    setIsLoading(false);

    const filterParams = { ...params };
    delete filterParams['browse_mode'];
    delete filterParams['query'];

    chAnalyticsTrackEvent("standards", {
      query: searchQuery,
      filters: filterParams,
      standard_results_count: data.standards.length,
      label: "search_on_browse_standards"
    });

    // The user hit a paywall
    if (data.standards && data.standards[0]?.resource_count) {
      const props = {
        feature: "clips_by_standards",
        label: "hit_paywall"
      };

      const userId = document.getElementById("browse-clips-by-standards-user-id")?.getAttribute("data-user-id");

      if (userId) props['user_id'] = userId;

      chAnalyticsTrackEvent("paywall", props);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <BrowseStandardsForm
            standardsSets={standardsSets}
            allGradeLevels={allGradeLevels}
            onSubmitSearchForm={onSubmitSearchForm}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-4">
          {isLoading &&
            <div className="text-center">
              <Spinner animation="border" variant="dark" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          }

          {!isFirstLoad && !isLoading &&
            <DataTable
              columns={TABLE_COLUMNS}
              data={standardsResults}
              noDataComponent="There aren't any results that match your filters."
            />
          }
        </div>
      </div>
    </>
  );
}

export function renderBrowseClipsByStandards(elementId, options) {
  const node = ReactDOM.render(
    <BrowseClipsByStandards
      standardsSets={options.standardsSets}
      allGradeLevels={options.allGradeLevels}
    />,
    document.getElementById(elementId)
  );

  return node;
}