import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SigninPaymentGateContainer from "../shared_components/signin_payment_gate_container";
import PaywallUpgradeSection from "./paywall_upgrade_section";
import { chAnalyticsTrackEvent } from "../analytics";

import GoogleClassroomLogo from "./google_classroom_logo.svg";
import toolsOverlayStyles from "../signin_gates/discussion_tools_overlay.module.scss";

interface Props {
  shown: boolean;
  showCloseButton?: boolean;
  containerClass?: string;
}

const ANALYTICS_FEATURE_NAME = "google_classroom_rostering";

export const GoogleClassroomRosteringPaywallModal = forwardRef(({ showCloseButton = false, ...props }: Props, ref) => {
  const [shown, setShown] = useState(props.shown);
  const [paywallShown, setPaywallShown] = useState(false);

  useEffect(() => {
    if (!shown || paywallShown) return;

    const args = { feature: ANALYTICS_FEATURE_NAME, label: "hit_paywall" };
    chAnalyticsTrackEvent("paywall", args);

    setPaywallShown(true);
  }, [shown]);

  useImperativeHandle(ref, () => ({
    updateShown: (newShown: boolean) => {
      setShown(newShown);
    },
  }));

  const handleOnClose = useCallback(() => {
    setShown(false);
  }, []);

  const onLinkClick = (e) => {
    const args = {
      feature: ANALYTICS_FEATURE_NAME,
      label: "click_paywall",
      link: e.currentTarget.href
    };

    chAnalyticsTrackEvent("paywall", args);
  };

  if (!shown) return null;

  return (
    <Modal show={shown} size="xl" onHide={handleOnClose}>
      <Modal.Body>
        <SigninPaymentGateContainer
          showCloseButton={showCloseButton}
          onClose={handleOnClose}
          containerClass={props.containerClass}
        >
          <SigninPaymentGateContainer.LeftSide className="p-4">
            <>
              <h4>
                <img src={GoogleClassroomLogo} width={32} height={32} className="me-2" alt="Google Classroom logo" />
                Roster with Google Classroom
              </h4>

              <p className="mt-4">
                Upgrade to Premium to roster students and classes with Google Classroom.
              </p>

              <p className="mt-4">
                Import all of your students and classes in just two clicks. Save time so you
                can focus on assigning content to students.
              </p>

              <PaywallUpgradeSection onLinkClick={onLinkClick} />
            </>
          </SigninPaymentGateContainer.LeftSide>

          <SigninPaymentGateContainer.RightSide>
            <div className="d-flex justify-content-center h-100">
              <div className={classNames(toolsOverlayStyles.backgroundContainer)}>
                <div className="p-4">
                  <h5 className="text-center">
                    <img src={GoogleClassroomLogo} width={28} height={28} className="me-2" alt="Google Classroom logo" />
                    Students
                  </h5>

                  <div className="border rounded w-75 mx-auto mt-4 p-3">
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon="user-circle" size="2x" className="me-3" />

                      <div className="w-100">
                        <div className={toolsOverlayStyles.videoDescPlaceholder75}></div>
                        <div className={toolsOverlayStyles.videoDescPlaceholder65}></div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center my-4">
                      <FontAwesomeIcon icon="user-circle" size="2x" className="me-3" />

                      <div className="w-100">
                        <div className={toolsOverlayStyles.videoDescPlaceholder75}></div>
                        <div className={toolsOverlayStyles.videoDescPlaceholder65}></div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon="user-circle" size="2x" className="me-3" />

                      <div className="w-100">
                        <div className={toolsOverlayStyles.videoDescPlaceholder75}></div>
                        <div className={toolsOverlayStyles.videoDescPlaceholder65}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SigninPaymentGateContainer.RightSide>
        </SigninPaymentGateContainer>
      </Modal.Body>
    </Modal>
  );
});

export function renderGoogleClassroomRosteringPaywallModal(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <GoogleClassroomRosteringPaywallModal
      shown={options.shown}
      showCloseButton={options.showCloseButton}
      containerClass={options.containerClass}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}