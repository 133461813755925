import React from "react";
import DisplayResourceFlex, { ResourceObj } from "../../resources/display_resource_flex";
import AddClipButton from "./add_clip_button";

import "./search.scss";

interface Props {
  queueableType: string;
  searchResults: { results: Array<ResourceObj> };
}

function ResultsWrapper({ children }) {
  return (
    <div id="add-clip-comparison-item-search-results" className="search-tab-results">
      <div className="d-flex flex-wrap mb-4">
        {children}
      </div>
    </div>
  )
}

export default function ClipSearchResults({ queueableType, searchResults }: Props) {
  if (searchResults?.results.length == 0) {
    return (
      <ResultsWrapper>
        <h4 className="mt-3 mx-auto">
          No results were found. Adjust your search options, or enter a new search term.
        </h4>
      </ResultsWrapper>
    )
  }
  return (
    <ResultsWrapper>
      {
        searchResults?.results.map((searchItem, index) => (
          <div className="d-flex flex-column discussion-search-result-container p-2" key={index}>
            <DisplayResourceFlex resourceObj={searchItem} thumbnailClasses="discussion-thumbnail" />

            <div className="flex-grow-1"></div>

            <div className="w-75 mx-auto mt-3">
              <AddClipButton
                queueableType={queueableType}
                video={searchItem.resource}
                buttonBlock={true}
              />
            </div>
          </div>
        ))
      }
    </ResultsWrapper>
  )
}
