import { useEffect } from 'react';

// This means the a11y outline styles will only be applied to elements when focused by tabbing. Not by clicking.
// This is so we can preserve a11y when it matters (when users are tabbing) but preserve aesthetics.

export function useTabbingListener(): void {
  function handleFirstTab(e: KeyboardEvent): void {
    if (e.key === 'Tab') {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
    }
  }

  function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  }

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab);
    return () => window.removeEventListener('keydown', handleFirstTab);
  }, []);
}