import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import styles from "./upgrade_button.module.scss";

interface Props {
  buttonText?: string;
  disableClicks?: boolean;
  extraBtnClasses?: string;
  darken?: boolean;
  icon?: IconProp;
  tooltipText?: string;
  size?: 'sm' | 'md' | 'lg';
  onClick?: (e) => void;
}

const DEFAULT_TOOLTIP = "Upgrade to Premium";

export default function UpgradeButton({
  disableClicks = false,
  extraBtnClasses = "",
  darken = false,
  icon = "bolt",
  tooltipText = DEFAULT_TOOLTIP,
  size = "md",
  ...props
}: Props) {
  const linkAttrs = { href: "/pricing", rel: "noopener noreferrer", target: "_blank" };

  if (props.onClick) linkAttrs["onClick"] = props.onClick;

  const tabIndexValue = disableClicks ? -1 : 0;
  const fontIconSize = (size !== "lg") ? "sm" : "lg";
  const theClasses = classNames(
    "btn",
    extraBtnClasses,
    darken ? styles.darker : '',
    disableClicks ? styles.disablePointerEvents : '',
    (size !== "md") ? styles[size] : ''
  );

  return (
    <>
      {!props.buttonText &&
        <OverlayTrigger overlay={
          <Tooltip id={`upgrade-button-${uuid()}`}>{tooltipText}</Tooltip>
        }>
          <a
            {...linkAttrs}
            className={classNames(styles.upgradeBtn, theClasses)}
            tabIndex={tabIndexValue}
          >
            <FontAwesomeIcon icon={icon} size={fontIconSize} />
          </a>
        </OverlayTrigger>
      }

      {props.buttonText &&
        <a
          {...linkAttrs}
          className={classNames(styles.upgradeBtnWithText, theClasses)}
          tabIndex={tabIndexValue}
        >
          <FontAwesomeIcon icon="bolt" size={fontIconSize} className="me-1" />
          <span>{props.buttonText}</span>
        </a>
      }
    </>
  );
}

export function renderUpgradeButton(elementId, options) {
  const node = ReactDOM.render(
    <UpgradeButton
      buttonText={options.buttonText}
      disableClicks={options.disableClicks}
      extraBtnClasses={options.extraBtnClasses}
      darken={options.darken}
      tooltipText={options.tooltipText}
      size={options.size}
      onClick={options.onClick}
    />,
    document.getElementById(elementId)
  );

  return node;
}