// Format the price to currency
// Source: https://stackoverflow.com/a/16233919/2430657
export function formatCurrency(priceInDollars, numberOfDigits: number = 0) {
  const formatOptions = {
    style: 'currency',
    currency: 'USD'
  };

  if (numberOfDigits >= 0) {
    formatOptions['minimumFractionDigits'] = numberOfDigits;
  }

  const formatter = new Intl.NumberFormat('en-US', formatOptions);

  return formatter.format(priceInDollars);
}