import React, { createRef, useImperativeHandle, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { v4 as uuid } from "uuid";
import { useDebouncedCallback } from "use-debounce";

import { fetchData } from "../global_functions";
import { chAnalyticsTrackEvent } from "../analytics";

import { ClipType, Standard } from "../../types";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import "./add_standards_typeahead.scss";

const PER_PAGE = 10;
const STANDARDS_PATH = "/standards";

interface Props {
  clipId: number;
  clipType: ClipType;
  htmlInputName: string
  onStandardsChanged?: (standards: Array<Standard>) => void;
}

function makeRequest(query: string, clipId: number, clipType: ClipType) {
  const params = {
    query: query,
    limit: PER_PAGE
  };

  const paramKey = (clipType === "Resource") ? "resource_id" : "user_clip_id";
  params[paramKey] = clipId;

  return fetchData({ url: STANDARDS_PATH, params: params });
}

function renderHtmlInput(selectedResults, htmlInputName) {
  const selectedOptionsHtml = selectedResults.map((standard, index) => {
    return (<option key={index} value={standard.id} selected></option>);
  });

  return (
    <select name={htmlInputName} multiple hidden>
      {selectedOptionsHtml}
    </select>
  );
}

export const AddStandardsTypeahead = React.forwardRef((props: Props, ref) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Array<Standard>>([]);
  const [selectedResults, setSelectedResults] = useState([]);

  const typeaheadId = useMemo(() => `resource-standards-typeahead-${uuid()}`, []);

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const searchStandards = useDebouncedCallback(
    async (query) => {
      setLoading(true);

      const response = await makeRequest(query, props.clipId, props.clipType);

      setResults(response.data.standards);
      setLoading(false);

      chAnalyticsTrackEvent("standards", {
        query: query,
        model_id: props.clipId,
        model_type: props.clipType,
        label: "search_standards_typeahead"
      });
    },
    400
  );

  const onChange = (results) => {
    setSelectedResults(results);
    props.onStandardsChanged?.(results);
  };

  const handleSearch = (query) => {
    searchStandards(query);
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setSelectedResults([]);
    }
  }));

  return (
    <>
      <AsyncTypeahead
        filterBy={filterBy}
        id={typeaheadId}
        isLoading={loading}
        options={results}
        labelKey="code"
        maxResults={PER_PAGE}
        minLength={2}
        multiple
        onChange={onChange}
        onSearch={handleSearch}
        placeholder="Search for a standard..."
        selected={selectedResults}
        renderMenuItemChildren={standard => (
          <div key={standard.id}>
            <span className="fw-bold">
              {standard.code}
            </span>
            &nbsp;
            {standard.statement}

            <div className="text-muted small">
              {standard.standards_set}
            </div>
          </div>
        )}
        useCache={false}
      />

      {renderHtmlInput(selectedResults, props.htmlInputName)}
    </>
  );
});

export function renderAddStandardsTypeahead(elementId, options) {
  const typeaheadRef = createRef();

  ReactDOM.render(
    <AddStandardsTypeahead
      clipId={options.clipId}
      clipType={options.clipType}
      htmlInputName={options.htmlInputName}
      ref={typeaheadRef}
    />,
    document.getElementById(elementId)
  );

  return typeaheadRef;
}