import { Assignment, User } from "../../types";

export function isAssignmentMatch(assignment: Assignment, filterText: string) {
  if (!filterText) return false;

  const theFilterText = filterText && filterText.toLowerCase();

  if (assignment.name.toLowerCase().includes(theFilterText)) {
    return true;
  }

  if (assignment.instructions && assignment.instructions.toLowerCase().includes(theFilterText)) {
    return true;
  }

  if (assignment.assignedItem && assignment.assignedItem.title.toLowerCase().includes(theFilterText)) {
    return true;
  }

  return false;
}

export function isStudentMatch(student: User, filterText: string) {
  if (!filterText) return false;

  const theFilterText = filterText && filterText.toLowerCase();

  if (student.name.toLowerCase().includes(theFilterText)) {
    return true;
  }

  if (student.email.toLowerCase().includes(theFilterText)) {
    return true;
  }

  return false;
}