import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { validate } from 'email-validator';
import pluralize from "pluralize";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import Tags from "@yaireo/tagify/dist/react.tagify"

import "@yaireo/tagify/dist/tagify.css";
import "./invite_users_field.scss";

import { postData } from "../global_functions";

interface Props {
  postUrl: string;
  maxUsers?: number;
  placeholder?: string;
  extraContainerClasses?: string;
  extraButtonClasses?: string;
  onSuccess?: (data) => void;
}

const DEFAULT_MAX_USERS = 50;

export default function InviteUsersField({ postUrl, maxUsers = DEFAULT_MAX_USERS, placeholder = "Enter email", onSuccess, ...props }: Props) {
  const [emails, setEmails] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const tagifyRef = useRef();

  const tagifySettings = {
    autoComplete: {
      enabled: false
    },
    placeholder: placeholder,
    maxTags: maxUsers,
    validate: (tag) => {
      return validate(tag.value);
    }
  };

  const onChange = (e) => {
    const theEmails = e.detail.tagify.value.map(tag => tag.value);
    setEmails(theEmails);
  };

  const handleSubmit = (e) => {
    setDisabled(true);

    postData({
      url: postUrl,
      data: {
        emails: emails,
        referral_page_path: window.location.pathname
      }
    }).then(response => {
      setDisabled(false);
      setSuccessMessage(response.data.message);
      setEmails([]);

      // Remove emails from Tagify's state
      tagifyRef.current.removeAllTags();

      onSuccess?.(response.data);
    }).catch(response => {
      setDisabled(false);
    });
  };

  return (
    <div className={classNames("invite-users-field-container", props.extraContainerClasses)}>
      <div className="mb-2">
        <Tags
          className="small"
          settings={tagifySettings}
          tagifyRef={tagifyRef}
          onChange={onChange}
        />
      </div>

      <Button
        variant="dark"
        disabled={(disabled || emails.length < 1)}
        onClick={handleSubmit}
        className={props.extraButtonClasses}
      >
        {pluralize('Invite User', maxUsers)}
      </Button>

      {successMessage &&
        <div className="text-success small mt-3">{successMessage}</div>
      }
    </div>
  );
}

export function renderInviteUsersField(elementId, options) {
  const node = ReactDOM.render(
    <InviteUsersField
      postUrl={options.postUrl}
      maxUsers={options.maxUsers}
      placeholder={options.placeholder}
      extraContainerClasses={options.extraContainerClasses}
      extraButtonClasses={options.extraButtonClasses}
      onSuccess={options.onSuccess}
    />,
    document.getElementById(elementId)
  );

  return node;
}