import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DateTime } from "luxon";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import classNames from 'classnames';

import { isAssignmentMatch } from "./assignments_helper";
import { Assignment, AssignmentSubmission, Course, User } from "../../types";

import styles from './assignments.module.scss';

interface Props {
  assignments: Array<Assignment>;
  assignmentSubmissions: Array<AssignmentSubmission>;
  courses: Array<Course>;
  user: User;
}

function findAssignmentSubmission(assignment: Assignment, assignmentSubmissions: Array<AssignmentSubmission>) {
  const submission = assignmentSubmissions.find(assignmentSubmission => assignmentSubmission.assignment_id === assignment.id);
  return submission;
}

function StudentAssignmentsTable({ assignments, assignmentSubmissions, courses, user }: Props) {
  const [theAssignments, setTheAssignments] = useState(assignments);
  const [filterText, setFilterText] = useState("");
  const [filteredCourseIds, setFilteredCourseIds] = useState([]);

  let filteredAssignments = theAssignments;

  if (filterText.length > 0) {
    filteredAssignments = filteredAssignments.filter(assignment => isAssignmentMatch(assignment, filterText));
  }

  if (filteredCourseIds.length > 0) {
    filteredAssignments = filteredAssignments.filter(assignment => {
      return assignment.courses.find(course => filteredCourseIds.includes(course.id));
    });
  }

  const handleFilterChange = e => setFilterText(e.target.value);
  const handleFilterCourses = (ary) => {
    const theAry = ary || [];
    const courseIds = theAry.map(courseObj => courseObj.value);

    setFilteredCourseIds(courseIds);
  };

  const tableColumns = [
    {
      name: "Name",
      selector: assignment => assignment.name,
      sortable: true,
      wrap: true
    },
    {
      name: "Course",
      selector: assignment => assignment.courses[0]?.name,
      sortable: true,
      wrap: true,
      hide: "md"
    },
    {
      name: "Assigned On",
      selector: (assignment: Assignment) => {
        const dateTime = DateTime.fromISO(assignment.createdAt);
        return dateTime.toLocaleString(DateTime.DATETIME_FULL);
      },
      sortable: true,
      wrap: true,
      cell: (assignment: Assignment) => DateTime.fromISO(assignment.createdAt).toRelative(),
      hide: "md"
    },
    {
      name: "Status",
      selector: assignment => {
        const submission = findAssignmentSubmission(assignment, assignmentSubmissions);
        return submission?.display_status || 'Not Started';
      },
      cell: (assignment: Assignment) => {
        const submission = findAssignmentSubmission(assignment, assignmentSubmissions);
        return submission?.display_status || 'Not Started';
      }
    },
    {
      name: "Due Date",
      selector: assignment => {
        let dueDate = '';

        if (assignment.dueDate) {
          const dateTime = DateTime.fromISO(assignment.dueDate);
          dueDate = dateTime.toLocaleString(DateTime.DATETIME_FULL);
        }

        return dueDate;
      },
      sortable: true,
      wrap: true,
      cell: assignment => (assignment.dueDate ? DateTime.fromISO(assignment.dueDate).toRelative() : '')
    },
    {
      name: "Actions",
      cell: assignment => {
        return (
          <a href={`/assignments/${assignment.id}`}>View</a>
        );
      }
    }
  ];

  const courseOptions = courses.map(course => {
    return { label: course.name, value: course.id };
  });

  return (
    <>
      <div className="row">
        <div className="col-md-4 offset-md-4 mb-3">
          <Select
            id="assignments_filter_course"
            onChange={handleFilterCourses}
            options={courseOptions}
            isMulti
            closeMenuOnSelect={false}
            isDisabled={false}
            placeholder="Filter by course"
          />
        </div>

        <div className="col-md-4 mb-3">
          <input type="input" className="form-control" placeholder="Search..." value={filterText} onChange={handleFilterChange} />
        </div>
      </div>

      <div className={classNames(styles['assignments-table'], 'table-responsive')}>
        <DataTable
          columns={tableColumns}
          data={filteredAssignments}
        />
      </div>
    </>
  );
}

export function renderStudentAssignmentsTable(elementId, options) {
  const node = ReactDOM.render(
    <StudentAssignmentsTable
      assignments={options.assignments}
      assignmentSubmissions={options.assignmentSubmissions}
      courses={options.courses}
      user={options.user}
    />,
    document.getElementById(elementId)
  );

  return node;
}