import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";

import SigninPaymentGateContainer from "../shared_components/signin_payment_gate_container";
import DiscussionToolsOverlay from "../signin_gates/discussion_tools_overlay";
import PaywallUpgradeSection from "./paywall_upgrade_section";
import { chAnalyticsTrackEvent } from "../analytics";
import { paywallAnalyticsArgs } from "../analytics/analytics_helper";

interface Props {
  clipTitle: string;
  clipThumbnailUrl: string;
  shown: boolean;
  clipId?: number;
  clipType?: string;
  showCloseButton?: boolean;
  containerClass?: string;
}

const ANALYTICS_FEATURE_NAME = "new_assignment";

export const AssignmentPaywallModal = forwardRef(({ showCloseButton = false, ...props }: Props, ref) => {
  const [shown, setShown] = useState(props.shown);
  const [paywallShown, setPaywallShown] = useState(false);

  useEffect(() => {
    if (!shown || paywallShown) return;

    const args = paywallAnalyticsArgs({
      feature: ANALYTICS_FEATURE_NAME,
      label: "hit_paywall",
      clipId: props.clipId,
      clipType: props.clipType
    });

    chAnalyticsTrackEvent("paywall", args);

    setPaywallShown(true);
  }, [shown]);

  useImperativeHandle(ref, () => ({
    updateShown: (newShown: boolean) => {
      setShown(newShown);
    },
  }));

  const handleOnClose = useCallback(() => {
    setShown(false);
  }, []);

  const onLinkClick = (e) => {
    const args = paywallAnalyticsArgs({
      feature: ANALYTICS_FEATURE_NAME,
      label: "click_paywall",
      clipId: props.clipId,
      clipType: props.clipType,
      link: e.currentTarget.href
    });

    chAnalyticsTrackEvent("paywall", args);
  };

  if (!shown) return null;

  return (
    <Modal show={shown} size="xl" onHide={handleOnClose}>
      <Modal.Body>
        <SigninPaymentGateContainer
          showCloseButton={showCloseButton}
          onClose={handleOnClose}
          containerClass={props.containerClass}
        >
          <SigninPaymentGateContainer.LeftSide className="p-4">
            <>
              <h4>You've reached your free limit for assignments</h4>

              <p className="mt-4">
                Upgrade to Premium to create unlimited assignments.
              </p>

              <p>
                Collect and assess student responses to questions, check for understanding,
                and encourage greater student participation.
              </p>

              <PaywallUpgradeSection onLinkClick={onLinkClick} />
            </>
          </SigninPaymentGateContainer.LeftSide>

          <SigninPaymentGateContainer.RightSide>
            <div className="d-flex justify-content-center h-100">
              <DiscussionToolsOverlay
                clipTitle={props.clipTitle}
                clipThumbnailUrl={props.clipThumbnailUrl}
              />
            </div>
          </SigninPaymentGateContainer.RightSide>
        </SigninPaymentGateContainer>
      </Modal.Body>
    </Modal>
  );
});

export function renderAssignmentPaywallModal(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <AssignmentPaywallModal
      clipTitle={options.clipTitle}
      clipThumbnailUrl={options.clipThumbnailUrl}
      shown={options.shown}
      clipId={options.clipId}
      clipType={options.clipType}
      showCloseButton={options.showCloseButton}
      containerClass={options.containerClass}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}