import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { validate } from "email-validator";

import { postData } from "../global_functions";
import FormErrorsList from "../shared_components/form_errors_list";
import { User } from "../../types";

interface Props {
  onSuccess: (user: User) => void;
}

// Prefixed with "Ch" so it's not a generic name that might clash with something else
export function ChSignIn({ onSuccess }: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState("1");

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const { data } = await postData({
        url: '/signin',
        data: {
          email: email,
          password: password,
          remember_me: rememberMe
        }
      });

      onSuccess(data.user);
    } catch (e) {
      const { data } = e.response;

      setErrors(data.errors);
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  const submitEnabled = (
    email.length > 0 &&
    password.length > 0 &&
    validate(email) &&
    !isLoading
  );

  return (
    <>
      <FormErrorsList errors={errors} />

      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-3 col-md-12">
            <label htmlFor="email">Email Address</label>
            <input id="email" type="email" className="form-control" onChange={e => setEmail(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <label htmlFor="password">Password</label>
            {' '}
            <small><a href="/forgot_password" target="_blank">forgot password?</a></small>

            <input id="password" type="password" className="form-control" onChange={e => setPassword(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <div className="form-check">
              <input id="remember_me" type="checkbox" className="form-check-input" defaultChecked={true} onChange={e => setRememberMe(e.target.checked ? "1" : "0")} />
              <label htmlFor="remember_me" className="form-check-label">Remember me</label>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-dark" disabled={!submitEnabled}>
          Sign in
        </button>
      </form>
    </>
  );
}

export function renderChSignIn(elementId, options) {
  const node = ReactDOM.render(
    <ChSignIn onSuccess={options.onSuccess} />,
    document.getElementById(elementId)
  );

  return node;
}