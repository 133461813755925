import React, { useCallback, useEffect, useRef, useState } from "react";

import { postData } from "../global_functions";

import emailInboxImg from "./email_inbox.png";
import separatorLineImg from "./separator_line.png";

interface Props {
  email: string;
  onEmailVerified: () => void;
  onNavigateBack: () => void;
}

export default function VerifyEmail({ email, onEmailVerified, onNavigateBack }: Props) {
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [resentMessage, setResentMessage] = useState('');

  const isDisabled = (verificationCode.length < 6) || isLoading;
  const mountedRef = useRef(true);

  useEffect(() => {
    sendVerificationCode();

    return () => {
      mountedRef.current = false;
    }
  }, []);

  const sendVerificationCode = useCallback(async () => {
    try {
      await postData({
        url: "/account_verifications/send_code",
        data: { email: email }
      });
    } catch (e) {
      let theError = e.response.data.errors && e.response.data.errors[0];
      if (!theError) theError = "There was an error. Please try again.";

      setError(theError);
    }
  }, []);

  const onResendCode = useCallback(async (e) => {
    e.preventDefault();

    sendVerificationCode();

    setResentMessage("Verification code resent.");
    setTimeout(() => setResentMessage(''), 4000);
  }, []);

  const onBackClicked = useCallback((e) => {
    e.preventDefault();

    onNavigateBack();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) return;

    setIsLoading(true);

    try {
      const { data } = await postData({
        url: `/account_verifications/${verificationCode}/check`,
        data: { email: email }
      });

      if (data.success) {
        onEmailVerified();
      }
    } catch (e) {
      let theError = e.response.data.errors && e.response.data.errors[0];
      if (!theError) theError = "There was an error. Please try again.";

      setError(theError);
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <h4 className="text-center">Check your email</h4>

      <div className="text-center my-4">
        <img src={emailInboxImg} width={218} height={165} alt="email inbox image" />
      </div>

      <div className="text-center my-4">
        <p>We've sent a temporary code to <span className="fw-500">{email}</span>.</p>
         
        {resentMessage &&
          <div className="text-center text-success fw-bold">
            Verification code resent!
          </div>
        }
      </div>

      <form onSubmit={onSubmit}>
        <input type="text" className="form-control py-2" placeholder="Verification code" onChange={e => setVerificationCode(e.target.value)} />

        {error && <div className="text-center text-red mt-3">{error}</div>}

        <button type="submit" className="btn btn-outline-dark w-100 mt-3 py-2" disabled={isDisabled}>
          Create account
        </button>
      </form>

      <div className="d-flex align-items-center justify-content-center text-center my-3">
        <a href="#" onClick={onBackClicked}>Go back</a>

        <img src={separatorLineImg} width={2} height={27} className="mx-4" alt="separator line" />

        <a href="#" onClick={onResendCode}>Resend code</a>
      </div>
    </div>
  );
}