import mixpanel from "./mixpanel_init";

const EVENT_ATTR_START_STR = "data-ch-event-";
const EVENT_NAME_ATTR = "data-ch-event-name";
const EVENT_TYPE_ATTR = "data-ch-event-type";
const DEFAULT_EVENT_TYPE = "click";
const ATTR_REPLACE_REGEX = /[-]/g;

type EventNames = 'click' | 'discussion_room' | 'discussion_room_code' | 'page_view' | 'paywall' | 'playlist' | 'purchase' | 'search' | 'share' | 'signin_gate' | 'standards' | 'verify_email' | 'vocabulary_finder' | 'watched_resource';

interface UserAttributes {
  id: number;
  firstName: string;
  gradeLevelsTaught: Array<string>;
  subjectsTaught: Array<string>;
  organizationId: number;
  organizationName: string;
  isPremium: boolean;
}

export function chAnalyticsTrackEvent(eventName: EventNames, extraData: object = {}, callback: () => void = null) {
  if (typeof(callback) === "function") {
    mixpanel.track(eventName, { ...defaultTrackingData(), ...extraData }, callback);
  } else {
    mixpanel.track(eventName, { ...defaultTrackingData(), ...extraData });
  }
}

export function chAnalyticsIdentify({ id, firstName, gradeLevelsTaught, subjectsTaught, organizationId, organizationName, isPremium }: UserAttributes) {
  mixpanel.identify(`${id}`);
  mixpanel.people.set({
    firstName: firstName,
    gradeLevelsTaught: gradeLevelsTaught,
    subjectsTaught: subjectsTaught,
    organizationId: organizationId,
    organizationName: organizationName,
    isPremium: isPremium
  });
}

export function chAnalyticsTrackElement(element: Element) {
  const attributes = Array.from(element.attributes);
  const data = {};
  let eventName;

  // Loop through all event data attributes
  // Source: https://stackoverflow.com/a/30484867/2430657
  [...attributes].forEach(({ name, value }) => {
    if (name === EVENT_NAME_ATTR) {
      eventName = value;
    } else if (name.startsWith(EVENT_ATTR_START_STR) && name !== EVENT_TYPE_ATTR) {
      data[trackingAttrStr(name)] = value;
    }
  });

  chAnalyticsTrackEvent(eventName, data);
}

export function chAnalyticsListenForEvents() {
  const trackedEventTypes = {
    [DEFAULT_EVENT_TYPE]: "click",
    "dropdown-show": "show.bs.dropdown",
    "dropdown-shown": "shown.bs.dropdown",
    "modal-show": "show.bs.modal",
    "modal-shown": "shown.bs.modal"
  };

  const trackedEventKeys = Object.keys(trackedEventTypes);

  // Add an event handler for each event type
  for (const eventKey of trackedEventKeys) {
    const eventType = trackedEventTypes[eventKey];

    $(document).on(eventType, `[data-ch-event-type='${eventKey}']`, e => {
      chAnalyticsTrackElement(e.currentTarget);
    });
  }

  // Use the default event on elements that don't have an event type specified
  $(document).on(trackedEventTypes[DEFAULT_EVENT_TYPE], "[data-ch-event-name]:not([data-ch-event-type])", e => {
    chAnalyticsTrackElement(e.currentTarget);
  });
}

function defaultTrackingData() {
  const url = new URL(window.location.href);
  const urlParams = Object.fromEntries(url.searchParams);

  const defaultOpts = { path: url.pathname, urlParams: { ...urlParams } };

  return defaultOpts;
}

function trackingAttrStr(attrName: string) {
  return attrName.replace(EVENT_ATTR_START_STR, "").replace(ATTR_REPLACE_REGEX, "_");
}