import React, { createRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { DiscussionQuestionsTable } from "../discussion_questions/discussion_questions_table";
import { PausePromptsTable } from "../pause_prompts/pause_prompts_table";
import { ClipType, DiscussionQuestion, PausePrompt, User } from "../../types";

type SectionKeyOptions = 'clip_questions' | 'pause_prompts';

interface Props {
  defaultSectionKey: SectionKeyOptions;
  canAddPausePrompt: boolean;
  clipId: number;
  clipType: ClipType;
  discussionQuestions: Array<DiscussionQuestion>;
  isPremium: boolean;
  pausePrompts: Array<PausePrompt>;
  user: User;
}

export const DiscussionToolsSection = forwardRef(({ defaultSectionKey = 'clip_questions', ...props }: Props, ref) => {
  const discussionQuestionsTableRef = useRef();
  const pausePromptsTableRef = useRef();

  const onUseDiscussionQuestionAsPausePrompt = (discussionQuestion: DiscussionQuestion) => {
    pausePromptsTableRef.current.setActiveMode("add");
    pausePromptsTableRef.current.updateNewPromptText(discussionQuestion.prompt);
  };

  useImperativeHandle(ref, () => ({
    get discussionQuestionsRef() { return discussionQuestionsTableRef },
    get pausePromptsRef() { return pausePromptsTableRef }
  }));

  return (
    <div>
      <h4 className="my-4" data-discussion-mode-toggle="true">
        Discussion tools
      </h4>

      <div className="mb-3">
        <DiscussionQuestionsTable
          isPremium={props.isPremium}
          discussableType={props.clipType}
          discussableId={props.clipId}
          discussionQuestions={props.discussionQuestions}
          user={props.user}
          ref={discussionQuestionsTableRef}
          onUseAsPausePrompt={onUseDiscussionQuestionAsPausePrompt}
        />
      </div>

      <div>
        <PausePromptsTable
          canAddPausePrompt={props.canAddPausePrompt}
          promptableType={props.clipType}
          promptableId={props.clipId}
          pausePrompts={props.pausePrompts}
          user={props.user}
          ref={pausePromptsTableRef}
        />
      </div>
    </div>
  );
});

export function focusVideoPlayerAddEditMode(mode, highlightClass) {
  // Allow the video editor to be used during add or edit mode
  // Hide other elements to concentrate focus on the video player and the editor
  if (mode == null) {
    document.getElementById("discussion-tools-backdrop")?.setAttribute("hidden", "true");
    document.getElementById("video-player-container")?.classList?.remove(highlightClass);
    document.querySelectorAll("[data-discussion-mode-toggle]:not([data-discussion-mode-active])").forEach(element => element.removeAttribute("hidden"));
  } else {
    document.getElementById("discussion-tools-backdrop")?.removeAttribute("hidden");
    document.getElementById("video-player-container")?.classList?.add(highlightClass);
    document.querySelectorAll("[data-discussion-mode-toggle]:not([data-discussion-mode-active])").forEach(element => element.setAttribute("hidden", "true"));
  }
}

export function renderDiscussionToolsSection(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <DiscussionToolsSection
      defaultSectionKey={options.defaultSectionKey}
      canAddPausePrompt={options.canAddPausePrompt}
      clipId={options.clipId}
      clipType={options.clipType}
      discussionQuestions={options.discussionQuestions}
      isPremium={options.isPremium}
      pausePrompts={options.pausePrompts}
      user={options.user}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}