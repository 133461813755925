import React, { createRef, useEffect, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import pluralize from "pluralize";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { chAnalyticsTrackEvent } from "../analytics";

import styles from "./watch_limit_notice.module.scss";

interface Props {
  clipId: string;
  clipType: string;
  numRemainingClipWatches: number;
  userId?: number;
}

function analyticsArgs(label: string, props: Props, extraData: object = {}) {
  const args = {
    label: label,
    model_id: props.clipId,
    model_type: props.clipType,
    anonymous: props.userId ? false : true,
    num_remaining_watches: props.numRemainingClipWatches,
    ...extraData
  };

  if (props.userId) args["user_id"] = props.userId;

  return args;
}

const WatchLimitNotice = React.forwardRef((props: Props, ref) => {
  const [hidden, setHidden] = useState(false);

  const onHideClick = () => {
    const args = analyticsArgs("watch_limit_notice_hidden", props, { hide_type: "manual" });
    chAnalyticsTrackEvent("watched_resource", args);
    setHidden(true);
  };

  useEffect(() => {
    const args = analyticsArgs("watch_limit_notice_shown", props);
    chAnalyticsTrackEvent("watched_resource", args);
  }, []);

  useImperativeHandle(ref, () => ({
    get isHidden() { return hidden },
    updateHidden: (isHidden: boolean) => {
      const args = analyticsArgs("watch_limit_notice_hidden", props, { hide_type: "automatic" });
      chAnalyticsTrackEvent("watched_resource", args);

      setHidden(isHidden);
    }
  }));

  if (hidden) return null;

  return (
    <div className={classNames(styles.container, "rounded")}>
      <div className="small me-2">
        You have
        {' '}
        {pluralize('clip watch', props.numRemainingClipWatches, true)}
        {' '}
        left for this month. For unlimited watches, upgrade your account
      </div>

      <div className="me-2">
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`watch-limit-notice-${uuid()}-view-tooltip`}>
                A watch is defined as viewing 30 seconds of a clip
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon="info-circle" />
          </OverlayTrigger>

          <span className="sr-only">A watch is defined as viewing 30 seconds of a clip</span>
        </>
      </div>

      <button type="button" className="btn btn-link text-white px-1" onClick={onHideClick}>
        <FontAwesomeIcon icon="times" size="sm" />
        <span className="sr-only">Hide</span>
      </button>
    </div>
  );
});

export function renderWatchLimitNotice(elementId, options) {
  const ref = createRef();

  ReactDOM.render(
    <WatchLimitNotice
      clipId={options.clipId}
      clipType={options.clipType}
      numRemainingClipWatches={options.numRemainingClipWatches}
      userId={options.userId}
      ref={ref}
    />,
    document.getElementById(elementId)
  );

  return ref;
}