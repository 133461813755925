export const TINYMCE_INITIAL_SETTINGS = {
  height: 240,
  menubar: false,
  plugins: [
    'autolink charmap fullscreen hr link preview emoticons',
    'hr lists'
  ],
  toolbar1: "fontselect fontsizeselect bullist numlist indent outdent",
  toolbar2: "bold italic underline strikethrough forecolor backcolor alignleft aligncenter alignright alignjustify emoticons removeformat"
}
