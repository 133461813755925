import React, { useContext } from "react";

import Button from "react-bootstrap/Button";

import { postData } from "../../global_functions";
import DiscussionContext from "../discussion_context";

interface Props {
  queueableType: string;
  video: object;
  buttonBlock: boolean;
}

export default function AddClipButton({ queueableType, video, buttonBlock = false }: Props) {
  const { discussionRoomId, videos, updateQueue, modal: { handleClose } } = useContext(DiscussionContext);

  const onClick = async () => {
    const data = {
      discussion_queue_video: {
        discussion_room_id: discussionRoomId,
        position: videos.length + 1,
        queueable_type: queueableType,
        queueable_id: video.id
      }
    };

    const json = await postData({ url: "/discussion_queue_videos", data });

    updateQueue(json.data);
    handleClose();
  }

  return (
    <Button
      variant="dark"
      onClick={onClick}
      {...(buttonBlock ? { block: true } : {})}
    >
      Select
    </Button>
  );
}
