import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { SearchFilterOption } from '../search_filters';
import classNames from 'classnames';
import styles from '../search_filters.module.scss';

interface DropdownToggleFilerItemProps {
  checked: boolean;
  label: string;
  toggleDescription: string;
  filterName: string;
  onFilterSelected: (filterName: string, value: string | number | Array<SearchFilterOption>) => void;
  disabled?: boolean;
}

export function DropdownToggleFilerItem(props: DropdownToggleFilerItemProps) {
  const [showDropdown, setShowDropdown] = React.useState(false);

  function onToggleDropdown(isShowing: boolean): void {
    setShowDropdown(isShowing);

    if (isShowing)
      return;
  }

  function onFilterSelected() {
    const nextValue = !props.checked
    props.onFilterSelected(props.filterName, nextValue ? "1" : null);
  }

  return (
    <Dropdown onToggle={onToggleDropdown} show={showDropdown}>
      <Dropdown.Toggle variant={!!props.checked ? 'dark' : 'outline-dark'} disabled={props.disabled}>
        {props.label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className={classNames('px-3 py2', styles.customToggleContainer)}>
          <div className='fw-semibold mb-3'>{props.label}</div>
          <div className='d-flex align-items-center justify-content-between'>
            {props.toggleDescription}
            <div
              role="switch"
              className={classNames("toggle-option", styles.customToggle, props.checked ? styles.on : '')}
              onClick={onFilterSelected}
            >
              <span className={styles.handle} />
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}