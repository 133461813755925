import { useMediaQuery } from "react-responsive";
import React from 'react';

export function useBreakPoints() {
  const xs = useMediaQuery({ query: '(max-width: 575.98px)' });
  const sm = useMediaQuery({ query: '(min-width: 576px) and (max-width: 767.98px)' });
  const md = useMediaQuery({ query: '(min-width: 768px) and (max-width: 991.98px)' });
  const lg = useMediaQuery({ query: '(min-width: 992px) and (max-width: 1199.98px)' });
  const xl = useMediaQuery({ query: '(min-width: 1200px) and (max-width: 1439.98px)' });
  const xxl = useMediaQuery({ query: '(min-width: 1440px)' });

  const memoizedBreakpoints = React.useMemo(
    () => ({ xs, sm, md, lg, xl, xxl }),
    [xs, sm, md, lg, xl, xxl]
  );
  return memoizedBreakpoints
}