import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from 'react-select';

import FormErrorsList from "../shared_components/form_errors_list";
import { getCountrySelectOptions } from "../../lib/countries_helper";
import { postData } from "../global_functions";

const DEFAULT_COUNTRY = "United States of America";

interface Props {
  onOrganizationSetupSuccess: (data) => void;
  postUrl: string;
  displayOrganizationType?: boolean;
  organizationTypeOptions?: Array<{
    label: string;
    value: string;
  }>;
}

export default function SetupOrganizationForm({ onOrganizationSetupSuccess, postUrl, displayOrganizationType = false, organizationTypeOptions }: Props) {
  const [organizationType, setOrganizationType] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState(DEFAULT_COUNTRY);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const isEnabled = (
    !isLoading &&
    organizationName.trim().length > 0 &&
    city.trim().length > 0 &&
    state.trim().length > 0 &&
    zipCode.trim().length > 0 &&
    country.trim().length > 0
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const thePostData = {
      name: organizationName,
      city: city,
      state: state,
      zip_code: zipCode,
      country: country
    };

    if (displayOrganizationType && organizationType) {
      thePostData["organization_type"] = organizationType
    }

    try {
      const { data } = await postData({
        url: postUrl,
        data: thePostData
      });

      onOrganizationSetupSuccess(data);
    } catch (e) {
      setErrors(e.response.data.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormErrorsList errors={errors} />

      <Form onSubmit={onSubmit}>
        {displayOrganizationType &&
          <div className="row">
            <div className="mb-3 col-md-12">
              <Form.Label htmlFor="organization_type">Organization Type</Form.Label>
              <Select
                id="organization_type"
                options={organizationTypeOptions}
                closeMenuOnSelect={true}
                onChange={option => setOrganizationType(option.value)}
              />
            </div>
          </div>
        }

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="organization_name">Organization Name</Form.Label>
            <Form.Control id="organization_name" type="text" defaultValue={organizationName} onChange={e => setOrganizationName(e.target.value)} />
          </div>
        </div>

        <div className="row gx-2">
          <div className="mb-3 col-md-5">
            <Form.Label htmlFor="city">City</Form.Label>
            <Form.Control id="city" type="text" defaultValue={city} onChange={e => setCity(e.target.value)} />
          </div>

          <div className="mb-3 col-md-5">
            <Form.Label htmlFor="state">State/Province</Form.Label>
            <Form.Control id="state" type="text" defaultValue={state} onChange={e => setState(e.target.value)} />
          </div>

          <div className="mb-3 col-md-2">
            <Form.Label htmlFor="zip_code">Zip Code</Form.Label>
            <Form.Control id="zip_code" type="text" defaultValue={zipCode} onChange={e => setZipCode(e.target.value)} />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Form.Label htmlFor="country">Country</Form.Label>
            <Select
              id="country"
              options={getCountrySelectOptions()}
              closeMenuOnSelect={true}
              defaultValue={{ label: country, value: country }}
              onChange={option => setCountry(option.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="mb-3 col-md-12">
            <Button type="submit" variant="dark" disabled={!isEnabled}>
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}