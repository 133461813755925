import axios from "axios";

export function retrieveCsrfToken() {
  return document.querySelector('meta[name="csrf-token"]')?.content;
}

// Gets the Rails CSRF token for forms
export function csrfTokenHeaders() {
  return {
    'X-CSRF-Token': retrieveCsrfToken()
  };
}

interface FetchParams {
  url: string;
  params?: object;
}

export function fetchData({ url, params }: FetchParams) {
  return axios.get(url, {
    headers: { accept: "application/json" },
    params,
  });
}

interface APIParams {
  url: string;
  data?: object;
  headers?: object;
}

export function postData({ url, data, headers }: APIParams) {
  return axios.post(url, data, {
    headers: {
      accept: "application/json",
      common: { ...csrfTokenHeaders() },
      ...headers
    },
  });
}

export function patchData({ url, data }: APIParams) {
  return axios.patch(url, data, {
    headers: {
      accept: "application/json",
      common: { ...csrfTokenHeaders() },
    },
  })
}

interface DeleteParams {
  url: string;
  params?: object;
}

export function deleteData({ url }: DeleteParams) {
  return axios.delete(url, {
    headers: {
      accept: "application/json",
      common: { ...csrfTokenHeaders() },
    }
  });
}
