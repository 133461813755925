import { postData } from "../global_functions";
import { chAnalyticsTrackEvent } from "../analytics";

// Should match the value of TIME_CONSIDERED_WATCHED in app/models/watched_resource.rb
const WATCH_TIME_TRIGGER_SECONDS = 30;
const LOGGED_IN_REQUEST_PATH = "/watched_resources";
const LOGGED_OUT_REQUEST_PATH = "/watched_resources/anon_create";

interface Options {
  loggedIn: boolean;
  resourceId: number;
  userId?: number;
}

export class VideoWatchTimeService {
  hasSentRequest: boolean;
  prevTime: number;
  requestPath: string;
  resourceId: number;
  userId: number;
  totalWatchTime: number;
  playerRef;

  constructor(options: Options) {
    this.hasSentRequest = false;
    this.prevTime = 0;
    this.requestPath = options.loggedIn ? LOGGED_IN_REQUEST_PATH : LOGGED_OUT_REQUEST_PATH;
    this.resourceId = options.resourceId;
    this.userId = options.userId;
    this.totalWatchTime = 0;
  }

  onTimeUpdate(time: number) {
    if (!this.playerRef || this.hasSentRequest) return;

    // Current video time - previous time
    const timeDiff = Math.abs(time - this.prevTime);

    this.prevTime = time;

    // The user probably seeked if the time difference is greater than a second
    // Ignore this in the total watch time calculation
    if (timeDiff >= 1) return;

    this.totalWatchTime += timeDiff;

    if (this.totalWatchTime < WATCH_TIME_TRIGGER_SECONDS) return;

    postData({
      url: this.requestPath,
      data: {
        watched_resource: {
          resource_id: this.resourceId,
          watch_length: this.totalWatchTime
        }
      }
    }).then(response => {
      const data = response.data;

      if (!data.errors) {
        chAnalyticsTrackEvent("watched_resource", {
          user_id: this.userId,
          model_id: this.resourceId,
          model_type: "Resource",
          watched_resource_id: data.watched_resource?.id,
          anonymous: data.watched_resource_count ? true : false
        });
      }
    });

    this.hasSentRequest = true;
  }

  register(playerRef) {
    this.playerRef = playerRef;
  }
}