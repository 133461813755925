import React, { useState, useEffect } from "react";
import ReactDOM, { unmountComponentAtNode } from "react-dom";
import { Row, Col, Button, Collapse, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  DiscussionProps,
  DiscussionSection,
  Participants,
  subscribeToChannel,
  ClassResponses,
  createVideoPlayerResource,
  fetchPausePrompts,
  PresentationPausePrompt,
} from "./discussion_helper";

import { fetchData } from "../global_functions";
import { markersFromPausePrompts } from "../video_player/functions";
import ContainerWithHeading from "../shared_components/container_with_heading";
import UpgradeButton from "../shared_components/upgrade_button";
import { VideoPausePromptService } from "../video_player/video_pause_prompt_service";
import { VideoSubtitlesService } from "../video_player/video_subtitles_service"
import { VideoProfanitySkippingService } from "../video_player/video_profanity_skipping_service"
import { renderVideoPlayer } from "../video_player/video_player";

import "./teacher_discussion.scss";
import { chAnalyticsTrackEvent } from "../analytics";

function TeacherDiscussion({ title, roomCodeId, roomCode, objective, name, discussionRoomId, isPaidOrTrialUser, id, userPreferences, hideResponses }: DiscussionProps) {
  const [userList, setUserList] = useState([]);
  const [queueVideos, setQueueVideos] = useState([]);
  const [classResponses, setClassResponses] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentPrompt, setCurrentPrompt] = useState({});
  const [commentsData, setCommentsData] = useState({});
  const [responseIndicators, setResponseIndicators] = useState(new Set());
  const [pausePrompts, setPausePrompts] = useState([]);
  const [currentPlayerRef, setCurrentPlayerRef] = useState(null);
  const [subtitleSettingsOpen, setSubtitleSettingsOpen] = useState(false);
  const [studentResponsesShown, setStudentResponsesShown] = useState(false);
  const [isUsingEduPlayer, setIsUsingEduPlayer] = useState(window.chUseEduPlayer);

  function receivedCallback(data) {
    switch (data.message.event_type) {
      case "join_list":
        setUserList(data.message.users);
        break;
      case "pause_prompt_new_comment":
        // callback cant seem to access up-to-date currentPrompt so using useEffect instead
        setCommentsData(data.message);
        break;
    }
  }

  const isStudent = false;
  const videoContainer = "teacher-video-container";
  const videoPausePromptContainer = `${videoContainer}-current-question`;

  // Subtitles and profanity toggles
  const subtitlesToggleElementId = "user-subtitles-preference";
  const censorToggleElementId = "user-subtitles-censor-profanity-preference";
  const skipProfanityToggleElementId = "user-skip-profanity-preference";

  useEffect(() => {
    const userJson = { isStudent, name };
    subscribeToChannel({ roomCode, userJson, receivedCallback });

    const getQueueVideos = async () => {
      const params = { discussion_room_id: discussionRoomId };
      const { data } = await fetchData({ url: "/discussion_queue_videos", params });

      // index and ended are used to gray out watched videos
      const moddedQueueVideos = data.queue_list.map((video, index) => (
        { ...video, index, ended: false }
      ))

      setQueueVideos(moddedQueueVideos);

      if (moddedQueueVideos.length > 0) {
        setCurrentVideo(moddedQueueVideos[0]);
      }
    };

    getQueueVideos();
  }, []);

  useEffect(() => {
    const renderVideoAndFetchPrompts = async () => {
      const { data } = await fetchPausePrompts(currentVideo, id);
      const pausePrompts = data.pause_prompts;
      setPausePrompts(pausePrompts);

      const pausePromptService = new VideoPausePromptService({
        isHomeworkMode: false,
        isPaidOrTrialUser: isPaidOrTrialUser,
        pausePrompts: pausePrompts,
        mode: "PRESENT",
        pausePromptContainerId: videoPausePromptContainer,
        showPausePromptHeader: false,
        onPausePromptShow: (prompt) => {
          const thePlayer = pausePromptService.playerRef.current;

          // Exit fullscreen mode
          if (thePlayer.fullscreenActive) {
            thePlayer.exitFullscreen();
          }

          setCurrentPrompt(prompt);

          // reset class responses when new prompt arrives
          setStudentResponsesShown(false);
          setClassResponses([]);
          setResponseIndicators(new Set());
          App.cable.subscriptions.subscriptions[0].pausePromptShown({ pause_prompt_id: prompt.id });

          chAnalyticsTrackEvent("discussion_room", {
            model_id: discussionRoomId,
            model_type: "DiscussionRoom",
            discussion_room_code_id: roomCodeId,
            prompt_id: prompt.id,
            prompt_type: "PausePrompt",
            label: "prompt_appeared"
          });
        },
        onPausePromptFinish: () => {
          setCurrentPrompt({});
        }
      });

      const subtitlesService = new VideoSubtitlesService({
        censoredToggleElementId: censorToggleElementId,
        toggleElementId: subtitlesToggleElementId,
        mode: "TOGGLE",
        subtitles: currentVideo.queueable.subtitles
      });

      const profanitySkippingService = new VideoProfanitySkippingService({
        toggleElementId: skipProfanityToggleElementId,
        profanityMoments: currentVideo.queueable.profanity_moments,
        mode: "TOGGLE"
      });

      const playerRef = renderVideoPlayer({
        elementId: videoContainer,
        useEduPlayer: isUsingEduPlayer,
        resource: createVideoPlayerResource(currentVideo.queueable),
        subtitlesService: subtitlesService,
        onTimeUpdate: (time) => {
          pausePromptService.onTimeUpdate(time);
          profanitySkippingService.onTimeUpdate(time);
        },
        onEnded: (player) => {
          // Gray out the video after it's finished playing
          const copyQueueVideos = queueVideos.slice();
          copyQueueVideos[currentVideo.index].ended = true;
          setQueueVideos(copyQueueVideos);
        },
        onEduPlayerUnavailable: function (thePlayerRef) {
          // removes current mounted video and mounts clicked video
          unmountComponentAtNode(document.getElementById(videoContainer));
          setIsUsingEduPlayer(false);
        },
        markers: markersFromPausePrompts(pausePrompts)
      });

      pausePromptService.register(playerRef);
      profanitySkippingService.register(playerRef);

      setCurrentPlayerRef(playerRef);
    }

    if (currentVideo) {
      renderVideoAndFetchPrompts();
    }
  }, [currentVideo, isUsingEduPlayer]);

  useEffect(() => {
    // since null === null, check if key present first before comparing
    // make sure comments are from the same pause prompt
    if (commentsData.pause_prompt_id && commentsData.pause_prompt_id == currentPrompt.id) {
      setClassResponses(commentsData.all_comments);
      setResponseIndicators(new Set([...responseIndicators, commentsData.student_uuid]));
    }
  }, [commentsData]);

  const onChangeVideoClick = (video) => () => {
    // Close the existing pause prompt to fix a bug in which multiple pause prompts are active
    if (Object.keys(currentPrompt).length > 0) {
      currentPrompt?.close();
    }

    // removes current mounted video and mounts clicked video
    unmountComponentAtNode(document.getElementById(videoContainer));

    // resets prompt and responses when clicking new videos
    setClassResponses([]);
    setCurrentPrompt({});

    setCurrentVideo(video);
    setIsUsingEduPlayer(window.chUseEduPlayer);
  };

  const onToggleSubtitleSettings = () => {
    setSubtitleSettingsOpen(!subtitleSettingsOpen);
  };

  const onShowStudentResponsesClick = () => {
    setStudentResponsesShown(true);
  };

  const hiddenClassName = (currentPrompt && Object.keys(currentPrompt).length > 0) ? "" : "invisible";

  return (
    <Row>
      <Col lg={10}>
        <DiscussionSection title={title} objective={objective} roomCode={roomCode} isStudent={isStudent}>
          <Row>
            <Col lg={6} md={12}>
              <div id={videoContainer} className={`${videoContainer} mb-2`}></div>

              <Button variant="outline-dark" onClick={onToggleSubtitleSettings} className="mb-3">
                <FontAwesomeIcon icon="closed-captioning" size="lg" />
                <span className="sr-only">Subtitles settings</span>
              </Button>

              <Collapse in={subtitleSettingsOpen}>
                <div className="border rounded bg-white px-3 py-2 mb-3">
                  <div className="d-flex align-items-center mb-2">
                    <Form.Check
                      id={subtitlesToggleElementId}
                      type="switch"
                      label="Subtitles"
                      className="me-2"
                      defaultChecked={userPreferences?.subtitlesOn}
                    />

                    <OverlayTrigger placement="top"
                      overlay={
                        <Tooltip>Subtitles generated automatically.</Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <FontAwesomeIcon icon="info-circle" size="sm" forwardedRef={ref} {...triggerHandler} />
                      )}
                    </OverlayTrigger>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <Form.Check
                      id={censorToggleElementId}
                      type="switch"
                      label="Censor profanity"
                      className="me-2"
                      defaultChecked={userPreferences?.censorProfanity}
                    />

                    <OverlayTrigger placement="top"
                      overlay={
                        <Tooltip>Replaces all profanity in subtitles with censored text.</Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <FontAwesomeIcon icon="info-circle" size="sm" forwardedRef={ref} {...triggerHandler} />
                      )}
                    </OverlayTrigger>
                  </div>

                  <div className="d-flex align-items-center">
                    <Form.Check
                      id={skipProfanityToggleElementId}
                      type="switch"
                      label="Skip profanity"
                      className="me-2"
                      defaultChecked={userPreferences?.skipProfanity}
                    />

                    <OverlayTrigger placement="top"
                      overlay={
                        <Tooltip>Automatically skips past moments in the clip containing profanity.</Tooltip>
                      }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <FontAwesomeIcon icon="info-circle" size="sm" forwardedRef={ref} {...triggerHandler} />
                      )}
                    </OverlayTrigger>
                  </div>
                </div>
              </Collapse>

              <ContainerWithHeading header={"Question Times"} iconName="stopwatch">
                <div className="clip-pause-prompts">
                  {pausePrompts.map((pausePrompt, index) =>
                    <PresentationPausePrompt
                      key={pausePrompt.id}
                      pausePrompt={pausePrompt}
                      playerRef={currentPlayerRef}
                      isCurrentPrompt={pausePrompt.id === currentPrompt?.id}
                    />
                  )}
                </div>
              </ContainerWithHeading>

              <ContainerWithHeading header={"Clips to Show"} iconName="video">
                <div className="d-flex flex-wrap clips-to-show">
                  {queueVideos.map(video => {
                    // active-video creates red borders around video
                    // video-ended grays out video
                    const active = currentVideo?.id === video.id ? "active-video" : "";
                    const ended = video.ended ? "video-ended" : "";
                    const clip = video.queueable;

                    const thumbnailUrl = clip.video_file ? clip.video_file.thumbnail_url : clip.thumbnail_url;

                    return (
                      <div
                        className={`queue-video me-1 mb-1 ${active}`}
                        key={video.id}
                        onClick={onChangeVideoClick(video)}>
                        <img src={thumbnailUrl} />
                        <div className={ended}></div>
                      </div>
                    );
                  }
                  )}
                </div>
              </ContainerWithHeading>
            </Col>

            <Col lg={6} md={12}>
              <ContainerWithHeading extraContainerClasses={hiddenClassName} header={"Current question"} iconName="question-circle">
                <div id={videoPausePromptContainer} className={videoPausePromptContainer} />
              </ContainerWithHeading>

              <ContainerWithHeading extraContainerClasses={hiddenClassName} header={`Responses (${classResponses.length})`} iconName="poll">
                {!studentResponsesShown &&
                  <div className="text-center">
                    <Button variant="link" onClick={onShowStudentResponsesClick}>
                      Show responses
                    </Button>
                  </div>
                }

                {studentResponsesShown &&
                  <ClassResponses classResponses={classResponses} isTeacher={true} hideResponses={hideResponses} />
                }
              </ContainerWithHeading>
            </Col>
          </Row>
        </DiscussionSection>
      </Col>

      <Col lg={2} className="px-0">
        <Participants
          userList={userList}
          isStudent={isStudent}
          responseIndicators={responseIndicators}
          containerClass="border rounded bg-white"
          id={id}
        />
      </Col>
    </Row>
  );
}

export function renderTeacherDiscussion(options: DiscussionProps) {
  ReactDOM.render(
    <TeacherDiscussion
      title={options.title}
      roomCodeId={options.roomCodeId}
      roomCode={options.roomCode}
      objective={options.objective}
      name={options.name}
      discussionRoomId={options.discussionRoomId}
      isPaidOrTrialUser={options.isPaidOrTrialUser}
      id={options.id}
      userPreferences={options.userPreferences}
      hideResponses={options.hideResponses}
    />,
    document.getElementById(options.elementId)
  );
}
