import React from 'react';

import { useIsComponentMounted } from './use_is_component_mounted';
import { FunctionHelper } from '../utils/function_helper';
import { ObjectHelper } from '../utils/object_helper';

interface HoverOptions {
  /**
   * Classes to be applied to the target element on hover. Useful if you want to style the element on hover
   * after a delay (controlled by the `timeout` option below)
   */
  className?: string;

  /**
   * Style attribute to be applied to the target element on hover. Should only be used 
   */
  style?: Record<string, string>;

  /**
   * A delay between when the user actually hovers on the element, and when the hook returns `true` for `isHovering`.
   * This can be used to determine whether a user has actually intended for a hover event on an element to be
   * triggered or not. Defaults to 0.
   */
  delay?: number;
}

const defaultOptions: HoverOptions = {
  className: '',
  delay: 0,
  style: null
};

interface MousePosition {
  screenX: number;
  screenY: number;
}


function setHoveringStyles<T extends HTMLElement>(
  ref: React.MutableRefObject<T>,
  options: HoverOptions
): void {
  if (!ref.current)
    return;

  if (options.className)
    ref.current.classList?.add(options.className);

  if (options.style) {
    Object.keys(options.style).forEach((styleName: any) => {
      ref.current.style[styleName] = options.style[styleName];
    });
  }
}

function unSetHoveringStyles<T extends HTMLElement>(
  ref: React.MutableRefObject<T>,
  options: HoverOptions
): void {
  if (!ref.current)
    return;

  if (options.className)
    ref.current.classList?.remove(options.className);

  if (options.style) {
    Object.keys(options.style).forEach((styleName: any) => {
      ref.current.style[styleName] = null;
    });
  }
}

/**
 * This hook detects hover events on an element.
 * This should only be used where CSS hover events won't suffice for what you are trying to achieve.
 * It will trigger after the delay regardless of pointer movement within the container, if you want
 * it to trigger only after the pointer has stopped moving, consider the `useHoverIntent` hook.
 */
export function useHover<T extends HTMLElement>(
  options: HoverOptions = defaultOptions
): [React.MutableRefObject<T>, boolean] {
  const isMounted = useIsComponentMounted();
  const [cachedOptions, setCachedOptions] = React.useState(options);
  const [isHovering, setIsHovering] = React.useState(false);

  const ref = React.useRef<T>(null);

  React.useEffect(() => {
    if (ObjectHelper.isEqual(options, cachedOptions))
      return;

    setCachedOptions(options);
  }, [options]);

  const triggerHover = FunctionHelper.throttle(() => {
    setHoveringStyles(ref, cachedOptions);
    if (isMounted.current)
      setIsHovering(true);
  }, cachedOptions.delay, { leading: false });

  const handleMouseOut = () => {
    if (FunctionHelper.isFunction(triggerHover.cancel))
      triggerHover.cancel();

    unSetHoveringStyles(ref, cachedOptions);

    if (isMounted.current)
      setIsHovering(false);
  };

  React.useLayoutEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mouseenter', triggerHover);
      node.addEventListener('mouseleave', handleMouseOut);

      return () => {
        node.removeEventListener('mouseenter', triggerHover);
        node.removeEventListener('mouseleave', handleMouseOut);
      };
    }
  }, [ref.current, cachedOptions]);

  return [ref, isHovering];
}