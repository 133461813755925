import React, { ChangeEvent, useState } from "react";
import ReactDOM from "react-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import pluralize from "pluralize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fetchData, postData } from "../global_functions";

interface AgeRange {
  name: string;
  value: number;
}

interface AddVetterModalProps {
  ageRanges: Array<AgeRange>;
  resourceId: number;
}

export function AddVetterModal({ ageRanges, resourceId }: AddVetterModalProps) {
  const [isShown, setShown] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [vettersCount, setVettersCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchAgeRanges, setSearchAgeRanges] = useState([]);

  const ageRangeOptions = ageRanges.map(ageRange => ({ label: ageRange.name, value: ageRange.value }));

  const handleShow = () => setShown(true);
  const handleClose = () => setShown(false);

  const onSearchQueryChange = (e) => {
    const newSearchQuery = e.target.value;
    const isDisabled = (newSearchQuery.length < 1);

    setSearchQuery(e.target.value);
    setDisabled(isDisabled);
  };

  const onAgeRangesChange = (optionsAry) => {
    const theOptionsAry = optionsAry || [];
    const ageRangeValues = theOptionsAry.map(option => option.value);

    setSearchAgeRanges(ageRangeValues);
  };

  const onCalculateVettersCount = async (e) => {
    e.preventDefault();

    if (searchQuery.length < 1) return;

    const response = await fetchData({
      url: "/resource_vets/calculate_vetters.json",
      params: {
        q: searchQuery,
        age_range_values: searchAgeRanges,
        resource_id: resourceId
      }
    });

    const newVettersCount = response.data.vetters_count;

    setVettersCount(newVettersCount);
    setSendDisabled(newVettersCount < 1);
  };

  const onSendToVetters = async (e) => {
    e.preventDefault();

    setSendDisabled(true);

    try
    {
      const response = await postData({
        url: "/resource_vets.json",
        data: {
          q: searchQuery,
          age_range_values: searchAgeRanges,
          resource_id: resourceId
        }
      });

      if (response.data.created_vets > 0) {
        // Reload the page
        window.location.reload();
      }
    }
    catch
    {

    }

    setSendDisabled(false);
  };

  return (
    <>
      <Button variant="outline-dark" onClick={handleShow}>
        <FontAwesomeIcon icon="check" className="me-1" />
        Ask Vetters
      </Button>

      <Modal show={isShown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ask Vetters</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={onCalculateVettersCount}>
            <Form.Group>
              <Form.Label htmlFor="grade_levels">
                Grade Levels
              </Form.Label>

              <Select
                id="vetters_grade_levels"
                defaultValue={searchAgeRanges}
                onChange={onAgeRangesChange}
                options={ageRangeOptions}
                isMulti
                closeMenuOnSelect={false}
                isDisabled={false}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor="vetters_search_query">
                Subjects Taught (Separate by comma)
              </Form.Label>

              <Form.Control
                id="vetters_search_query"
                as="input"
                onChange={onSearchQueryChange}
                value={searchQuery}
              />
            </Form.Group>

            <div className="d-flex align-items-center">
              <Button
                className="me-2"
                disabled={disabled}                
                type="submit"
                variant="outline-dark"
              >
                Calculate Vetter Count
              </Button>

              <div>
                {pluralize('vetter', vettersCount, true)}
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="dark" onClick={onSendToVetters} disabled={sendDisabled}>
            Send to {pluralize('Vetter', vettersCount, true)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function renderAddVettersModal(elementId, options) {
  const node = ReactDOM.render(
    <AddVetterModal
      ageRanges={options.ageRanges}
      resourceId={options.resourceId}
    />,
    document.getElementById(elementId)
  );

  return node;
}