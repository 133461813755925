import React from "react";
import classNames from "classnames";

import { Resource } from "../../types";

import { timestampFromSeconds } from "../time_functions";

import styles from "./resource_thumbnail.module.scss";

interface ResourceThumbnailProps {
  resource: Pick<Resource, "thumbnail_url" | "clip_length" | "title">;
  extraClasses?: string;
  showClipLength?: boolean;
}

export function ResourceThumbnail({ resource, extraClasses, showClipLength }: ResourceThumbnailProps) {
  return (
    <>
      {resource.thumbnail_url ?
        <img
          src={resource.thumbnail_url}
          alt={`${resource.title} thumbnail`}
          className={classNames(styles.resourceThumbnail, "rounded-3", extraClasses)}
        />
        :
        <div className={classNames("search-thumbnail-error", extraClasses)}>
          <div>No Preview available</div>
        </div>
      }
      {showClipLength && resource.clip_length && (
        <span className={classNames("resource-thumbnail-length ps-1")}>
          {timestampFromSeconds(resource.clip_length)}
        </span>
      )}
    </>
  )
}
