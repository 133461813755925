/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { unmountComponentAtNode } from 'react-dom';

// Build a FontAwesome icon library to use throughout the app. Add icons below as needed
// Source: https://fontawesome.com/v5/docs/web/use-with/react#using-icons-via-global-use
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowLeft,
  faArrowRight,
  faBolt,
  faBook,
  faCaretDown,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faClipboardList,
  faClock,
  faClosedCaptioning,
  faCog,
  faComment,
  faCommentDots,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faGlobeAmericas,
  faHourglass,
  faInbox,
  faInfoCircle,
  faLink,
  faMagic,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faPoll,
  faQuestionCircle,
  faRedoAlt,
  faSearch,
  faShareSquare,
  faSignInAlt,
  faStopwatch,
  faTimes,
  faTrash,
  faUserCircle,
  faUsers,
  faVideo,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowLeft,
  faArrowRight,
  faBolt,
  faBook,
  faCaretDown,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faClipboardList,
  faClock,
  faClosedCaptioning,
  faCog,
  faComment,
  faCommentDots,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faGlobeAmericas,
  faHourglass,
  faInbox,
  faInfoCircle,
  faLink,
  faMagic,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faPoll,
  faQuestionCircle,
  faRedoAlt,
  faSearch,
  faShareSquare,
  faSignInAlt,
  faStopwatch,
  faTimes,
  faTrash,
  faUserCircle,
  faUsers,
  faVideo,
  faWindowClose,
);

import { renderVideoPlayer } from "../src/video_player/video_player";
import { VideoPausePromptService } from "../src/video_player/video_pause_prompt_service";
import { VideoProfanitySkippingService } from "../src/video_player/video_profanity_skipping_service";
import { VideoSubtitlesService } from "../src/video_player/video_subtitles_service";
import { VideoWatchTimeService } from "../src/video_player/video_watch_time_service";
import { renderUserClipsSearchYouTubeForm } from "../src/user_clips/search_youtube_form";
import { renderDirectFileUpload } from "../src/direct_file_uploads";
import { renderAddVettersModal } from "../src/resource_vets/add_vetter_modal";
import { renderDiscussion } from "../src/discussion/discussion";
import { renderTeacherDiscussion } from "../src/discussion/teacher_discussion";
import { renderStudentDiscussion } from "../src/discussion/student_discussion";
import { renderJoinDiscussion } from "../src/discussion/join_discussion";
import { renderClipEmbedCode } from "../src/embeds/clip_embed_code";
import { renderAddStandardsTypeahead } from "../src/standards/add_standards_typeahead";
import { renderViewEducatorsModal } from "../src/organizations/view_educators_modal";
import { renderUpdateUserDetailsForm } from "../src/users/update_user_details_form";
import { renderMultiSelectFormGroup } from '../src/users/multi_select_form_group';
import { renderVideoPredictions } from '../src/video_predictions/video_prediction';
import { renderBrowseClipsByStandards } from '../src/standards/browse_clips_by_standards';
import { renderAddQueueableModal } from '../src/discussion/add_queueable_modal';
import { renderViewAssignmentsTable } from '../src/assignments/assignments_table';
import { renderStudentAssignmentsTable } from '../src/assignments/student_assignments_table';
import { renderNewAssignmentForm } from '../src/assignments/new_assignment_form';
import { renderEditAssignmentForm } from '../src/assignments/edit_assignment_form';
import { renderDuplicateCourseButton } from '../src/courses/duplicate_course_button';
import { renderAssignmentItemComment } from '../src/assignment_items/assignment_item_comment';
import { renderNewAssignmentItemComment } from '../src/assignment_items/new_assignment_item_comment';
import { renderVideoQuestionsList } from "../src/video_questions/video_questions_list";
import { renderSubmitAssignmentButton } from "../src/assignments/submit_assignment_button";
import { renderAssignmentsReport } from "../src/assignments/assignments_report";
import { renderNewQuoteForm } from "../src/quotes/new_quote_form";
import { renderShareQuoteForm } from "../src/quotes/share_quote_form";
import { renderOrderQuoteForm } from "../src/quotes/order_quote_form";
import { renderAwsPurchaseSetup } from '../src/aws_marketplace/aws_purchase_setup';
import { renderChSignIn } from "../src/sessions/signin";
import { renderChSignUp } from "../src/users/signup";
import { renderGoogleSignInButton } from '../src/signin/google_sign_in_button';
import { renderCleverSignInButton } from '../src/signin/clever_sign_in_button';
import { renderOrganizationsTypeahead } from '../src/organizations/find_organization_typeahead';
import { renderAddUsersTypeahead } from '../src/licenses/add_users_typeahead';
import { renderUserClipsImportCsvForm } from '../src/user_clips/import_csv_form';
import { renderSearchFilters } from '../src/search/search_filters';
import { renderSearchFiltersMobile } from '../src/search/search_filters_mobile';
import { renderDiscussionToolsSection } from '../src/resources/discussion_tools_section';
import { renderDiscussionToolsSectionLoggedOut } from '../src/resources/discussion_tools_section_logged_out';
import { renderDiscussionQuestionsTable } from '../src/discussion_questions/discussion_questions_table';
import { renderPausePromptsTable } from '../src/pause_prompts/pause_prompts_table';
import { renderStandardsTable } from '../src/resources/standards_table';
import { renderUpgradeButton } from '../src/shared_components/upgrade_button';
import { renderSignInPromptButton } from '../src/shared_components/sign_in_prompt_button';
import { renderChSwiper } from '../src/shared_components/ch_swiper';
import { renderClipSigninGate } from '../src/signin_gates/clip_signin_gate';
import { renderAssignmentPaywallModal } from '../src/paywalls/assignment_paywall_modal';
import { renderBrowseGenresPaywall } from '../src/paywalls/browse_genres_paywall';
import { renderVocabularyFinderSigninGate } from '../src/signin_gates/vocabulary_finder_signin_gate';
import { renderGoogleClassroomRosteringPaywallModal } from '../src/paywalls/google_classroom_rostering_paywall_modal';
import { renderWatchedResourcePaywall } from '../src/paywalls/watched_resource_paywall';
import { renderWatchLimitNotice } from '../src/watched_resources/watch_limit_notice';
import { renderVideoTranscript } from '../src/resources/video_transcript';
import { renderInviteUsersField } from '../src/shared_components/invite_users_field';
import { renderHomepageSearch } from '../src/search/homepage_search';
import { renderResourcesSlider } from '../src/shared_components/resources_slider/resources_slider';

// Functions
import { replaceYoutubeEduPlayer } from '../src/video_player/functions';
import { chAnalyticsIdentify, chAnalyticsListenForEvents, chAnalyticsTrackElement, chAnalyticsTrackEvent } from '../src/analytics';
import { chIsMobileBrowser } from '../lib/device_detect';
import { stringify as nodeQsStringify } from "qs";

// Analytics
(window as any).chAnalyticsIdentify = chAnalyticsIdentify;
(window as any).chAnalyticsListenForEvents = chAnalyticsListenForEvents;
(window as any).chAnalyticsTrackElement = chAnalyticsTrackElement;
(window as any).chAnalyticsTrackEvent = chAnalyticsTrackEvent;

// Components
(window as any).renderVideoPlayer = renderVideoPlayer;
(window as any).VideoPausePromptService = VideoPausePromptService;
(window as any).VideoProfanitySkippingService = VideoProfanitySkippingService;
(window as any).VideoSubtitlesService = VideoSubtitlesService;
(window as any).VideoWatchTimeService = VideoWatchTimeService;
(window as any).renderUserClipsSearchYouTubeForm = renderUserClipsSearchYouTubeForm;
(window as any).renderDirectFileUpload = renderDirectFileUpload;
(window as any).renderAddVettersModal = renderAddVettersModal;
(window as any).renderDiscussion = renderDiscussion;
(window as any).renderTeacherDiscussion = renderTeacherDiscussion;
(window as any).renderStudentDiscussion = renderStudentDiscussion;
(window as any).renderJoinDiscussion = renderJoinDiscussion;
(window as any).renderClipEmbedCode = renderClipEmbedCode;
(window as any).renderAddStandardsTypeahead = renderAddStandardsTypeahead;
(window as any).renderViewEducatorsModal = renderViewEducatorsModal;
(window as any).renderUpdateUserDetailsForm = renderUpdateUserDetailsForm;
(window as any).renderMultiSelectFormGroup = renderMultiSelectFormGroup;
(window as any).renderVideoPredictions = renderVideoPredictions;
(window as any).renderBrowseClipsByStandards = renderBrowseClipsByStandards;
(window as any).renderAddQueueableModal = renderAddQueueableModal;
(window as any).renderViewAssignmentsTable = renderViewAssignmentsTable;
(window as any).renderStudentAssignmentsTable = renderStudentAssignmentsTable;
(window as any).renderNewAssignmentForm = renderNewAssignmentForm;
(window as any).renderEditAssignmentForm = renderEditAssignmentForm;
(window as any).renderDuplicateCourseButton = renderDuplicateCourseButton;
(window as any).renderAssignmentItemComment = renderAssignmentItemComment;
(window as any).renderNewAssignmentItemComment = renderNewAssignmentItemComment;
(window as any).renderVideoQuestionsList = renderVideoQuestionsList;
(window as any).renderSubmitAssignmentButton = renderSubmitAssignmentButton;
(window as any).renderAssignmentsReport = renderAssignmentsReport;
(window as any).renderNewQuoteForm = renderNewQuoteForm;
(window as any).renderShareQuoteForm = renderShareQuoteForm;
(window as any).renderOrderQuoteForm = renderOrderQuoteForm;
(window as any).renderAwsPurchaseSetup = renderAwsPurchaseSetup;
(window as any).renderChSignIn = renderChSignIn;
(window as any).renderChSignUp = renderChSignUp;
(window as any).renderGoogleSignInButton = renderGoogleSignInButton;
(window as any).renderCleverSignInButton = renderCleverSignInButton;
(window as any).renderOrganizationsTypeahead = renderOrganizationsTypeahead;
(window as any).renderAddUsersTypeahead = renderAddUsersTypeahead;
(window as any).renderUserClipsImportCsvForm = renderUserClipsImportCsvForm;
(window as any).renderSearchFilters = renderSearchFilters;
(window as any).renderSearchFiltersMobile = renderSearchFiltersMobile;
(window as any).renderDiscussionToolsSection = renderDiscussionToolsSection;
(window as any).renderDiscussionToolsSectionLoggedOut = renderDiscussionToolsSectionLoggedOut;
(window as any).renderDiscussionQuestionsTable = renderDiscussionQuestionsTable;
(window as any).renderPausePromptsTable = renderPausePromptsTable;
(window as any).renderStandardsTable = renderStandardsTable;
(window as any).renderUpgradeButton = renderUpgradeButton;
(window as any).renderSignInPromptButton = renderSignInPromptButton;
(window as any).renderChSwiper = renderChSwiper;
(window as any).renderClipSigninGate = renderClipSigninGate;
(window as any).renderAssignmentPaywallModal = renderAssignmentPaywallModal;
(window as any).renderBrowseGenresPaywall = renderBrowseGenresPaywall;
(window as any).renderVocabularyFinderSigninGate = renderVocabularyFinderSigninGate;
(window as any).renderGoogleClassroomRosteringPaywallModal = renderGoogleClassroomRosteringPaywallModal;
(window as any).renderWatchedResourcePaywall = renderWatchedResourcePaywall;
(window as any).renderWatchLimitNotice = renderWatchLimitNotice;
(window as any).renderVideoTranscript = renderVideoTranscript;
(window as any).renderInviteUsersField = renderInviteUsersField;
(window as any).renderHomepageSearch = renderHomepageSearch;
(window as any).renderChResourcesSlider = renderResourcesSlider;

// Functions
(window as any).unmountComponentAtNode = unmountComponentAtNode;
(window as any).replaceYoutubeEduPlayer = replaceYoutubeEduPlayer;
(window as any).chIsMobileBrowser = chIsMobileBrowser;
(window as any).nodeQsStringify = nodeQsStringify;