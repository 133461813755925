import React, { createRef, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { v4 as uuid } from "uuid";

import { fetchData } from "../global_functions";
import { Organization } from "../../types";

import 'react-bootstrap-typeahead/css/Typeahead.css';

const RESULTS_PER_PAGE = 10;
const SEARCH_PATH = "/search/school_search";

interface Props {
  htmlInputName: string;
  multiple?: boolean;
  onSelected: (results) => void;
  defaultSelectedResults?: Array<Organization>;
  excludedIds?: Array<number>;
  renderMenuItemFunc?: (organization: Organization) => void;
  extraParams?: { include_educator_count: boolean };
}

async function makeRequest(query, excludedIds = [], extraParams = {}) {
  let data;

  try {
    const response = await fetchData({
      url: SEARCH_PATH,
      params: {
        q: query,
        "filters[limit]": RESULTS_PER_PAGE,
        "filters[excluded_ids]": excludedIds ?? [],
        ...extraParams
      }
    });

    data = response.data;
  } catch (e) {
    data = e.response.data;
  }

  return data;
}

function renderHtmlInput(selectedResults, htmlInputName, multiple) {
  const selectedOptionsHtml = selectedResults.map((result, index) => {
    return (<option key={index} value={result.id} selected></option>);
  });

  return (
    <select name={htmlInputName} multiple={multiple} hidden>
      {selectedOptionsHtml}
    </select>
  );
}

function defaultMenuItemFunc(organization: Organization) {
  return (
    <div key={organization.id}>
      <div className="fw-bold">
        {organization.name}
        {' '}
        <span className="small text-muted">{organization.display_type}</span>
      </div>

      <div>
        {organization.city}
        {organization.state ? `, ${organization.state}` : ""}
      </div>

      {organization.country &&
        <div>{organization.country}</div>
      }
    </div>
  );
}

export const OrganizationsTypeahead = React.forwardRef((props: Props, ref) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedResults, setSelectedResults] = useState(props.defaultSelectedResults ?? []);
  const typeaheadId = `organization-typeahead-${uuid()}`;

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const onSelected = (results) => {
    setSelectedResults(results);
    props.onSelected?.(results);
  };

  const handleSearch = async query => {
    setLoading(true);

    const data = await makeRequest(query, props.excludedIds, props.extraParams);

    setResults(data.results || []);
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setSelectedResults([]);
    },
    setResults: (theResults) => {
      setSelectedResults(theResults);
    }
  }));

  return (
    <>
      <AsyncTypeahead
        filterBy={filterBy}
        id={typeaheadId}
        isLoading={loading}
        options={results}
        labelKey="name"
        maxResults={RESULTS_PER_PAGE}
        minLength={2}
        multiple={props.multiple}
        onChange={onSelected}
        onSearch={handleSearch}
        placeholder="Search for your organization..."
        selected={selectedResults}
        renderMenuItemChildren={(organization: Organization) => {
          const theFunc = props.renderMenuItemFunc || defaultMenuItemFunc;
          return theFunc(organization);
        }}
        useCache={false}
      />

      {renderHtmlInput(selectedResults, props.htmlInputName, props.multiple)}
    </>
  );
});

export function renderOrganizationsTypeahead(elementId, options) {
  const typeaheadRef = createRef();

  ReactDOM.render(
    <OrganizationsTypeahead
      htmlInputName={options.htmlInputName}
      ref={typeaheadRef}
      multiple={options.multiple ?? true}
      onSelected={options.onSelected}
      defaultSelectedResults={options.defaultSelectedResults}
      excludedIds={options.excludedIds}
      renderMenuItemFunc={options.renderMenuItemFunc}
      extraParams={options.extraParams}
    />,
    document.getElementById(elementId)
  );

  return typeaheadRef;
}
