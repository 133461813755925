import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { fetchData } from "../global_functions";
import PersonalClips from "./add_clip_tabs/personal_clips";
import Playlists from "./add_clip_tabs/playlists";
import Search from "./add_clip_tabs/search";
import DiscussionContext from "./discussion_context";

export default function AddClipModal(): React.ReactNode {
  const [data, setData] = useState({});

  // fetches user's playlists, personal clips
  useEffect(() => {
    const getData = async () => {
      const { data } = await fetchData({
        url: "/discussion_queue_videos/video_clips",
      });
      setData(data);
    };
    getData();
  }, []);

  const {
    modal: { activeModalName, handleClose },
  } = useContext(DiscussionContext);

  return (
    <Modal
      show={activeModalName == "AddClipModal"}
      onHide={handleClose}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="add-clip-modal">Add Clip</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link"
              id="from-playlists-tab"
              data-bs-toggle="tab"
              href="#from-playlists"
              role="tab"
              aria-controls="from-playlists"
              aria-selected="false"
            >
              From Playlists
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              id="from-personal-clips-tab"
              data-bs-toggle="tab"
              href="#from-personal-clips"
              role="tab"
              aria-controls="from-personal-clips"
              aria-selected="false"
            >
              From Personal Clips
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              id="from-search-tab"
              data-bs-toggle="tab"
              href="#from-search"
              role="tab"
              aria-controls="from-search"
              aria-selected="false"
            >
              Search
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade"
            id="from-playlists"
            role="tabpanel"
            aria-labelledby="from-playlists-tab"
          >
            {data.playlists && (
              <Playlists
                playlists={data.playlists.data}
                queueableType={data.playlists.queueable_type}
              />
            )}
          </div>

          <div
            className="tab-pane fade"
            id="from-personal-clips"
            role="tabpanel"
            aria-labelledby="from-personal-clips-tab"
          >
            {data.user_clips && (
              <PersonalClips
                personalClips={data.user_clips.data}
                queueableType={data.user_clips.queueable_type}
              />
            )}
          </div>

          <div
            className="tab-pane fade"
            id="from-search"
            role="tabpanel"
            aria-labelledby="from-search-tab"
          >
            {data.search && <Search search={data.search} />}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-dark"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
