import React, { createRef, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { patchData } from "../global_functions";
import { Comment, User } from '../../types';
import FormErrorsList from "../shared_components/form_errors_list";

import styles from "./assignment_item_comment.module.scss";

interface Props {
  comment: Comment;
  currentUser: User
  allowEditMode: boolean;
  onUpdateComment?: (comment: Comment) => void;
}

export const AssignmentItemComment = React.forwardRef((
  { comment, currentUser, onUpdateComment, allowEditMode = false }: Props,
  ref
) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [originalCommentText, setOriginalCommentText] = useState(comment.comment_text);
  const [commentText, setCommentText] = useState(comment.comment_text);
  const [errors, setErrors] = useState([]);

  const { user } = comment;

  const onEditCancel = (e) => {
    setCommentText(originalCommentText);
    setIsEditMode(false);
  };

  const onCommentUpdate = async (e) => {
    e.preventDefault();

    const { data } = await patchData({
      url: `/assignment_item_comments/${comment.id}`,
      data: {
        comment: {
          comment_text: commentText
        }
      }
    });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setIsEditMode(false);
      setOriginalCommentText(data.comment.comment_text);
      setCommentText(data.comment.comment_text);
      onUpdateComment?.(data.comment);
    }
  };

  useImperativeHandle(ref, () => ({
    get commentText() { return commentText },
    updateCommentText: (newCommentText: string) => {
      setCommentText(newCommentText);
      setOriginalCommentText(newCommentText);
    },
  }));

  return (
    <div className="d-flex assignment-item-responses" key={comment.id}>
      {!isEditMode &&
        <>
          <div className="me-3">
            <div className={`${styles.profile_image} rounded-circle`} style={{ backgroundImage: `url('${user.profileImage.url}')` }}></div>
          </div>

          {/* The 'w-100' class is a fix for text wrapping in IE 11 */}
          <div className="d-flex flex-column flex-grow-1 w-100">
            <div className="d-flex flex-row flex-wrap mb-1">
              <h6 className="fw-bold mb-0 me-2">
                {user.displayName}
              </h6>
            </div>

            <div id={`comment-${comment.id}-text`}>
              {commentText}
            </div>
          </div>

          {
            allowEditMode && comment.user_id === currentUser.id &&
            <Dropdown>
              <Dropdown.Toggle bsPrefix="n" variant="link">
                <FontAwesomeIcon icon="ellipsis-h" className="text-muted" />
                <span className="sr-only">Actions</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={e => setIsEditMode(true)}>Edit</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </>
      }

      {
        isEditMode &&

        <div className="d-flex flex-column w-100">
          <FormErrorsList errors={errors} />

          <Form onSubmit={onCommentUpdate}>
            <div className="row">
              <div className="mb-3 col-md-12">
                <Form.Control as="textarea" rows={3} value={commentText} onChange={e => setCommentText(e.target.value)} />
              </div>
            </div>

            <div className="d-flex flex-wrap">
              <Button type="submit" variant="dark" className="me-3">Update</Button>
              <Button type="button" variant="link" onClick={onEditCancel}>Cancel</Button>
            </div>
          </Form>
        </div>
      }
    </div>
  );
});

export function renderAssignmentItemComment(elementId, options) {
  const assignmentItemCommentRef = createRef();

  ReactDOM.render(
    <AssignmentItemComment
      comment={options.comment}
      currentUser={options.currentUser}
      allowEditMode={options.allowEditMode}
      onUpdateComment={options.onUpdateComment}
      ref={assignmentItemCommentRef}
    />,
    document.getElementById(elementId)
  );

  return assignmentItemCommentRef;
}