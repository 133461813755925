import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { PaywallUpgradePrompt } from "./paywall_upgrade_prompt";
import { chAnalyticsTrackEvent } from "../analytics";

import styles from "./watched_resource_paywall.module.scss";
import { ReferPaywallPrompt } from "./refer_paywall_prompt";

interface Props {
  clipId: number;
  clipType: string;
  clipThumbnailUrl: string;
  showReferralOption: boolean;
}

function WatchedResourcePaywall({ clipId, clipType, clipThumbnailUrl, showReferralOption }: Props) {
  useEffect(() => {
    chAnalyticsTrackEvent("paywall", {
      feature: "watched_resource",
      label: "hit_paywall",
      model_id: clipId,
      model_type: clipType
    });
  }, []);

  const onUpgradeClicked = (e) => {
    chAnalyticsTrackEvent("paywall", {
      feature: "watched_resource",
      label: "click_paywall",
      link: "upgrade_button",
      model_id: clipId,
      model_type: clipType,
      extra_data: {
        referral_option_shown: showReferralOption
      }
    });
  };

  return (
    <div className={classNames(showReferralOption ? styles.mainContainerShowReferral : styles.mainContainer)}>
      <div className={styles.overlay} style={{ backgroundImage: `url(${clipThumbnailUrl})` }}></div>

      <div className={classNames("row", styles.contentContainer)}>
        {showReferralOption &&
          <>
            <h4 className="col-md-12 text-center text-white p-3">
              You've reached the limit for clip watches.
              Please refer others, upgrade, or wait until next month to watch more clips.
            </h4>

            <div className={classNames("col-md-6", styles.leftSide)}>
              <div className={styles.leftSideContainerShowReferral}>
                <ReferPaywallPrompt
                  className="border rounded bg-white h-100"
                  onInvited={(e) => console.log("onInvited e", e)}
                  labelText="Invite at least 4 colleagues and get Premium access for 7 days."
                />
              </div>
            </div>
          </>
        }

        {!showReferralOption &&
          <div className={classNames("col-md-7", styles.leftSide)}>
            <div className={styles.leftSideContainer}>
              <h5 className={styles.header}>0 Clip Watches Left</h5>

              <p>
                You've reached the limit for clip watches for this month.
                Please wait until next month or upgrade to watch more clips.
              </p>
            </div>
          </div>
        }

        <div className={classNames(showReferralOption ? "col-md-6" : "col-md-5", styles.rightSide)}>
          <div className={classNames(showReferralOption ? styles.rightSideContainerShowReferral : styles.rightSideContainer)}>
            <PaywallUpgradePrompt
              benefitText="Unlimited clip watches"
              mobileUpgradeReason="to watch unlimited clips"
              className={classNames("border rounded bg-white", showReferralOption ? "h-100" : null)}
              onUpgradeClicked={onUpgradeClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function renderWatchedResourcePaywall(elementId, options) {
  const node = ReactDOM.render(
    <WatchedResourcePaywall
      clipId={options.clipId}
      clipType={options.clipType}
      clipThumbnailUrl={options.clipThumbnailUrl}
      showReferralOption={options.showReferralOption}
    />,
    document.getElementById(elementId)
  );

  return node;
}