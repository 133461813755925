import React from "react";
import ReactDOM from "react-dom";

function DiscussionToolsSectionLoggedOut() {
  return (
    <div className="border rounded p-3">
      <div className="row">
        <div className="col-12">
          <p className="mb-2 text-gray-900">
            Sign up to access lesson features such as sample questions,
            standards alignment, and discussion tools to check for understanding.
          </p>

          <div>
            <a href="/signup" className="btn btn-primary text-white fw-500 homepage-nav-btn">
              Sign up FREE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export function renderDiscussionToolsSectionLoggedOut(elementId) {
  const node = ReactDOM.render(
    <DiscussionToolsSectionLoggedOut />,
    document.getElementById(elementId)
  );

  return node;
}