import React from 'react';

import { ResourcesSliderState } from '../../src/shared_components/resources_slider/resources_slider_reducer';

import { TouchScreenHelper } from '../utils/touch_screen_helper';

// Sync with transition time in resource_slider.tsx
const SLIDER_ANIMATION_TIME = 400;

type SliderKeyboardOptions = {
  state: ResourcesSliderState,
  containerRef: React.MutableRefObject<HTMLElement>
}

export function useSliderKeyboard(options: SliderKeyboardOptions) {
  const { state, containerRef } = options;

  const [isTabbing, setIsTabbing] = React.useState(false);
  const [focusedSliderIndex, setFocusedSliderIndex] = React.useState(null);

  const isTouch = React.useMemo(() => TouchScreenHelper.isTouchScreen(), []);

  function getSliderAttributes(index?: number) {
    const sliderState = state as ResourcesSliderState;

    const isItemVisible =
      isTouch ||
      index >= (sliderState.offset - sliderState.viewableItems) &&
      index < sliderState.offset;
    if (isItemVisible) {
      return {
        'aria-hidden': false
      };
    }

    return {
      'inert': 'true',
      'aria-hidden': true
    };
  }

  /**
   * When the user is not signed in, there may be videos which are not focusable.
   * setTimeout is required because the transition time is 400ms
  */
  function focusNext() {
    setTimeout(() => {
      if (!containerRef.current)
        return;

      const items = containerRef.current.querySelectorAll('li[aria-hidden]');

      let nextFocusableElement: HTMLElement = null;

      for (let i = (focusedSliderIndex + 1); i < items.length; i++) {
        if (nextFocusableElement)
          break;

        nextFocusableElement = items[i].querySelector('a[tabindex="0"]');
      }

      if (nextFocusableElement)
        nextFocusableElement.focus();
    }, SLIDER_ANIMATION_TIME + 100);
  }

  function focusPrev() {
    setTimeout(() => {
      if (!containerRef.current)
        return;

      const items = containerRef.current.querySelectorAll('li[aria-hidden]');

      let prevFocusableElement: HTMLElement = null;
      for (let i = focusedSliderIndex - 1; i >= 0; i--) {
        if (prevFocusableElement) break;

        const item = items[i];
        prevFocusableElement = item.querySelector('a[tabindex="0"]');
      }

      if (prevFocusableElement)
        prevFocusableElement.focus();
    }, SLIDER_ANIMATION_TIME + 100);
  }

  function onFocusSlider(index: number) {
    if (document.querySelector('.user-is-tabbing')) {
      setIsTabbing(true);
      setFocusedSliderIndex(index);
    }
  }

  function onBlurSlider() {
    if (!document.querySelector('.user-is-tabbing')) {
      setIsTabbing(false);
      setFocusedSliderIndex(null);
    }
  }

  return {
    isTabbing,
    onFocusSlider,
    onBlurSlider,
    getSliderAttributes,
    focusNext,
    focusPrev
  };
}