import React, { useRef, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import pluralize from "pluralize";

import FormErrorsList from "../shared_components/form_errors_list";
import { patchData } from "../global_functions";
import { OrganizationsTypeahead } from "../organizations/find_organization_typeahead";
import { AddOrganizationModal } from "../organizations/add_organization_modal";
import { Option, Organization } from "../../types";
import styles from "./update_user_details_form.module.scss";

const NON_STUDENT_NEXT_URL = "/";
const STUDENT_NEXT_URL = "/profile";

interface Props {
  organization?: Organization;
  selectedGradeLevels: Array<Option>;
  gradeLevelOptions: Array<Option>;
  selectedSubjects: Array<Option>;
  subjectOptions: Array<Option>;
  userType: Option;
  userTypeOptions: Array<Option>;
  studentPopulation: Option;
  studentPopulationOptions: Array<Option>;
  referralSources: Array<string>;
  showNextButton: boolean;
  userId: number;
  fromSso: boolean;
}

function getReferredByDetailsLabel(referredBy) {
  switch (referredBy) {
    case "Event":
      return "Event Name";
    case "Blog":
      return "Which blog?";
    case "Other":
      return 'Please elaborate on "Other"';
    default:
      return null;
  }
}

function UpdateUserDetailsForm(props: Props) {
  const [organization, setOrganization] = useState(props.organization);
  const [userType, setUserType] = useState(props.userType);
  const [selectedGradeLevels, setSelectedGradeLevels] = useState(props.selectedGradeLevels);
  const [selectedSubjects, setSelectedSubjects] = useState(props.selectedSubjects);
  const [studentPopulation, setStudentPopulation] = useState(props.studentPopulation);
  const [referredBy, setReferredBy] = useState('');
  const [referredByDetails, setReferredByDetails] = useState('');

  const [modalShown, setModalShown] = useState(false);

  const [hasSavedData, setHasSavedData] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const organizationsTypeaheadRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const userData = {
      grade_level_ids: selectedGradeLevels.map(gradeLevel => gradeLevel.value),
      subject_ids: selectedSubjects.map(subject => subject.value),
      student_population: studentPopulation.value,
      user_type: userType.value
    };

    if (organization) userData['organization_id'] = organization.id;
    if (props.fromSso && referredBy) userData['referred_by'] = referredBy;
    if (props.fromSso && referredByDetails) userData['referred_by_details'] = referredByDetails;

    const { data } = await patchData({
      url: `/users/${props.userId}`,
      data: { user: userData }
    });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setHasSavedData(true);
      setShowSaveMessage(true);

      setTimeout(() => setShowSaveMessage(false), 4000);
    }

    setIsLoading(false);
  };

  const onClickAddOrganization = () => {
    setModalShown(true);
  };

  const handleOrganizationAdded = (theOrganization: Organization) => {
    setOrganization(theOrganization);
    setModalShown(false);

    if (organizationsTypeaheadRef && organizationsTypeaheadRef.current) {
      organizationsTypeaheadRef.current.setResults([theOrganization]);
    }
  };

  const nextUrl = (userType.value === 'student') ? STUDENT_NEXT_URL : NON_STUDENT_NEXT_URL;
  const referredByDetailsLabel = getReferredByDetailsLabel(referredBy);

  return (
    <div className="container text-start">
      <AddOrganizationModal
        shown={modalShown}
        onClose={() => setModalShown(false)}
        onSuccess={organization => handleOrganizationAdded(organization)}
      />

      <FormErrorsList errors={errors} />

      <div className={styles.formContainer}>
        <Form onSubmit={onSubmit} >
          {props.userType.value !== 'student' && props.fromSso &&
            <>
              <div className="mb-3 ">
                <label htmlFor="user_user_type mb-2">Your role</label>
                <Select
                  id="user_user_type"
                  onChange={option => setUserType(option)}
                  options={props.userTypeOptions}
                  controlShouldRenderValue={true}
                  value={userType}
                />
              </div>

              <div className="mb-3 ">
                <label htmlFor="user_referred_by mb-2">How did you hear about ClassHook?</label>
                <Select
                  onChange={option => setReferredBy(option.value)}
                  options={props.referralSources.map(source => ({ label: source, value: source }))}
                  controlShouldRenderValue={true}
                  value={{ label: referredBy, value: referredBy }}
                />
              </div>

              {referredByDetailsLabel &&
                <div className="mb-3 ">
                  <label htmlFor="user_referred_by_details mb-2">{referredByDetailsLabel}</label>
                  <input
                    id="user_referred_by_details"
                    className="form-control"
                    type="text"
                    onChange={e => setReferredByDetails(e.target.value)}
                  />
                </div>
              }
            </>
          }

          {userType.value !== 'student' &&
            <div className="mb-3 ">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <label htmlFor="user_organization_id">Which organization are you from?</label>
                <button type="button" className="btn btn-sm btn-link" onClick={onClickAddOrganization}>
                  Can’t find your organization?
                </button>
              </div>

              <OrganizationsTypeahead
                htmlInputName="users[organization_id]"
                defaultSelectedResults={Object.keys(props.organization).length > 0 ? [props.organization] : []}
                onSelected={results => setOrganization(results[0])}
                extraParams={{ include_educator_count: true }}
                ref={organizationsTypeaheadRef}
                renderMenuItemFunc={(theOrganization: Organization) => (
                  <div key={theOrganization.id}>
                    <div className="fw-bold">
                      {theOrganization.name}
                      {' '}
                      <span className="small text-muted">{theOrganization.display_type}</span>
                    </div>

                    <div>
                      {theOrganization.city}
                      {theOrganization.state ? `, ${theOrganization.state}` : ""}
                      {theOrganization.country ? `, ${theOrganization.country}` : ""}
                    </div>

                    {theOrganization?.educator_count > 0 &&
                      <div className="small">
                        {pluralize('educator', theOrganization.educator_count, true)}
                        {' '}
                        using ClassHook
                      </div>
                    }
                  </div>
                )}
              />
            </div>
          }

          {userType.value === 'teacher' &&
            <>
              <div>
                <div className="mb-3">
                  <label htmlFor="user_grade_level_ids mb-2">Which grade levels do you teach?</label>
                  <Select
                    options={props.gradeLevelOptions}
                    onChange={options => setSelectedGradeLevels(options)}
                    defaultValue={props.selectedGradeLevels}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </div>
              </div>

              <div>
                <div className="mb-3 ">
                  <label htmlFor="user_subject_ids mb-2">Which subjects do you teach?</label>
                  <Select
                    options={props.subjectOptions}
                    onChange={options => setSelectedSubjects(options)}
                    defaultValue={props.selectedSubjects}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </div>
              </div>

              <div className="mb-3 ">
                <label htmlFor="user_student_population mb-2">Which population of students do you teach?</label>
                <Select
                  onChange={option => setStudentPopulation(option)}
                  options={props.studentPopulationOptions}
                  controlShouldRenderValue={true}
                  defaultValue={studentPopulation}
                />
              </div>
            </>
          }

          {userType.value === 'student' &&
            <div className="">
              <div className="mb-3 ">
                <label htmlFor="user_grade_levels_taught mb-2">Which grade are you in?</label>
                <Select
                  onChange={option => setSelectedGradeLevels(option.value)}
                  options={props.gradeLevelOptions}
                  controlShouldRenderValue={true}
                  defaultValue={props.selectedGradeLevels}
                />
              </div>
            </div>
          }

          {hasSavedData && showSaveMessage &&
            <div className="alert alert-success text-center">
              Your changes have been saved
            </div>
          }

          <div className="text-center mt-3">
            <Button variant="dark" type="submit" disabled={isLoading} className="w-100">Save changes</Button>
          </div>
        </Form>
      </div>

      {props.showNextButton && hasSavedData &&
        <div className="text-center mt-3">
          <a href={nextUrl} className="btn btn-outline-dark text-decoration-none w-100">
            Continue
          </a>
        </div>
      }
    </div>
  );
}

export function renderUpdateUserDetailsForm(elementId, options) {
  const node = ReactDOM.render(
    <UpdateUserDetailsForm
      organization={options.organization}
      selectedGradeLevels={options.selectedGradeLevels}
      gradeLevelOptions={options.gradeLevelOptions}
      selectedSubjects={options.selectedSubjects}
      subjectOptions={options.subjectOptions}
      userType={options.userType}
      userTypeOptions={options.userTypeOptions}
      studentPopulation={options.studentPopulation}
      studentPopulationOptions={options.studentPopulationOptions}
      referralSources={options.referralSources}
      showNextButton={options.showNextButton}
      userId={options.userId}
      fromSso={options.fromSso}
    />,
    document.getElementById(elementId)
  );

  return node;
}