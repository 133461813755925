import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Row, Col } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";

import {
  DiscussionProps,
  DiscussionSection,
  Participants,
  subscribeToChannel,
  ClassResponses,
} from "./discussion_helper";

import { Comment } from "../pause_prompts/pause_prompt_comment";

import { renderPausePrompt, MODE } from "../pause_prompts";
import ContainerWithHeading from "../shared_components/container_with_heading";
import { postData } from "../global_functions";

import "./student_discussion.scss";
import { chAnalyticsTrackEvent } from "../analytics";

enum StudentState {
  WatchVideo = 1,
  Prompt,
  ViewResponses
}

interface ViewResponsesProps {
  prompt: string;
  response: string;
  classResponses: Array<Comment>;
  hideResponses: boolean;
}

function ViewResponses({ prompt, response, classResponses, hideResponses = false }: ViewResponsesProps): React.ReactNode {
  const theClassResponses = classResponses.filter(comment => !comment.is_hidden);

  return (
    <section>
      <p className="fw-bold">Follow the instructions from your teacher</p>

      <Row>
        <Col lg={6} md={12}>
          <ContainerWithHeading header={"Current question"} iconName="question-circle">
            <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(prompt) }} />
          </ContainerWithHeading>

          <ContainerWithHeading header={"My response"} iconName="comment">
            <div>{response}</div>
          </ContainerWithHeading>
        </Col>

        <Col lg={6} md={12}>
          <ContainerWithHeading header={hideResponses ? "Class responses" : `Class responses (${theClassResponses.length})`} iconName="poll">
            {
              hideResponses ?
                <p className="text-align-center">Class responses are hidden.</p>
                :
                <ClassResponses classResponses={theClassResponses} isTeacher={false} hideResponses={hideResponses} />
            }
          </ContainerWithHeading>
        </Col>
      </Row>
    </section>
  );
}

function StudentDiscussion({ title, discussionRoomId, roomCode, roomCodeId, objective, name, id, hideResponses = false }: DiscussionProps): React.ReactNode {
  const [userList, setUserList] = useState([]);
  const [studentState, setStudentState] = useState(StudentState.WatchVideo);
  const [currentPrompt, setCurrentPrompt] = useState({});
  // personal response
  const [response, setResponse] = useState("");
  const [classResponses, setClassResponses] = useState([]);
  const [commentsData, setCommentsData] = useState({});
  const [responseIndicators, setResponseIndicators] = useState(new Set());

  const pausePromptContainerId = "student-pause-prompt";
  const isStudent = true;

  function receivedCallback(data) {
    switch (data.message.event_type) {
      case "join_list":
        setUserList(data.message.users);
        break;
      case "pause_prompt_received":
        //remove old pause prompt first before appending new pause prompt
        const container = document.getElementById(pausePromptContainerId);
        const childElements = Array.from(container.children);
        childElements.forEach(node => node.remove());

        setStudentState(StudentState.Prompt);
        setCurrentPrompt(data.message.pause_prompt);
        setResponseIndicators(new Set());

        renderPausePrompt({
          id: data.message.pause_prompt.id,
          container: container,
          hasTimer: false,
          content: data.message.pause_prompt.prompt,
          addMinuteButtonEnabled: false,
          mode: MODE.LIVE_RESPOND,
          secondsDuration: 0,
          onSubmitResponse: (responseText, callback) => {
            if (responseText?.trim()?.length < 1) {
              callback(false);
              return false;
            }

            setStudentState(StudentState.ViewResponses);
            setResponse(responseText);

            const promptId = data.message.pause_prompt.id;
            const formData = {
              discussion_room_name: `live_discussion_${roomCode}`,
              studentUUID: id,
              comment: {
                comment_text: responseText,
                discussion_room_code_id: roomCodeId,
                user_name: name,
                is_hidden: hideResponses
              }
            };

            postData({ url: `/pause_prompts/${promptId}/pause_prompt_comments`, data: formData }).then(response => {
              const data = response.data;
              const successfulRequest = data.errors?.length < 1;

              if (successfulRequest) {
                chAnalyticsTrackEvent("discussion_room", {
                  model_id: discussionRoomId,
                  model_type: "DiscussionRoom",
                  discussion_room_code_id: roomCodeId,
                  prompt_id: promptId,
                  prompt_type: "PausePrompt",
                  response_id: data.comment.id,
                  response_type: "Comment",
                  label: "responded_to_prompt"
                });
              }

              callback(successfulRequest);
            }).catch(error => {
              callback(false);
            });

            return false;
          }
        })

        break;
      case "remove_user":
        if (data.message.student_uuid === id) {
          window.location.href = "/discussions/join";
        }
        break;
      case "pause_prompt_new_comment":
        // accessing currentPrompt in callback is unreliable, so using useEffect instead
        setCommentsData(data.message);
        break;
    }
  }

  useEffect(() => {
    const userJson = { isStudent, name };
    subscribeToChannel({ roomCode, userJson, receivedCallback });
  });

  useEffect(() => {
    // since null === null, check if key present first before comparing
    // make sure comments are from the same pause prompt
    if (commentsData.pause_prompt_id && commentsData.pause_prompt_id === currentPrompt.id) {
      setClassResponses(commentsData.all_comments);
      setResponseIndicators(new Set([...responseIndicators, commentsData.student_uuid]));
    }
  }, [commentsData]);

  return (
    <Row>
      <Col lg={10}>
        <DiscussionSection title={title} objective={objective} isStudent={isStudent}>
          {studentState === StudentState.WatchVideo &&
            <div className="watch-video">
              <div className="fw-bold">
                Watch the video on your teacher's screen. Then come back here when there's a question
              </div>
            </div>
          }
          {studentState === StudentState.Prompt &&
            <div className="text-center">
              <p className="fw-bold">Write your response to the question below</p>
            </div>
          }

          <div id={pausePromptContainerId}></div>

          {studentState === StudentState.ViewResponses &&
            <ViewResponses prompt={currentPrompt.prompt || ""} hideResponses={hideResponses} response={response} classResponses={classResponses} />
          }
        </DiscussionSection>
      </Col>

      <Col lg={2} className="px-0">
        <Participants
          userList={userList}
          isStudent={isStudent}
          responseIndicators={responseIndicators}
          containerClass="border rounded bg-white"
          id={id}
        />
      </Col>
    </Row>
  );
}

export function renderStudentDiscussion(options: DiscussionProps) {
  ReactDOM.render(
    <StudentDiscussion
      title={options.title}
      discussionRoomId={options.discussionRoomId}
      roomCode={options.roomCode}
      roomCodeId={options.roomCodeId}
      objective={options.objective}
      name={options.name}
      id={options.id}
      hideResponses={options.hideResponses}
    />,
    document.getElementById(options.elementId)
  );
}
